/**
 * Generated by @openapi-codegen
 *
 * @version 1.0.0
 */
import * as reactQuery from '@tanstack/react-query';

import type { DiceContext } from './diceContext';
import type * as Fetcher from './diceFetcher';
import type * as Schemas from './diceSchemas';
import { queryKeyFn, useDiceContext } from './diceContext';
import { diceFetch } from './diceFetcher';

export type BaseError = Fetcher.ErrorWrapper<undefined>;

export type BaseVariables = DiceContext['fetcherOptions'];

export const fetchBase = (variables: BaseVariables, signal?: AbortSignal) =>
  diceFetch<string, BaseError, undefined, {}, {}, {}>({
    url: '/',
    method: 'get',
    ...variables,
    signal,
  });

export const baseQuery = (
  variables: BaseVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<string>;
} => ({
  queryKey: queryKeyFn({
    path: '/',
    operationId: 'base',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) => fetchBase({ ...variables }, signal),
});

export const useSuspenseBase = <TData = string>(
  variables: BaseVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<string, BaseError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<string, BaseError, TData>({
    ...baseQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useBase = <TData = string>(
  variables: BaseVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<string, BaseError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<string, BaseError, TData>({
    ...baseQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export type UpdateAssetExtendedInfoError = Fetcher.ErrorWrapper<undefined>;

export type UpdateAssetExtendedInfoVariables = {
  body: Schemas.ConfigRequest;
} & DiceContext['fetcherOptions'];

export const fetchUpdateAssetExtendedInfo = (
  variables: UpdateAssetExtendedInfoVariables,
  signal?: AbortSignal,
) =>
  diceFetch<string, UpdateAssetExtendedInfoError, Schemas.ConfigRequest, {}, {}, {}>({
    url: '/',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpdateAssetExtendedInfo = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      UpdateAssetExtendedInfoError,
      UpdateAssetExtendedInfoVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    string,
    UpdateAssetExtendedInfoError,
    UpdateAssetExtendedInfoVariables
  >({
    mutationFn: (variables: UpdateAssetExtendedInfoVariables) =>
      fetchUpdateAssetExtendedInfo({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetAllDataContainerError = Fetcher.ErrorWrapper<undefined>;

export type GetAllDataContainerResponse = Schemas.YearDTO[];

export type GetAllDataContainerVariables = DiceContext['fetcherOptions'];

export const fetchGetAllDataContainer = (
  variables: GetAllDataContainerVariables,
  signal?: AbortSignal,
) =>
  diceFetch<GetAllDataContainerResponse, GetAllDataContainerError, undefined, {}, {}, {}>({
    url: '/years',
    method: 'get',
    ...variables,
    signal,
  });

export const getAllDataContainerQuery = (
  variables: GetAllDataContainerVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<GetAllDataContainerResponse>;
} => ({
  queryKey: queryKeyFn({
    path: '/years',
    operationId: 'getAllDataContainer',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetAllDataContainer({ ...variables }, signal),
});

export const useSuspenseGetAllDataContainer = <TData = GetAllDataContainerResponse>(
  variables: GetAllDataContainerVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetAllDataContainerResponse, GetAllDataContainerError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<GetAllDataContainerResponse, GetAllDataContainerError, TData>({
    ...getAllDataContainerQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetAllDataContainer = <TData = GetAllDataContainerResponse>(
  variables: GetAllDataContainerVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetAllDataContainerResponse, GetAllDataContainerError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<GetAllDataContainerResponse, GetAllDataContainerError, TData>({
    ...getAllDataContainerQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export type UpdateDataContainerError = Fetcher.ErrorWrapper<undefined>;

export type UpdateDataContainerVariables = {
  body?: Schemas.YearDTO;
} & DiceContext['fetcherOptions'];

export const fetchUpdateDataContainer = (
  variables: UpdateDataContainerVariables,
  signal?: AbortSignal,
) =>
  diceFetch<Schemas.YearDTO, UpdateDataContainerError, Schemas.YearDTO, {}, {}, {}>({
    url: '/years',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpdateDataContainer = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.YearDTO,
      UpdateDataContainerError,
      UpdateDataContainerVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.YearDTO,
    UpdateDataContainerError,
    UpdateDataContainerVariables
  >({
    mutationFn: (variables: UpdateDataContainerVariables) =>
      fetchUpdateDataContainer({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type InsertDataContainerError = Fetcher.ErrorWrapper<undefined>;

export type InsertDataContainerVariables = {
  body?: Schemas.YearDTO;
} & DiceContext['fetcherOptions'];

export const fetchInsertDataContainer = (
  variables: InsertDataContainerVariables,
  signal?: AbortSignal,
) =>
  diceFetch<Schemas.YearDTO, InsertDataContainerError, Schemas.YearDTO, {}, {}, {}>({
    url: '/years',
    method: 'post',
    ...variables,
    signal,
  });

export const useInsertDataContainer = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.YearDTO,
      InsertDataContainerError,
      InsertDataContainerVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.YearDTO,
    InsertDataContainerError,
    InsertDataContainerVariables
  >({
    mutationFn: (variables: InsertDataContainerVariables) =>
      fetchInsertDataContainer({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpsertFileMetadataError = Fetcher.ErrorWrapper<undefined>;

export type UpsertFileMetadataVariables = {
  body?: Schemas.FileMetadataDTO;
} & DiceContext['fetcherOptions'];

export const fetchUpsertFileMetadata = (
  variables: UpsertFileMetadataVariables,
  signal?: AbortSignal,
) =>
  diceFetch<Schemas.FileMetadataDTO, UpsertFileMetadataError, Schemas.FileMetadataDTO, {}, {}, {}>({
    url: '/year-calculations/files-metadata',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpsertFileMetadata = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.FileMetadataDTO,
      UpsertFileMetadataError,
      UpsertFileMetadataVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.FileMetadataDTO,
    UpsertFileMetadataError,
    UpsertFileMetadataVariables
  >({
    mutationFn: (variables: UpsertFileMetadataVariables) =>
      fetchUpsertFileMetadata({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetYearPathParams {
  publicFacilityId: string;
  yearId: string;
}

export type GetYearError = Fetcher.ErrorWrapper<undefined>;

export type GetYearVariables = {
  pathParams: GetYearPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetYear = (variables: GetYearVariables, signal?: AbortSignal) =>
  diceFetch<Schemas.YearDTOV2, GetYearError, undefined, {}, {}, GetYearPathParams>({
    url: '/public-facilities/{publicFacilityId}/years/{yearId}',
    method: 'get',
    ...variables,
    signal,
  });

export const getYearQuery = (
  variables: GetYearVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<Schemas.YearDTOV2>;
} => ({
  queryKey: queryKeyFn({
    path: '/public-facilities/{publicFacilityId}/years/{yearId}',
    operationId: 'getYear',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) => fetchGetYear({ ...variables }, signal),
});

export const useSuspenseGetYear = <TData = Schemas.YearDTOV2>(
  variables: GetYearVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.YearDTOV2, GetYearError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<Schemas.YearDTOV2, GetYearError, TData>({
    ...getYearQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetYear = <TData = Schemas.YearDTOV2>(
  variables: GetYearVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.YearDTOV2, GetYearError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<Schemas.YearDTOV2, GetYearError, TData>({
    ...getYearQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface PutYearPathParams {
  publicFacilityId: string;
  yearId: string;
}

export type PutYearError = Fetcher.ErrorWrapper<undefined>;

export type PutYearVariables = {
  body?: Schemas.YearDTOV2;
  pathParams: PutYearPathParams;
} & DiceContext['fetcherOptions'];

export const fetchPutYear = (variables: PutYearVariables, signal?: AbortSignal) =>
  diceFetch<Schemas.YearDTOV2, PutYearError, Schemas.YearDTOV2, {}, {}, PutYearPathParams>({
    url: '/public-facilities/{publicFacilityId}/years/{yearId}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutYear = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.YearDTOV2, PutYearError, PutYearVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<Schemas.YearDTOV2, PutYearError, PutYearVariables>({
    mutationFn: (variables: PutYearVariables) => fetchPutYear({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetMasterConfigurationPathParams {
  masterConfigurationId: string;
}

export type GetMasterConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type GetMasterConfigurationVariables = {
  pathParams: GetMasterConfigurationPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetMasterConfiguration = (
  variables: GetMasterConfigurationVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.MasterConfiguration,
    GetMasterConfigurationError,
    undefined,
    {},
    {},
    GetMasterConfigurationPathParams
  >({ url: '/master-configurations/{masterConfigurationId}', method: 'get', ...variables, signal });

export const getMasterConfigurationQuery = (
  variables: GetMasterConfigurationVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<Schemas.MasterConfiguration>;
} => ({
  queryKey: queryKeyFn({
    path: '/master-configurations/{masterConfigurationId}',
    operationId: 'getMasterConfiguration',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetMasterConfiguration({ ...variables }, signal),
});

export const useSuspenseGetMasterConfiguration = <TData = Schemas.MasterConfiguration>(
  variables: GetMasterConfigurationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.MasterConfiguration, GetMasterConfigurationError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    Schemas.MasterConfiguration,
    GetMasterConfigurationError,
    TData
  >({
    ...getMasterConfigurationQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetMasterConfiguration = <TData = Schemas.MasterConfiguration>(
  variables: GetMasterConfigurationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.MasterConfiguration, GetMasterConfigurationError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<Schemas.MasterConfiguration, GetMasterConfigurationError, TData>({
    ...getMasterConfigurationQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface PutMasterConfigurationPathParams {
  masterConfigurationId: string;
}

export type PutMasterConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type PutMasterConfigurationVariables = {
  body: Schemas.MasterConfiguration;
  pathParams: PutMasterConfigurationPathParams;
} & DiceContext['fetcherOptions'];

export const fetchPutMasterConfiguration = (
  variables: PutMasterConfigurationVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.MasterConfiguration,
    PutMasterConfigurationError,
    Schemas.MasterConfiguration,
    {},
    {},
    PutMasterConfigurationPathParams
  >({ url: '/master-configurations/{masterConfigurationId}', method: 'put', ...variables, signal });

export const usePutMasterConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MasterConfiguration,
      PutMasterConfigurationError,
      PutMasterConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.MasterConfiguration,
    PutMasterConfigurationError,
    PutMasterConfigurationVariables
  >({
    mutationFn: (variables: PutMasterConfigurationVariables) =>
      fetchPutMasterConfiguration({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface DeleteMasterConfigurationPathParams {
  masterConfigurationId: string;
}

export interface DeleteMasterConfigurationHeaders {
  authorization?: string;
}

export type DeleteMasterConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type DeleteMasterConfigurationVariables = {
  headers?: DeleteMasterConfigurationHeaders;
  pathParams: DeleteMasterConfigurationPathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeleteMasterConfiguration = (
  variables: DeleteMasterConfigurationVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    DeleteMasterConfigurationError,
    undefined,
    DeleteMasterConfigurationHeaders,
    {},
    DeleteMasterConfigurationPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteMasterConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteMasterConfigurationError,
      DeleteMasterConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    DeleteMasterConfigurationError,
    DeleteMasterConfigurationVariables
  >({
    mutationFn: (variables: DeleteMasterConfigurationVariables) =>
      fetchDeleteMasterConfiguration({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetTechnicalDataByMasterConfigurationIdPathParams {
  masterConfigurationId: string;
}

export type GetTechnicalDataByMasterConfigurationIdError = Fetcher.ErrorWrapper<undefined>;

export type GetTechnicalDataByMasterConfigurationIdResponse = Schemas.TechnicalDataDTO[];

export type GetTechnicalDataByMasterConfigurationIdVariables = {
  pathParams: GetTechnicalDataByMasterConfigurationIdPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetTechnicalDataByMasterConfigurationId = (
  variables: GetTechnicalDataByMasterConfigurationIdVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetTechnicalDataByMasterConfigurationIdResponse,
    GetTechnicalDataByMasterConfigurationIdError,
    undefined,
    {},
    {},
    GetTechnicalDataByMasterConfigurationIdPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/technical-data',
    method: 'get',
    ...variables,
    signal,
  });

export const getTechnicalDataByMasterConfigurationIdQuery = (
  variables: GetTechnicalDataByMasterConfigurationIdVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({
    signal,
  }: {
    signal?: AbortSignal;
  }) => Promise<GetTechnicalDataByMasterConfigurationIdResponse>;
} => ({
  queryKey: queryKeyFn({
    path: '/master-configurations/{masterConfigurationId}/technical-data',
    operationId: 'getTechnicalDataByMasterConfigurationId',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetTechnicalDataByMasterConfigurationId({ ...variables }, signal),
});

export const useSuspenseGetTechnicalDataByMasterConfigurationId = <
  TData = GetTechnicalDataByMasterConfigurationIdResponse,
>(
  variables: GetTechnicalDataByMasterConfigurationIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetTechnicalDataByMasterConfigurationIdResponse,
      GetTechnicalDataByMasterConfigurationIdError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetTechnicalDataByMasterConfigurationIdResponse,
    GetTechnicalDataByMasterConfigurationIdError,
    TData
  >({
    ...getTechnicalDataByMasterConfigurationIdQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetTechnicalDataByMasterConfigurationId = <
  TData = GetTechnicalDataByMasterConfigurationIdResponse,
>(
  variables: GetTechnicalDataByMasterConfigurationIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetTechnicalDataByMasterConfigurationIdResponse,
      GetTechnicalDataByMasterConfigurationIdError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    GetTechnicalDataByMasterConfigurationIdResponse,
    GetTechnicalDataByMasterConfigurationIdError,
    TData
  >({
    ...getTechnicalDataByMasterConfigurationIdQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface PutTechnicalDataEntriesPathParams {
  masterConfigurationId: string;
}

export type PutTechnicalDataEntriesError = Fetcher.ErrorWrapper<undefined>;

export type PutTechnicalDataEntriesResponse = Schemas.TechnicalDataDTO[];

export type PutTechnicalDataEntriesRequestBody = Schemas.TechnicalDataDTO[];

export type PutTechnicalDataEntriesVariables = {
  body?: PutTechnicalDataEntriesRequestBody;
  pathParams: PutTechnicalDataEntriesPathParams;
} & DiceContext['fetcherOptions'];

export const fetchPutTechnicalDataEntries = (
  variables: PutTechnicalDataEntriesVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    PutTechnicalDataEntriesResponse,
    PutTechnicalDataEntriesError,
    PutTechnicalDataEntriesRequestBody,
    {},
    {},
    PutTechnicalDataEntriesPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/technical-data',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutTechnicalDataEntries = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      PutTechnicalDataEntriesResponse,
      PutTechnicalDataEntriesError,
      PutTechnicalDataEntriesVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    PutTechnicalDataEntriesResponse,
    PutTechnicalDataEntriesError,
    PutTechnicalDataEntriesVariables
  >({
    mutationFn: (variables: PutTechnicalDataEntriesVariables) =>
      fetchPutTechnicalDataEntries({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface PostTechnicalDataEntryPathParams {
  masterConfigurationId: string;
}

export type PostTechnicalDataEntryError = Fetcher.ErrorWrapper<undefined>;

export type PostTechnicalDataEntryVariables = {
  body?: Schemas.TechnicalDataDTO;
  pathParams: PostTechnicalDataEntryPathParams;
} & DiceContext['fetcherOptions'];

export const fetchPostTechnicalDataEntry = (
  variables: PostTechnicalDataEntryVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.TechnicalDataDTO,
    PostTechnicalDataEntryError,
    Schemas.TechnicalDataDTO,
    {},
    {},
    PostTechnicalDataEntryPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/technical-data',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostTechnicalDataEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.TechnicalDataDTO,
      PostTechnicalDataEntryError,
      PostTechnicalDataEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.TechnicalDataDTO,
    PostTechnicalDataEntryError,
    PostTechnicalDataEntryVariables
  >({
    mutationFn: (variables: PostTechnicalDataEntryVariables) =>
      fetchPostTechnicalDataEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface PutTechnicalDataEntryPathParams {
  masterConfigurationId: string;
  id: string;
}

export type PutTechnicalDataEntryError = Fetcher.ErrorWrapper<undefined>;

export type PutTechnicalDataEntryVariables = {
  body?: Schemas.TechnicalDataDTO;
  pathParams: PutTechnicalDataEntryPathParams;
} & DiceContext['fetcherOptions'];

export const fetchPutTechnicalDataEntry = (
  variables: PutTechnicalDataEntryVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.TechnicalDataDTO,
    PutTechnicalDataEntryError,
    Schemas.TechnicalDataDTO,
    {},
    {},
    PutTechnicalDataEntryPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/technical-data/{id}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutTechnicalDataEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.TechnicalDataDTO,
      PutTechnicalDataEntryError,
      PutTechnicalDataEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.TechnicalDataDTO,
    PutTechnicalDataEntryError,
    PutTechnicalDataEntryVariables
  >({
    mutationFn: (variables: PutTechnicalDataEntryVariables) =>
      fetchPutTechnicalDataEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface RestoreBackupOfMasterConfigurationPathParams {
  masterConfigurationId: string;
}

export type RestoreBackupOfMasterConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type RestoreBackupOfMasterConfigurationVariables = {
  pathParams: RestoreBackupOfMasterConfigurationPathParams;
} & DiceContext['fetcherOptions'];

export const fetchRestoreBackupOfMasterConfiguration = (
  variables: RestoreBackupOfMasterConfigurationVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    RestoreBackupOfMasterConfigurationError,
    undefined,
    {},
    {},
    RestoreBackupOfMasterConfigurationPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/restore-backup',
    method: 'put',
    ...variables,
    signal,
  });

export const useRestoreBackupOfMasterConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      RestoreBackupOfMasterConfigurationError,
      RestoreBackupOfMasterConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    RestoreBackupOfMasterConfigurationError,
    RestoreBackupOfMasterConfigurationVariables
  >({
    mutationFn: (variables: RestoreBackupOfMasterConfigurationVariables) =>
      fetchRestoreBackupOfMasterConfiguration({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface PromoteMasterConfigurationToPrimaryPathParams {
  masterConfigurationId: string;
}

export type PromoteMasterConfigurationToPrimaryError = Fetcher.ErrorWrapper<undefined>;

export type PromoteMasterConfigurationToPrimaryVariables = {
  pathParams: PromoteMasterConfigurationToPrimaryPathParams;
} & DiceContext['fetcherOptions'];

export const fetchPromoteMasterConfigurationToPrimary = (
  variables: PromoteMasterConfigurationToPrimaryVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    PromoteMasterConfigurationToPrimaryError,
    undefined,
    {},
    {},
    PromoteMasterConfigurationToPrimaryPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/promote',
    method: 'put',
    ...variables,
    signal,
  });

export const usePromoteMasterConfigurationToPrimary = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      PromoteMasterConfigurationToPrimaryError,
      PromoteMasterConfigurationToPrimaryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    PromoteMasterConfigurationToPrimaryError,
    PromoteMasterConfigurationToPrimaryVariables
  >({
    mutationFn: (variables: PromoteMasterConfigurationToPrimaryVariables) =>
      fetchPromoteMasterConfigurationToPrimary({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface SavePlannedCostRevenueCostTypeAccountEntryPathParams {
  masterConfigurationId: string;
  plannedCostTypeAccountEntryId: string;
}

export type SavePlannedCostRevenueCostTypeAccountEntryError = Fetcher.ErrorWrapper<undefined>;

export type SavePlannedCostRevenueCostTypeAccountEntryVariables = {
  body?: Schemas.PlannedCostTypeAccountEntryDTO;
  pathParams: SavePlannedCostRevenueCostTypeAccountEntryPathParams;
} & DiceContext['fetcherOptions'];

export const fetchSavePlannedCostRevenueCostTypeAccountEntry = (
  variables: SavePlannedCostRevenueCostTypeAccountEntryVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.PlannedCostTypeAccountEntryDTO,
    SavePlannedCostRevenueCostTypeAccountEntryError,
    Schemas.PlannedCostTypeAccountEntryDTO,
    {},
    {},
    SavePlannedCostRevenueCostTypeAccountEntryPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/planned-cost-revenue/{plannedCostTypeAccountEntryId}',
    method: 'put',
    ...variables,
    signal,
  });

export const useSavePlannedCostRevenueCostTypeAccountEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PlannedCostTypeAccountEntryDTO,
      SavePlannedCostRevenueCostTypeAccountEntryError,
      SavePlannedCostRevenueCostTypeAccountEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.PlannedCostTypeAccountEntryDTO,
    SavePlannedCostRevenueCostTypeAccountEntryError,
    SavePlannedCostRevenueCostTypeAccountEntryVariables
  >({
    mutationFn: (variables: SavePlannedCostRevenueCostTypeAccountEntryVariables) =>
      fetchSavePlannedCostRevenueCostTypeAccountEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface SavePlannedCostRevenueAccountEntryPathParams {
  masterConfigurationId: string;
  plannedCostTypeAccountEntryId: string;
  plannedAccountEntryId: string;
}

export type SavePlannedCostRevenueAccountEntryError = Fetcher.ErrorWrapper<undefined>;

export type SavePlannedCostRevenueAccountEntryVariables = {
  body?: Schemas.PlannedAccountEntryDTO;
  pathParams: SavePlannedCostRevenueAccountEntryPathParams;
} & DiceContext['fetcherOptions'];

export const fetchSavePlannedCostRevenueAccountEntry = (
  variables: SavePlannedCostRevenueAccountEntryVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.PlannedAccountEntryDTO,
    SavePlannedCostRevenueAccountEntryError,
    Schemas.PlannedAccountEntryDTO,
    {},
    {},
    SavePlannedCostRevenueAccountEntryPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/planned-cost-revenue/{plannedCostTypeAccountEntryId}/account/{plannedAccountEntryId}',
    method: 'put',
    ...variables,
    signal,
  });

export const useSavePlannedCostRevenueAccountEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PlannedAccountEntryDTO,
      SavePlannedCostRevenueAccountEntryError,
      SavePlannedCostRevenueAccountEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.PlannedAccountEntryDTO,
    SavePlannedCostRevenueAccountEntryError,
    SavePlannedCostRevenueAccountEntryVariables
  >({
    mutationFn: (variables: SavePlannedCostRevenueAccountEntryVariables) =>
      fetchSavePlannedCostRevenueAccountEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface SavePlannedCostRevenueConfigDetailsPathParams {
  masterConfigurationId: string;
}

export type SavePlannedCostRevenueConfigDetailsError = Fetcher.ErrorWrapper<undefined>;

export type SavePlannedCostRevenueConfigDetailsVariables = {
  body?: Schemas.PlannedCostRevenueDetailDTO;
  pathParams: SavePlannedCostRevenueConfigDetailsPathParams;
} & DiceContext['fetcherOptions'];

export const fetchSavePlannedCostRevenueConfigDetails = (
  variables: SavePlannedCostRevenueConfigDetailsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.PlannedCostRevenueConfigDTO,
    SavePlannedCostRevenueConfigDetailsError,
    Schemas.PlannedCostRevenueDetailDTO,
    {},
    {},
    SavePlannedCostRevenueConfigDetailsPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/planned-cost-revenue/details',
    method: 'put',
    ...variables,
    signal,
  });

export const useSavePlannedCostRevenueConfigDetails = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PlannedCostRevenueConfigDTO,
      SavePlannedCostRevenueConfigDetailsError,
      SavePlannedCostRevenueConfigDetailsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.PlannedCostRevenueConfigDTO,
    SavePlannedCostRevenueConfigDetailsError,
    SavePlannedCostRevenueConfigDetailsVariables
  >({
    mutationFn: (variables: SavePlannedCostRevenueConfigDetailsVariables) =>
      fetchSavePlannedCostRevenueConfigDetails({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface CreatePlannedCostRevenueConfigDetailsPathParams {
  masterConfigurationId: string;
}

export type CreatePlannedCostRevenueConfigDetailsError = Fetcher.ErrorWrapper<undefined>;

export type CreatePlannedCostRevenueConfigDetailsVariables = {
  body?: Schemas.PlannedCostRevenueDetailDTO;
  pathParams: CreatePlannedCostRevenueConfigDetailsPathParams;
} & DiceContext['fetcherOptions'];

export const fetchCreatePlannedCostRevenueConfigDetails = (
  variables: CreatePlannedCostRevenueConfigDetailsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.PlannedCostRevenueConfigDTO,
    CreatePlannedCostRevenueConfigDetailsError,
    Schemas.PlannedCostRevenueDetailDTO,
    {},
    {},
    CreatePlannedCostRevenueConfigDetailsPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/planned-cost-revenue/details',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreatePlannedCostRevenueConfigDetails = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PlannedCostRevenueConfigDTO,
      CreatePlannedCostRevenueConfigDetailsError,
      CreatePlannedCostRevenueConfigDetailsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.PlannedCostRevenueConfigDTO,
    CreatePlannedCostRevenueConfigDetailsError,
    CreatePlannedCostRevenueConfigDetailsVariables
  >({
    mutationFn: (variables: CreatePlannedCostRevenueConfigDetailsVariables) =>
      fetchCreatePlannedCostRevenueConfigDetails({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface FinalizeMasterConfigurationPathParams {
  masterConfigurationId: string;
}

export type FinalizeMasterConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type FinalizeMasterConfigurationVariables = {
  pathParams: FinalizeMasterConfigurationPathParams;
} & DiceContext['fetcherOptions'];

export const fetchFinalizeMasterConfiguration = (
  variables: FinalizeMasterConfigurationVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    FinalizeMasterConfigurationError,
    undefined,
    {},
    {},
    FinalizeMasterConfigurationPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/finalize',
    method: 'put',
    ...variables,
    signal,
  });

export const useFinalizeMasterConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      FinalizeMasterConfigurationError,
      FinalizeMasterConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    FinalizeMasterConfigurationError,
    FinalizeMasterConfigurationVariables
  >({
    mutationFn: (variables: FinalizeMasterConfigurationVariables) =>
      fetchFinalizeMasterConfiguration({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface PutMasterConfigurationEntityPathParams {
  masterConfigurationId: string;
}

export type PutMasterConfigurationEntityError = Fetcher.ErrorWrapper<undefined>;

export type PutMasterConfigurationEntityVariables = {
  body?: Schemas.MasterConfigurationEntityDTO;
  pathParams: PutMasterConfigurationEntityPathParams;
} & DiceContext['fetcherOptions'];

export const fetchPutMasterConfigurationEntity = (
  variables: PutMasterConfigurationEntityVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.MasterConfigurationEntityDTO,
    PutMasterConfigurationEntityError,
    Schemas.MasterConfigurationEntityDTO,
    {},
    {},
    PutMasterConfigurationEntityPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/entities',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutMasterConfigurationEntity = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MasterConfigurationEntityDTO,
      PutMasterConfigurationEntityError,
      PutMasterConfigurationEntityVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.MasterConfigurationEntityDTO,
    PutMasterConfigurationEntityError,
    PutMasterConfigurationEntityVariables
  >({
    mutationFn: (variables: PutMasterConfigurationEntityVariables) =>
      fetchPutMasterConfigurationEntity({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetCostUnitAllocationConfigPathParams {
  masterConfigurationId: string;
}

export type GetCostUnitAllocationConfigError = Fetcher.ErrorWrapper<undefined>;

export type GetCostUnitAllocationConfigResponse = Schemas.CostUnitAllocationConfigDTO[];

export type GetCostUnitAllocationConfigVariables = {
  pathParams: GetCostUnitAllocationConfigPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetCostUnitAllocationConfig = (
  variables: GetCostUnitAllocationConfigVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetCostUnitAllocationConfigResponse,
    GetCostUnitAllocationConfigError,
    undefined,
    {},
    {},
    GetCostUnitAllocationConfigPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/cost-unit-allocations',
    method: 'get',
    ...variables,
    signal,
  });

export const getCostUnitAllocationConfigQuery = (
  variables: GetCostUnitAllocationConfigVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<GetCostUnitAllocationConfigResponse>;
} => ({
  queryKey: queryKeyFn({
    path: '/master-configurations/{masterConfigurationId}/cost-unit-allocations',
    operationId: 'getCostUnitAllocationConfig',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetCostUnitAllocationConfig({ ...variables }, signal),
});

export const useSuspenseGetCostUnitAllocationConfig = <TData = GetCostUnitAllocationConfigResponse>(
  variables: GetCostUnitAllocationConfigVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetCostUnitAllocationConfigResponse,
      GetCostUnitAllocationConfigError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetCostUnitAllocationConfigResponse,
    GetCostUnitAllocationConfigError,
    TData
  >({
    ...getCostUnitAllocationConfigQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetCostUnitAllocationConfig = <TData = GetCostUnitAllocationConfigResponse>(
  variables: GetCostUnitAllocationConfigVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetCostUnitAllocationConfigResponse,
      GetCostUnitAllocationConfigError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    GetCostUnitAllocationConfigResponse,
    GetCostUnitAllocationConfigError,
    TData
  >({
    ...getCostUnitAllocationConfigQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface SaveCostUnitAllocationConfigPathParams {
  masterConfigurationId: string;
}

export type SaveCostUnitAllocationConfigError = Fetcher.ErrorWrapper<undefined>;

export type SaveCostUnitAllocationConfigResponse = Schemas.CostUnitAllocationConfigDTO[];

export type SaveCostUnitAllocationConfigRequestBody = Schemas.CostUnitAllocationConfigDTO[];

export type SaveCostUnitAllocationConfigVariables = {
  body?: SaveCostUnitAllocationConfigRequestBody;
  pathParams: SaveCostUnitAllocationConfigPathParams;
} & DiceContext['fetcherOptions'];

export const fetchSaveCostUnitAllocationConfig = (
  variables: SaveCostUnitAllocationConfigVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    SaveCostUnitAllocationConfigResponse,
    SaveCostUnitAllocationConfigError,
    SaveCostUnitAllocationConfigRequestBody,
    {},
    {},
    SaveCostUnitAllocationConfigPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/cost-unit-allocations',
    method: 'put',
    ...variables,
    signal,
  });

export const useSaveCostUnitAllocationConfig = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      SaveCostUnitAllocationConfigResponse,
      SaveCostUnitAllocationConfigError,
      SaveCostUnitAllocationConfigVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    SaveCostUnitAllocationConfigResponse,
    SaveCostUnitAllocationConfigError,
    SaveCostUnitAllocationConfigVariables
  >({
    mutationFn: (variables: SaveCostUnitAllocationConfigVariables) =>
      fetchSaveCostUnitAllocationConfig({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface SaveCostUnitAllocationConfigurationPathParams {
  masterConfigurationId: string;
  costUnitAllocationConfigId: string;
}

export type SaveCostUnitAllocationConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type SaveCostUnitAllocationConfigurationVariables = {
  body?: Schemas.CostUnitAllocationConfigDTO;
  pathParams: SaveCostUnitAllocationConfigurationPathParams;
} & DiceContext['fetcherOptions'];

export const fetchSaveCostUnitAllocationConfiguration = (
  variables: SaveCostUnitAllocationConfigurationVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.CostUnitAllocationConfigDTO,
    SaveCostUnitAllocationConfigurationError,
    Schemas.CostUnitAllocationConfigDTO,
    {},
    {},
    SaveCostUnitAllocationConfigurationPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/cost-unit-allocations/{costUnitAllocationConfigId}',
    method: 'put',
    ...variables,
    signal,
  });

export const useSaveCostUnitAllocationConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.CostUnitAllocationConfigDTO,
      SaveCostUnitAllocationConfigurationError,
      SaveCostUnitAllocationConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.CostUnitAllocationConfigDTO,
    SaveCostUnitAllocationConfigurationError,
    SaveCostUnitAllocationConfigurationVariables
  >({
    mutationFn: (variables: SaveCostUnitAllocationConfigurationVariables) =>
      fetchSaveCostUnitAllocationConfiguration({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface ActivateMasterConfigurationPathParams {
  masterConfigurationId: string;
}

export type ActivateMasterConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type ActivateMasterConfigurationVariables = {
  pathParams: ActivateMasterConfigurationPathParams;
} & DiceContext['fetcherOptions'];

export const fetchActivateMasterConfiguration = (
  variables: ActivateMasterConfigurationVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    ActivateMasterConfigurationError,
    undefined,
    {},
    {},
    ActivateMasterConfigurationPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/activate',
    method: 'put',
    ...variables,
    signal,
  });

export const useActivateMasterConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ActivateMasterConfigurationError,
      ActivateMasterConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    ActivateMasterConfigurationError,
    ActivateMasterConfigurationVariables
  >({
    mutationFn: (variables: ActivateMasterConfigurationVariables) =>
      fetchActivateMasterConfiguration({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface PutRangePathParams {
  masterConfigurationId: string;
  id: string;
}

export type PutRangeError = Fetcher.ErrorWrapper<undefined>;

export type PutRangeVariables = {
  body?: Schemas.RangeDTO;
  pathParams: PutRangePathParams;
} & DiceContext['fetcherOptions'];

export const fetchPutRange = (variables: PutRangeVariables, signal?: AbortSignal) =>
  diceFetch<Schemas.RangeConfigDTO, PutRangeError, Schemas.RangeDTO, {}, {}, PutRangePathParams>({
    url: '/master-configurations/{masterConfigurationId}/account-ranges/{id}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutRange = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.RangeConfigDTO, PutRangeError, PutRangeVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<Schemas.RangeConfigDTO, PutRangeError, PutRangeVariables>({
    mutationFn: (variables: PutRangeVariables) =>
      fetchPutRange({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface DeleteRangePathParams {
  masterConfigurationId: string;
  id: string;
}

export type DeleteRangeError = Fetcher.ErrorWrapper<undefined>;

export type DeleteRangeVariables = {
  pathParams: DeleteRangePathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeleteRange = (variables: DeleteRangeVariables, signal?: AbortSignal) =>
  diceFetch<Schemas.RangeConfigDTO, DeleteRangeError, undefined, {}, {}, DeleteRangePathParams>({
    url: '/master-configurations/{masterConfigurationId}/account-ranges/{id}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteRange = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.RangeConfigDTO, DeleteRangeError, DeleteRangeVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<Schemas.RangeConfigDTO, DeleteRangeError, DeleteRangeVariables>({
    mutationFn: (variables: DeleteRangeVariables) =>
      fetchDeleteRange({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface UseJournalAccountsPathParams {
  masterConfigurationId: string;
}

export type UseJournalAccountsError = Fetcher.ErrorWrapper<undefined>;

export type UseJournalAccountsVariables = {
  pathParams: UseJournalAccountsPathParams;
} & DiceContext['fetcherOptions'];

export const fetchUseJournalAccounts = (
  variables: UseJournalAccountsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.RangeConfigDTO,
    UseJournalAccountsError,
    undefined,
    {},
    {},
    UseJournalAccountsPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/account-ranges/use-journal-accounts',
    method: 'put',
    ...variables,
    signal,
  });

export const useUseJournalAccounts = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.RangeConfigDTO,
      UseJournalAccountsError,
      UseJournalAccountsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.RangeConfigDTO,
    UseJournalAccountsError,
    UseJournalAccountsVariables
  >({
    mutationFn: (variables: UseJournalAccountsVariables) =>
      fetchUseJournalAccounts({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface UseAccountPlanAccountsPathParams {
  masterConfigurationId: string;
}

export type UseAccountPlanAccountsError = Fetcher.ErrorWrapper<undefined>;

export type UseAccountPlanAccountsVariables = {
  pathParams: UseAccountPlanAccountsPathParams;
} & DiceContext['fetcherOptions'];

export const fetchUseAccountPlanAccounts = (
  variables: UseAccountPlanAccountsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.RangeConfigDTO,
    UseAccountPlanAccountsError,
    undefined,
    {},
    {},
    UseAccountPlanAccountsPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/account-ranges/use-account-plan-accounts',
    method: 'put',
    ...variables,
    signal,
  });

export const useUseAccountPlanAccounts = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.RangeConfigDTO,
      UseAccountPlanAccountsError,
      UseAccountPlanAccountsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.RangeConfigDTO,
    UseAccountPlanAccountsError,
    UseAccountPlanAccountsVariables
  >({
    mutationFn: (variables: UseAccountPlanAccountsVariables) =>
      fetchUseAccountPlanAccounts({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateMasterConfigurationsError = Fetcher.ErrorWrapper<undefined>;

export type UpdateMasterConfigurationsVariables = {
  body?: Schemas.MasterConfigurationRequest;
} & DiceContext['fetcherOptions'];

export const fetchUpdateMasterConfigurations = (
  variables: UpdateMasterConfigurationsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.EntityUpsertedResponse,
    UpdateMasterConfigurationsError,
    Schemas.MasterConfigurationRequest,
    {},
    {},
    {}
  >({ url: '/master-configuration', method: 'put', ...variables, signal });

export const useUpdateMasterConfigurations = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.EntityUpsertedResponse,
      UpdateMasterConfigurationsError,
      UpdateMasterConfigurationsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.EntityUpsertedResponse,
    UpdateMasterConfigurationsError,
    UpdateMasterConfigurationsVariables
  >({
    mutationFn: (variables: UpdateMasterConfigurationsVariables) =>
      fetchUpdateMasterConfigurations({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpsertMasterConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type UpsertMasterConfigurationVariables = {
  body: Schemas.MasterConfiguration;
} & DiceContext['fetcherOptions'];

export const fetchUpsertMasterConfiguration = (
  variables: UpsertMasterConfigurationVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.EntityUpsertedResponse,
    UpsertMasterConfigurationError,
    Schemas.MasterConfiguration,
    {},
    {},
    {}
  >({ url: '/master-configuration', method: 'post', ...variables, signal });

export const useUpsertMasterConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.EntityUpsertedResponse,
      UpsertMasterConfigurationError,
      UpsertMasterConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.EntityUpsertedResponse,
    UpsertMasterConfigurationError,
    UpsertMasterConfigurationVariables
  >({
    mutationFn: (variables: UpsertMasterConfigurationVariables) =>
      fetchUpsertMasterConfiguration({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface UpdateCostTypeAccountConfigAccountsPathParams {
  masterConfigurationId: string;
}

export type UpdateCostTypeAccountConfigAccountsError = Fetcher.ErrorWrapper<undefined>;

export type UpdateCostTypeAccountConfigAccountsVariables = {
  pathParams: UpdateCostTypeAccountConfigAccountsPathParams;
} & DiceContext['fetcherOptions'];

export const fetchUpdateCostTypeAccountConfigAccounts = (
  variables: UpdateCostTypeAccountConfigAccountsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    UpdateCostTypeAccountConfigAccountsError,
    undefined,
    {},
    {},
    UpdateCostTypeAccountConfigAccountsPathParams
  >({
    url: '/master-configs/{masterConfigurationId}/cost-type-accounts/update-accounts',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpdateCostTypeAccountConfigAccounts = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdateCostTypeAccountConfigAccountsError,
      UpdateCostTypeAccountConfigAccountsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    UpdateCostTypeAccountConfigAccountsError,
    UpdateCostTypeAccountConfigAccountsVariables
  >({
    mutationFn: (variables: UpdateCostTypeAccountConfigAccountsVariables) =>
      fetchUpdateCostTypeAccountConfigAccounts({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface SaveAllocationKeyConfigurationPathParams {
  masterConfigurationId: string;
  allocationKeyConfigId: string;
}

export type SaveAllocationKeyConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type SaveAllocationKeyConfigurationVariables = {
  body?: Schemas.AllocationKeyConfigDTO;
  pathParams: SaveAllocationKeyConfigurationPathParams;
} & DiceContext['fetcherOptions'];

export const fetchSaveAllocationKeyConfiguration = (
  variables: SaveAllocationKeyConfigurationVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.AllocationKeyConfigDTO,
    SaveAllocationKeyConfigurationError,
    Schemas.AllocationKeyConfigDTO,
    {},
    {},
    SaveAllocationKeyConfigurationPathParams
  >({
    url: '/master-configs/{masterConfigurationId}/allocation-key-configs/{allocationKeyConfigId}',
    method: 'put',
    ...variables,
    signal,
  });

export const useSaveAllocationKeyConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AllocationKeyConfigDTO,
      SaveAllocationKeyConfigurationError,
      SaveAllocationKeyConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.AllocationKeyConfigDTO,
    SaveAllocationKeyConfigurationError,
    SaveAllocationKeyConfigurationVariables
  >({
    mutationFn: (variables: SaveAllocationKeyConfigurationVariables) =>
      fetchSaveAllocationKeyConfiguration({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpsertConfigError = Fetcher.ErrorWrapper<undefined>;

export type UpsertConfigVariables = {
  body?: Schemas.CostTypeAccountConfigDTO;
} & DiceContext['fetcherOptions'];

export const fetchUpsertConfig = (variables: UpsertConfigVariables, signal?: AbortSignal) =>
  diceFetch<
    Schemas.CostTypeAccountConfigDTO,
    UpsertConfigError,
    Schemas.CostTypeAccountConfigDTO,
    {},
    {},
    {}
  >({ url: '/master-configs/cost-type-accounts', method: 'put', ...variables, signal });

export const useUpsertConfig = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.CostTypeAccountConfigDTO,
      UpsertConfigError,
      UpsertConfigVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.CostTypeAccountConfigDTO,
    UpsertConfigError,
    UpsertConfigVariables
  >({
    mutationFn: (variables: UpsertConfigVariables) =>
      fetchUpsertConfig({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SaveAllocationKeyConfigError = Fetcher.ErrorWrapper<undefined>;

export type SaveAllocationKeyConfigResponse = Schemas.AllocationKeyConfigDTO[];

export type SaveAllocationKeyConfigRequestBody = Schemas.AllocationKeyConfigDTO[];

export type SaveAllocationKeyConfigVariables = {
  body?: SaveAllocationKeyConfigRequestBody;
} & DiceContext['fetcherOptions'];

export const fetchSaveAllocationKeyConfig = (
  variables: SaveAllocationKeyConfigVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    SaveAllocationKeyConfigResponse,
    SaveAllocationKeyConfigError,
    SaveAllocationKeyConfigRequestBody,
    {},
    {},
    {}
  >({ url: '/master-configs/allocation-key-configs', method: 'put', ...variables, signal });

export const useSaveAllocationKeyConfig = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      SaveAllocationKeyConfigResponse,
      SaveAllocationKeyConfigError,
      SaveAllocationKeyConfigVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    SaveAllocationKeyConfigResponse,
    SaveAllocationKeyConfigError,
    SaveAllocationKeyConfigVariables
  >({
    mutationFn: (variables: SaveAllocationKeyConfigVariables) =>
      fetchSaveAllocationKeyConfig({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetFileErpTypesQueryParams {
  clientId: string;
  /**
   * @minimum 1900
   * @format int32
   */
  calculationYear: number;
  yearId: string;
}

export type GetFileErpTypesError = Fetcher.ErrorWrapper<undefined>;

export type GetFileErpTypesResponse = Schemas.FileErpTypeDTO[];

export type GetFileErpTypesVariables = {
  queryParams: GetFileErpTypesQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchGetFileErpTypes = (variables: GetFileErpTypesVariables, signal?: AbortSignal) =>
  diceFetch<
    GetFileErpTypesResponse,
    GetFileErpTypesError,
    undefined,
    {},
    GetFileErpTypesQueryParams,
    {}
  >({ url: '/imports/fileErpTypes', method: 'get', ...variables, signal });

export const getFileErpTypesQuery = (
  variables: GetFileErpTypesVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<GetFileErpTypesResponse>;
} => ({
  queryKey: queryKeyFn({
    path: '/imports/fileErpTypes',
    operationId: 'getFileErpTypes',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) => fetchGetFileErpTypes({ ...variables }, signal),
});

export const useSuspenseGetFileErpTypes = <TData = GetFileErpTypesResponse>(
  variables: GetFileErpTypesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetFileErpTypesResponse, GetFileErpTypesError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<GetFileErpTypesResponse, GetFileErpTypesError, TData>({
    ...getFileErpTypesQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetFileErpTypes = <TData = GetFileErpTypesResponse>(
  variables: GetFileErpTypesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetFileErpTypesResponse, GetFileErpTypesError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<GetFileErpTypesResponse, GetFileErpTypesError, TData>({
    ...getFileErpTypesQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export type UpdateFileErpTypesError = Fetcher.ErrorWrapper<undefined>;

export type UpdateFileErpTypesVariables = {
  body?: Schemas.FileErpTypeDTO;
} & DiceContext['fetcherOptions'];

export const fetchUpdateFileErpTypes = (
  variables: UpdateFileErpTypesVariables,
  signal?: AbortSignal,
) =>
  diceFetch<string, UpdateFileErpTypesError, Schemas.FileErpTypeDTO, {}, {}, {}>({
    url: '/imports/fileErpTypes',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpdateFileErpTypes = (
  options?: Omit<
    reactQuery.UseMutationOptions<string, UpdateFileErpTypesError, UpdateFileErpTypesVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<string, UpdateFileErpTypesError, UpdateFileErpTypesVariables>({
    mutationFn: (variables: UpdateFileErpTypesVariables) =>
      fetchUpdateFileErpTypes({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SaveFileErpTypesError = Fetcher.ErrorWrapper<undefined>;

export type SaveFileErpTypesVariables = {
  body?: Schemas.FileErpTypeDTO;
} & DiceContext['fetcherOptions'];

export const fetchSaveFileErpTypes = (variables: SaveFileErpTypesVariables, signal?: AbortSignal) =>
  diceFetch<string, SaveFileErpTypesError, Schemas.FileErpTypeDTO, {}, {}, {}>({
    url: '/imports/fileErpTypes',
    method: 'post',
    ...variables,
    signal,
  });

export const useSaveFileErpTypes = (
  options?: Omit<
    reactQuery.UseMutationOptions<string, SaveFileErpTypesError, SaveFileErpTypesVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<string, SaveFileErpTypesError, SaveFileErpTypesVariables>({
    mutationFn: (variables: SaveFileErpTypesVariables) =>
      fetchSaveFileErpTypes({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteFileErpTypesError = Fetcher.ErrorWrapper<undefined>;

export type DeleteFileErpTypesVariables = {
  body?: Schemas.FileErpTypeDTO;
} & DiceContext['fetcherOptions'];

export const fetchDeleteFileErpTypes = (
  variables: DeleteFileErpTypesVariables,
  signal?: AbortSignal,
) =>
  diceFetch<string, DeleteFileErpTypesError, Schemas.FileErpTypeDTO, {}, {}, {}>({
    url: '/imports/fileErpTypes',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteFileErpTypes = (
  options?: Omit<
    reactQuery.UseMutationOptions<string, DeleteFileErpTypesError, DeleteFileErpTypesVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<string, DeleteFileErpTypesError, DeleteFileErpTypesVariables>({
    mutationFn: (variables: DeleteFileErpTypesVariables) =>
      fetchDeleteFileErpTypes({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetPublicFacilitiesByClientPathParams {
  clientId: string;
}

export interface GetPublicFacilitiesByClientHeaders {
  authorization?: string;
}

export type GetPublicFacilitiesByClientError = Fetcher.ErrorWrapper<undefined>;

export type GetPublicFacilitiesByClientResponse = Schemas.PublicFacilityDTO[];

export type GetPublicFacilitiesByClientVariables = {
  headers?: GetPublicFacilitiesByClientHeaders;
  pathParams: GetPublicFacilitiesByClientPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetPublicFacilitiesByClient = (
  variables: GetPublicFacilitiesByClientVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetPublicFacilitiesByClientResponse,
    GetPublicFacilitiesByClientError,
    undefined,
    GetPublicFacilitiesByClientHeaders,
    {},
    GetPublicFacilitiesByClientPathParams
  >({ url: '/clients/{clientId}/public-facilities', method: 'get', ...variables, signal });

export const getPublicFacilitiesByClientQuery = (
  variables: GetPublicFacilitiesByClientVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<GetPublicFacilitiesByClientResponse>;
} => ({
  queryKey: queryKeyFn({
    path: '/clients/{clientId}/public-facilities',
    operationId: 'getPublicFacilitiesByClient',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetPublicFacilitiesByClient({ ...variables }, signal),
});

export const useSuspenseGetPublicFacilitiesByClient = <TData = GetPublicFacilitiesByClientResponse>(
  variables: GetPublicFacilitiesByClientVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetPublicFacilitiesByClientResponse,
      GetPublicFacilitiesByClientError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetPublicFacilitiesByClientResponse,
    GetPublicFacilitiesByClientError,
    TData
  >({
    ...getPublicFacilitiesByClientQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetPublicFacilitiesByClient = <TData = GetPublicFacilitiesByClientResponse>(
  variables: GetPublicFacilitiesByClientVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetPublicFacilitiesByClientResponse,
      GetPublicFacilitiesByClientError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    GetPublicFacilitiesByClientResponse,
    GetPublicFacilitiesByClientError,
    TData
  >({
    ...getPublicFacilitiesByClientQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface PutPublicFacilityPathParams {
  clientId: string;
}

export type PutPublicFacilityError = Fetcher.ErrorWrapper<undefined>;

export type PutPublicFacilityVariables = {
  body?: Schemas.PublicFacilityDTO;
  pathParams: PutPublicFacilityPathParams;
} & DiceContext['fetcherOptions'];

export const fetchPutPublicFacility = (
  variables: PutPublicFacilityVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.PublicFacilityDTO,
    PutPublicFacilityError,
    Schemas.PublicFacilityDTO,
    {},
    {},
    PutPublicFacilityPathParams
  >({ url: '/clients/{clientId}/public-facilities', method: 'put', ...variables, signal });

export const usePutPublicFacility = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PublicFacilityDTO,
      PutPublicFacilityError,
      PutPublicFacilityVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.PublicFacilityDTO,
    PutPublicFacilityError,
    PutPublicFacilityVariables
  >({
    mutationFn: (variables: PutPublicFacilityVariables) =>
      fetchPutPublicFacility({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface PostPublicFacilityPathParams {
  clientId: string;
}

export type PostPublicFacilityError = Fetcher.ErrorWrapper<undefined>;

export type PostPublicFacilityVariables = {
  body?: Schemas.PublicFacilityDTO;
  pathParams: PostPublicFacilityPathParams;
} & DiceContext['fetcherOptions'];

export const fetchPostPublicFacility = (
  variables: PostPublicFacilityVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.PublicFacilityDTO,
    PostPublicFacilityError,
    Schemas.PublicFacilityDTO,
    {},
    {},
    PostPublicFacilityPathParams
  >({ url: '/clients/{clientId}/public-facilities', method: 'post', ...variables, signal });

export const usePostPublicFacility = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PublicFacilityDTO,
      PostPublicFacilityError,
      PostPublicFacilityVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.PublicFacilityDTO,
    PostPublicFacilityError,
    PostPublicFacilityVariables
  >({
    mutationFn: (variables: PostPublicFacilityVariables) =>
      fetchPostPublicFacility({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetAllAssetsQueryParams {
  yearId: string;
  masterConfigurationId?: string;
  includeForecastAssets?: boolean;
  assetType?: 'UPLOADED_ASSET' | 'ERP_ASSET' | 'FORECAST_ASSET' | 'FUTURE_ASSET';
}

export type GetAllAssetsError = Fetcher.ErrorWrapper<undefined>;

export type GetAllAssetsResponse = Schemas.Asset[];

export type GetAllAssetsVariables = {
  queryParams: GetAllAssetsQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchGetAllAssets = (variables: GetAllAssetsVariables, signal?: AbortSignal) =>
  diceFetch<GetAllAssetsResponse, GetAllAssetsError, undefined, {}, GetAllAssetsQueryParams, {}>({
    url: '/assets',
    method: 'get',
    ...variables,
    signal,
  });

export const getAllAssetsQuery = (
  variables: GetAllAssetsVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<GetAllAssetsResponse>;
} => ({
  queryKey: queryKeyFn({
    path: '/assets',
    operationId: 'getAllAssets',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) => fetchGetAllAssets({ ...variables }, signal),
});

export const useSuspenseGetAllAssets = <TData = GetAllAssetsResponse>(
  variables: GetAllAssetsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetAllAssetsResponse, GetAllAssetsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<GetAllAssetsResponse, GetAllAssetsError, TData>({
    ...getAllAssetsQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetAllAssets = <TData = GetAllAssetsResponse>(
  variables: GetAllAssetsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetAllAssetsResponse, GetAllAssetsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<GetAllAssetsResponse, GetAllAssetsError, TData>({
    ...getAllAssetsQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export type UpdateAssetError = Fetcher.ErrorWrapper<undefined>;

export type UpdateAssetVariables = {
  body?: Schemas.Asset;
} & DiceContext['fetcherOptions'];

export const fetchUpdateAsset = (variables: UpdateAssetVariables, signal?: AbortSignal) =>
  diceFetch<undefined, UpdateAssetError, Schemas.Asset, {}, {}, {}>({
    url: '/assets',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpdateAsset = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, UpdateAssetError, UpdateAssetVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, UpdateAssetError, UpdateAssetVariables>({
    mutationFn: (variables: UpdateAssetVariables) =>
      fetchUpdateAsset({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type InsertAssetsError = Fetcher.ErrorWrapper<undefined>;

export type InsertAssetsResponse = Schemas.Asset[];

export type InsertAssetsVariables = {
  body: Schemas.AssetFileRequest;
} & DiceContext['fetcherOptions'];

export const fetchInsertAssets = (variables: InsertAssetsVariables, signal?: AbortSignal) =>
  diceFetch<InsertAssetsResponse, InsertAssetsError, Schemas.AssetFileRequest, {}, {}, {}>({
    url: '/assets',
    method: 'post',
    ...variables,
    signal,
  });

export const useInsertAssets = (
  options?: Omit<
    reactQuery.UseMutationOptions<InsertAssetsResponse, InsertAssetsError, InsertAssetsVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<InsertAssetsResponse, InsertAssetsError, InsertAssetsVariables>({
    mutationFn: (variables: InsertAssetsVariables) =>
      fetchInsertAssets({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface DeleteFileQueryParams {
  yearId: string;
  masterConfigurationId?: string;
}

export type DeleteFileError = Fetcher.ErrorWrapper<undefined>;

export type DeleteFileVariables = {
  queryParams: DeleteFileQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchDeleteFile = (variables: DeleteFileVariables, signal?: AbortSignal) =>
  diceFetch<undefined, DeleteFileError, undefined, {}, DeleteFileQueryParams, {}>({
    url: '/assets',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteFile = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteFileError, DeleteFileVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, DeleteFileError, DeleteFileVariables>({
    mutationFn: (variables: DeleteFileVariables) =>
      fetchDeleteFile({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface UpdateAssetExtendedInfoByAssetIdPathParams {
  assetId: string;
  masterConfigurationId: string;
}

export type UpdateAssetExtendedInfoByAssetIdError = Fetcher.ErrorWrapper<undefined>;

export type UpdateAssetExtendedInfoByAssetIdVariables = {
  body?: Schemas.AssetExtendedInfo;
  pathParams: UpdateAssetExtendedInfoByAssetIdPathParams;
} & DiceContext['fetcherOptions'];

export const fetchUpdateAssetExtendedInfoByAssetId = (
  variables: UpdateAssetExtendedInfoByAssetIdVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    UpdateAssetExtendedInfoByAssetIdError,
    Schemas.AssetExtendedInfo,
    {},
    {},
    UpdateAssetExtendedInfoByAssetIdPathParams
  >({
    url: '/assets/{assetId}/assetExtendedInfo/{masterConfigurationId}',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpdateAssetExtendedInfoByAssetId = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdateAssetExtendedInfoByAssetIdError,
      UpdateAssetExtendedInfoByAssetIdVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    UpdateAssetExtendedInfoByAssetIdError,
    UpdateAssetExtendedInfoByAssetIdVariables
  >({
    mutationFn: (variables: UpdateAssetExtendedInfoByAssetIdVariables) =>
      fetchUpdateAssetExtendedInfoByAssetId({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateAssetGroupError = Fetcher.ErrorWrapper<undefined>;

export type UpdateAssetGroupVariables = {
  body?: Schemas.AssetGroupDTO;
} & DiceContext['fetcherOptions'];

export const fetchUpdateAssetGroup = (variables: UpdateAssetGroupVariables, signal?: AbortSignal) =>
  diceFetch<Schemas.AssetGroupDTO, UpdateAssetGroupError, Schemas.AssetGroupDTO, {}, {}, {}>({
    url: '/asset-group',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpdateAssetGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AssetGroupDTO,
      UpdateAssetGroupError,
      UpdateAssetGroupVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.AssetGroupDTO,
    UpdateAssetGroupError,
    UpdateAssetGroupVariables
  >({
    mutationFn: (variables: UpdateAssetGroupVariables) =>
      fetchUpdateAssetGroup({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CreateAssetGroupError = Fetcher.ErrorWrapper<undefined>;

export type CreateAssetGroupVariables = {
  body?: Schemas.AssetGroupDTO;
} & DiceContext['fetcherOptions'];

export const fetchCreateAssetGroup = (variables: CreateAssetGroupVariables, signal?: AbortSignal) =>
  diceFetch<Schemas.AssetGroupDTO, CreateAssetGroupError, Schemas.AssetGroupDTO, {}, {}, {}>({
    url: '/asset-group',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreateAssetGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AssetGroupDTO,
      CreateAssetGroupError,
      CreateAssetGroupVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.AssetGroupDTO,
    CreateAssetGroupError,
    CreateAssetGroupVariables
  >({
    mutationFn: (variables: CreateAssetGroupVariables) =>
      fetchCreateAssetGroup({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetAllAssets1QueryParams {
  yearId: string;
  masterConfigId: string;
}

export type GetAllAssets1Error = Fetcher.ErrorWrapper<undefined>;

export type GetAllAssets1Variables = {
  queryParams: GetAllAssets1QueryParams;
} & DiceContext['fetcherOptions'];

export const fetchGetAllAssets1 = (variables: GetAllAssets1Variables, signal?: AbortSignal) =>
  diceFetch<
    Schemas.AssetConfigMappingResponseObject,
    GetAllAssets1Error,
    undefined,
    {},
    GetAllAssets1QueryParams,
    {}
  >({ url: '/asset-cc-config', method: 'get', ...variables, signal });

export const getAllAssets1Query = (
  variables: GetAllAssets1Variables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({
    signal,
  }: {
    signal?: AbortSignal;
  }) => Promise<Schemas.AssetConfigMappingResponseObject>;
} => ({
  queryKey: queryKeyFn({
    path: '/asset-cc-config',
    operationId: 'getAllAssets1',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) => fetchGetAllAssets1({ ...variables }, signal),
});

export const useSuspenseGetAllAssets1 = <TData = Schemas.AssetConfigMappingResponseObject>(
  variables: GetAllAssets1Variables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.AssetConfigMappingResponseObject, GetAllAssets1Error, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    Schemas.AssetConfigMappingResponseObject,
    GetAllAssets1Error,
    TData
  >({
    ...getAllAssets1Query(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetAllAssets1 = <TData = Schemas.AssetConfigMappingResponseObject>(
  variables: GetAllAssets1Variables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.AssetConfigMappingResponseObject, GetAllAssets1Error, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<Schemas.AssetConfigMappingResponseObject, GetAllAssets1Error, TData>({
    ...getAllAssets1Query(variables),
    ...options,
    ...queryOptions,
  });
};

export type UpdateConfigToAssetError = Fetcher.ErrorWrapper<undefined>;

export type UpdateConfigToAssetVariables = {
  body?: Schemas.AssetConfigMappingRequest;
} & DiceContext['fetcherOptions'];

export const fetchUpdateConfigToAsset = (
  variables: UpdateConfigToAssetVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.AssetConfigMappingResponseObject,
    UpdateConfigToAssetError,
    Schemas.AssetConfigMappingRequest,
    {},
    {},
    {}
  >({ url: '/asset-cc-config', method: 'put', ...variables, signal });

export const useUpdateConfigToAsset = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AssetConfigMappingResponseObject,
      UpdateConfigToAssetError,
      UpdateConfigToAssetVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.AssetConfigMappingResponseObject,
    UpdateConfigToAssetError,
    UpdateConfigToAssetVariables
  >({
    mutationFn: (variables: UpdateConfigToAssetVariables) =>
      fetchUpdateConfigToAsset({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type MapConfigToAssetsError = Fetcher.ErrorWrapper<undefined>;

export type MapConfigToAssetsVariables = {
  body?: Schemas.AssetConfigMappingRequest;
} & DiceContext['fetcherOptions'];

export const fetchMapConfigToAssets = (
  variables: MapConfigToAssetsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.AssetConfigMappingResponseObject,
    MapConfigToAssetsError,
    Schemas.AssetConfigMappingRequest,
    {},
    {},
    {}
  >({ url: '/asset-cc-config', method: 'post', ...variables, signal });

export const useMapConfigToAssets = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AssetConfigMappingResponseObject,
      MapConfigToAssetsError,
      MapConfigToAssetsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.AssetConfigMappingResponseObject,
    MapConfigToAssetsError,
    MapConfigToAssetsVariables
  >({
    mutationFn: (variables: MapConfigToAssetsVariables) =>
      fetchMapConfigToAssets({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface CopyAssetCcConfigPathParams {
  destinationYearId: string;
  destinationMasterConfigId: string;
}

export type CopyAssetCcConfigError = Fetcher.ErrorWrapper<undefined>;

export type CopyAssetCcConfigVariables = {
  body?: Schemas.AssetConfigMappingRequest;
  pathParams: CopyAssetCcConfigPathParams;
} & DiceContext['fetcherOptions'];

export const fetchCopyAssetCcConfig = (
  variables: CopyAssetCcConfigVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.AssetConfigMappingResponseObject,
    CopyAssetCcConfigError,
    Schemas.AssetConfigMappingRequest,
    {},
    {},
    CopyAssetCcConfigPathParams
  >({
    url: '/asset-cc-config/{destinationYearId}/{destinationMasterConfigId}',
    method: 'put',
    ...variables,
    signal,
  });

export const useCopyAssetCcConfig = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AssetConfigMappingResponseObject,
      CopyAssetCcConfigError,
      CopyAssetCcConfigVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.AssetConfigMappingResponseObject,
    CopyAssetCcConfigError,
    CopyAssetCcConfigVariables
  >({
    mutationFn: (variables: CopyAssetCcConfigVariables) =>
      fetchCopyAssetCcConfig({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ResetManuallySetBabCCForAssetsError = Fetcher.ErrorWrapper<undefined>;

export type ResetManuallySetBabCCForAssetsVariables = {
  body?: Schemas.AssetConfigMappingRequest;
} & DiceContext['fetcherOptions'];

export const fetchResetManuallySetBabCCForAssets = (
  variables: ResetManuallySetBabCCForAssetsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.AssetConfigMappingResponseObject,
    ResetManuallySetBabCCForAssetsError,
    Schemas.AssetConfigMappingRequest,
    {},
    {},
    {}
  >({ url: '/asset-cc-config/reset-bab-cc', method: 'put', ...variables, signal });

export const useResetManuallySetBabCCForAssets = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AssetConfigMappingResponseObject,
      ResetManuallySetBabCCForAssetsError,
      ResetManuallySetBabCCForAssetsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.AssetConfigMappingResponseObject,
    ResetManuallySetBabCCForAssetsError,
    ResetManuallySetBabCCForAssetsVariables
  >({
    mutationFn: (variables: ResetManuallySetBabCCForAssetsVariables) =>
      fetchResetManuallySetBabCCForAssets({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetAllAssetCategoryConfigsQueryParams {
  yearId: string;
  masterConfigId: string;
}

export type GetAllAssetCategoryConfigsError = Fetcher.ErrorWrapper<undefined>;

export type GetAllAssetCategoryConfigsVariables = {
  queryParams: GetAllAssetCategoryConfigsQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchGetAllAssetCategoryConfigs = (
  variables: GetAllAssetCategoryConfigsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.AssetCategoryConfigResponseObject,
    GetAllAssetCategoryConfigsError,
    undefined,
    {},
    GetAllAssetCategoryConfigsQueryParams,
    {}
  >({ url: '/asset-category', method: 'get', ...variables, signal });

export const getAllAssetCategoryConfigsQuery = (
  variables: GetAllAssetCategoryConfigsVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({
    signal,
  }: {
    signal?: AbortSignal;
  }) => Promise<Schemas.AssetCategoryConfigResponseObject>;
} => ({
  queryKey: queryKeyFn({
    path: '/asset-category',
    operationId: 'getAllAssetCategoryConfigs',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetAllAssetCategoryConfigs({ ...variables }, signal),
});

export const useSuspenseGetAllAssetCategoryConfigs = <
  TData = Schemas.AssetCategoryConfigResponseObject,
>(
  variables: GetAllAssetCategoryConfigsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.AssetCategoryConfigResponseObject,
      GetAllAssetCategoryConfigsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    Schemas.AssetCategoryConfigResponseObject,
    GetAllAssetCategoryConfigsError,
    TData
  >({
    ...getAllAssetCategoryConfigsQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetAllAssetCategoryConfigs = <TData = Schemas.AssetCategoryConfigResponseObject>(
  variables: GetAllAssetCategoryConfigsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.AssetCategoryConfigResponseObject,
      GetAllAssetCategoryConfigsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    Schemas.AssetCategoryConfigResponseObject,
    GetAllAssetCategoryConfigsError,
    TData
  >({
    ...getAllAssetCategoryConfigsQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export type UpdateAssetCategoryConfigError = Fetcher.ErrorWrapper<undefined>;

export type UpdateAssetCategoryConfigVariables = {
  body?: Schemas.AssetCategoryConfigRequest;
} & DiceContext['fetcherOptions'];

export const fetchUpdateAssetCategoryConfig = (
  variables: UpdateAssetCategoryConfigVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.AssetCategoryConfigResponseObject,
    UpdateAssetCategoryConfigError,
    Schemas.AssetCategoryConfigRequest,
    {},
    {},
    {}
  >({ url: '/asset-category', method: 'put', ...variables, signal });

export const useUpdateAssetCategoryConfig = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AssetCategoryConfigResponseObject,
      UpdateAssetCategoryConfigError,
      UpdateAssetCategoryConfigVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.AssetCategoryConfigResponseObject,
    UpdateAssetCategoryConfigError,
    UpdateAssetCategoryConfigVariables
  >({
    mutationFn: (variables: UpdateAssetCategoryConfigVariables) =>
      fetchUpdateAssetCategoryConfig({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface PostMasterConfigurationPathParams {
  yearCalculationId: string;
}

export type PostMasterConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type PostMasterConfigurationVariables = {
  body: Schemas.MasterConfiguration;
  pathParams: PostMasterConfigurationPathParams;
} & DiceContext['fetcherOptions'];

export const fetchPostMasterConfiguration = (
  variables: PostMasterConfigurationVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.MasterConfiguration,
    PostMasterConfigurationError,
    Schemas.MasterConfiguration,
    {},
    {},
    PostMasterConfigurationPathParams
  >({
    url: '/year-calculations/{yearCalculationId}/master-configurations',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostMasterConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MasterConfiguration,
      PostMasterConfigurationError,
      PostMasterConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.MasterConfiguration,
    PostMasterConfigurationError,
    PostMasterConfigurationVariables
  >({
    mutationFn: (variables: PostMasterConfigurationVariables) =>
      fetchPostMasterConfiguration({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface CreateWithdrawCapitalConfigQueryParams {
  yearId: string;
  masterConfigId?: string;
}

export type CreateWithdrawCapitalConfigError = Fetcher.ErrorWrapper<undefined>;

export type CreateWithdrawCapitalConfigVariables = {
  queryParams: CreateWithdrawCapitalConfigQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchCreateWithdrawCapitalConfig = (
  variables: CreateWithdrawCapitalConfigVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    CreateWithdrawCapitalConfigError,
    undefined,
    {},
    CreateWithdrawCapitalConfigQueryParams,
    {}
  >({ url: '/withdraw-capital-config', method: 'post', ...variables, signal });

export const useCreateWithdrawCapitalConfig = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      CreateWithdrawCapitalConfigError,
      CreateWithdrawCapitalConfigVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    CreateWithdrawCapitalConfigError,
    CreateWithdrawCapitalConfigVariables
  >({
    mutationFn: (variables: CreateWithdrawCapitalConfigVariables) =>
      fetchCreateWithdrawCapitalConfig({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface UploadFileQueryParams {
  type: 'ASSETS' | 'LABELS' | 'LEDGER_ACCOUNT' | 'JOURNAL' | 'ACCOUNT_PLAN';
  yearId: string;
}

export type UploadFileError = Fetcher.ErrorWrapper<undefined>;

export interface UploadFileRequestBody {
  /**
   * @format binary
   */
  uploadFile: Blob;
}

export type UploadFileVariables = {
  body: UploadFileRequestBody;
  queryParams: UploadFileQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchUploadFile = (variables: UploadFileVariables, signal?: AbortSignal) =>
  diceFetch<
    Schemas.UploadResponse,
    UploadFileError,
    UploadFileRequestBody,
    {},
    UploadFileQueryParams,
    {}
  >({ url: '/upload', method: 'post', ...variables, signal });

export const useUploadFile = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.UploadResponse, UploadFileError, UploadFileVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<Schemas.UploadResponse, UploadFileError, UploadFileVariables>({
    mutationFn: (variables: UploadFileVariables) =>
      fetchUploadFile({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetYearsPathParams {
  publicFacilityId: string;
}

export type GetYearsError = Fetcher.ErrorWrapper<undefined>;

export type GetYearsResponse = Schemas.YearDTOV2[];

export type GetYearsVariables = {
  pathParams: GetYearsPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetYears = (variables: GetYearsVariables, signal?: AbortSignal) =>
  diceFetch<GetYearsResponse, GetYearsError, undefined, {}, {}, GetYearsPathParams>({
    url: '/public-facilities/{publicFacilityId}/years',
    method: 'get',
    ...variables,
    signal,
  });

export const getYearsQuery = (
  variables: GetYearsVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<GetYearsResponse>;
} => ({
  queryKey: queryKeyFn({
    path: '/public-facilities/{publicFacilityId}/years',
    operationId: 'getYears',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) => fetchGetYears({ ...variables }, signal),
});

export const useSuspenseGetYears = <TData = GetYearsResponse>(
  variables: GetYearsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetYearsResponse, GetYearsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<GetYearsResponse, GetYearsError, TData>({
    ...getYearsQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetYears = <TData = GetYearsResponse>(
  variables: GetYearsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetYearsResponse, GetYearsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<GetYearsResponse, GetYearsError, TData>({
    ...getYearsQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface PostYearPathParams {
  publicFacilityId: string;
}

export type PostYearError = Fetcher.ErrorWrapper<undefined>;

export type PostYearVariables = {
  body?: Schemas.YearDTOV2;
  pathParams: PostYearPathParams;
} & DiceContext['fetcherOptions'];

export const fetchPostYear = (variables: PostYearVariables, signal?: AbortSignal) =>
  diceFetch<Schemas.YearDTOV2, PostYearError, Schemas.YearDTOV2, {}, {}, PostYearPathParams>({
    url: '/public-facilities/{publicFacilityId}/years',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostYear = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.YearDTOV2, PostYearError, PostYearVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<Schemas.YearDTOV2, PostYearError, PostYearVariables>({
    mutationFn: (variables: PostYearVariables) =>
      fetchPostYear({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface AddPublicFacilityFavoritePathParams {
  publicFacilityId: string;
  yearId: string;
}

export interface AddPublicFacilityFavoriteHeaders {
  authorization?: string;
}

export type AddPublicFacilityFavoriteError = Fetcher.ErrorWrapper<undefined>;

export type AddPublicFacilityFavoriteVariables = {
  headers?: AddPublicFacilityFavoriteHeaders;
  pathParams: AddPublicFacilityFavoritePathParams;
} & DiceContext['fetcherOptions'];

export const fetchAddPublicFacilityFavorite = (
  variables: AddPublicFacilityFavoriteVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    AddPublicFacilityFavoriteError,
    undefined,
    AddPublicFacilityFavoriteHeaders,
    {},
    AddPublicFacilityFavoritePathParams
  >({
    url: '/public-facilities/{publicFacilityId}/years/{yearId}/favorites',
    method: 'post',
    ...variables,
    signal,
  });

export const useAddPublicFacilityFavorite = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      AddPublicFacilityFavoriteError,
      AddPublicFacilityFavoriteVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    AddPublicFacilityFavoriteError,
    AddPublicFacilityFavoriteVariables
  >({
    mutationFn: (variables: AddPublicFacilityFavoriteVariables) =>
      fetchAddPublicFacilityFavorite({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface RemovePublicFacilityFavoritePathParams {
  publicFacilityId: string;
  yearId: string;
}

export interface RemovePublicFacilityFavoriteHeaders {
  authorization?: string;
}

export type RemovePublicFacilityFavoriteError = Fetcher.ErrorWrapper<undefined>;

export type RemovePublicFacilityFavoriteVariables = {
  headers?: RemovePublicFacilityFavoriteHeaders;
  pathParams: RemovePublicFacilityFavoritePathParams;
} & DiceContext['fetcherOptions'];

export const fetchRemovePublicFacilityFavorite = (
  variables: RemovePublicFacilityFavoriteVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    RemovePublicFacilityFavoriteError,
    undefined,
    RemovePublicFacilityFavoriteHeaders,
    {},
    RemovePublicFacilityFavoritePathParams
  >({
    url: '/public-facilities/{publicFacilityId}/years/{yearId}/favorites',
    method: 'delete',
    ...variables,
    signal,
  });

export const useRemovePublicFacilityFavorite = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      RemovePublicFacilityFavoriteError,
      RemovePublicFacilityFavoriteVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    RemovePublicFacilityFavoriteError,
    RemovePublicFacilityFavoriteVariables
  >({
    mutationFn: (variables: RemovePublicFacilityFavoriteVariables) =>
      fetchRemovePublicFacilityFavorite({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CreatePriceGroupError = Fetcher.ErrorWrapper<undefined>;

export type CreatePriceGroupVariables = {
  body?: Schemas.PostPriceSheetGroupDTO;
} & DiceContext['fetcherOptions'];

export const fetchCreatePriceGroup = (variables: CreatePriceGroupVariables, signal?: AbortSignal) =>
  diceFetch<
    Schemas.PostPriceSheetGroupDTO,
    CreatePriceGroupError,
    Schemas.PostPriceSheetGroupDTO,
    {},
    {},
    {}
  >({ url: '/price-groups', method: 'post', ...variables, signal });

export const useCreatePriceGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PostPriceSheetGroupDTO,
      CreatePriceGroupError,
      CreatePriceGroupVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.PostPriceSheetGroupDTO,
    CreatePriceGroupError,
    CreatePriceGroupVariables
  >({
    mutationFn: (variables: CreatePriceGroupVariables) =>
      fetchCreatePriceGroup({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdatePriceGroupError = Fetcher.ErrorWrapper<undefined>;

export type UpdatePriceGroupRequestBody = Schemas.PatchPriceSheetGroupDTO[];

export type UpdatePriceGroupVariables = {
  body?: UpdatePriceGroupRequestBody;
} & DiceContext['fetcherOptions'];

export const fetchUpdatePriceGroup = (variables: UpdatePriceGroupVariables, signal?: AbortSignal) =>
  diceFetch<undefined, UpdatePriceGroupError, UpdatePriceGroupRequestBody, {}, {}, {}>({
    url: '/price-groups',
    method: 'patch',
    ...variables,
    signal,
  });

export const useUpdatePriceGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, UpdatePriceGroupError, UpdatePriceGroupVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, UpdatePriceGroupError, UpdatePriceGroupVariables>({
    mutationFn: (variables: UpdatePriceGroupVariables) =>
      fetchUpdatePriceGroup({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface CreatePriceSubGroupPathParams {
  priceGroupId: string;
}

export type CreatePriceSubGroupError = Fetcher.ErrorWrapper<undefined>;

export type CreatePriceSubGroupVariables = {
  body?: Schemas.PostPriceSheetSubGroupDTO;
  pathParams: CreatePriceSubGroupPathParams;
} & DiceContext['fetcherOptions'];

export const fetchCreatePriceSubGroup = (
  variables: CreatePriceSubGroupVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.PostPriceSheetSubGroupDTO,
    CreatePriceSubGroupError,
    Schemas.PostPriceSheetSubGroupDTO,
    {},
    {},
    CreatePriceSubGroupPathParams
  >({ url: '/price-groups/{priceGroupId}/sub-groups', method: 'post', ...variables, signal });

export const useCreatePriceSubGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PostPriceSheetSubGroupDTO,
      CreatePriceSubGroupError,
      CreatePriceSubGroupVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.PostPriceSheetSubGroupDTO,
    CreatePriceSubGroupError,
    CreatePriceSubGroupVariables
  >({
    mutationFn: (variables: CreatePriceSubGroupVariables) =>
      fetchCreatePriceSubGroup({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface CreatePriceSubGroupEntryPathParams {
  priceSubGroupId: string;
}

export type CreatePriceSubGroupEntryError = Fetcher.ErrorWrapper<undefined>;

export type CreatePriceSubGroupEntryVariables = {
  body?: Schemas.PriceSheetSubGroupEntryDTO;
  pathParams: CreatePriceSubGroupEntryPathParams;
} & DiceContext['fetcherOptions'];

export const fetchCreatePriceSubGroupEntry = (
  variables: CreatePriceSubGroupEntryVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.PriceSheetSubGroupEntryDTO,
    CreatePriceSubGroupEntryError,
    Schemas.PriceSheetSubGroupEntryDTO,
    {},
    {},
    CreatePriceSubGroupEntryPathParams
  >({
    url: '/price-groups/sub-groups/{priceSubGroupId}/entries',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreatePriceSubGroupEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PriceSheetSubGroupEntryDTO,
      CreatePriceSubGroupEntryError,
      CreatePriceSubGroupEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.PriceSheetSubGroupEntryDTO,
    CreatePriceSubGroupEntryError,
    CreatePriceSubGroupEntryVariables
  >({
    mutationFn: (variables: CreatePriceSubGroupEntryVariables) =>
      fetchCreatePriceSubGroupEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type AddImputedWithdrawalCapitalIdsError = Fetcher.ErrorWrapper<undefined>;

export type AddImputedWithdrawalCapitalIdsVariables = DiceContext['fetcherOptions'];

export const fetchAddImputedWithdrawalCapitalIds = (
  variables: AddImputedWithdrawalCapitalIdsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<Schemas.MigrationResult, AddImputedWithdrawalCapitalIdsError, undefined, {}, {}, {}>({
    url: '/migration/imputed-withdrawal-capital-id',
    method: 'post',
    ...variables,
    signal,
  });

export const useAddImputedWithdrawalCapitalIds = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MigrationResult,
      AddImputedWithdrawalCapitalIdsError,
      AddImputedWithdrawalCapitalIdsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.MigrationResult,
    AddImputedWithdrawalCapitalIdsError,
    AddImputedWithdrawalCapitalIdsVariables
  >({
    mutationFn: (variables: AddImputedWithdrawalCapitalIdsVariables) =>
      fetchAddImputedWithdrawalCapitalIds({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type AddIndexesError = Fetcher.ErrorWrapper<undefined>;

export type AddIndexesVariables = DiceContext['fetcherOptions'];

export const fetchAddIndexes = (variables: AddIndexesVariables, signal?: AbortSignal) =>
  diceFetch<undefined, AddIndexesError, undefined, {}, {}, {}>({
    url: '/migration/add-indexes',
    method: 'post',
    ...variables,
    signal,
  });

export const useAddIndexes = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, AddIndexesError, AddIndexesVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, AddIndexesError, AddIndexesVariables>({
    mutationFn: (variables: AddIndexesVariables) =>
      fetchAddIndexes({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface SyncMasterConfigurationCostUnitBasicFeePathParams {
  masterConfigurationId: string;
}

export type SyncMasterConfigurationCostUnitBasicFeeError = Fetcher.ErrorWrapper<undefined>;

export type SyncMasterConfigurationCostUnitBasicFeeVariables = {
  body?: Schemas.UpdateCostUnitBasicFee;
  pathParams: SyncMasterConfigurationCostUnitBasicFeePathParams;
} & DiceContext['fetcherOptions'];

export const fetchSyncMasterConfigurationCostUnitBasicFee = (
  variables: SyncMasterConfigurationCostUnitBasicFeeVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    SyncMasterConfigurationCostUnitBasicFeeError,
    Schemas.UpdateCostUnitBasicFee,
    {},
    {},
    SyncMasterConfigurationCostUnitBasicFeePathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/sync-cost-unit',
    method: 'post',
    ...variables,
    signal,
  });

export const useSyncMasterConfigurationCostUnitBasicFee = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      SyncMasterConfigurationCostUnitBasicFeeError,
      SyncMasterConfigurationCostUnitBasicFeeVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    SyncMasterConfigurationCostUnitBasicFeeError,
    SyncMasterConfigurationCostUnitBasicFeeVariables
  >({
    mutationFn: (variables: SyncMasterConfigurationCostUnitBasicFeeVariables) =>
      fetchSyncMasterConfigurationCostUnitBasicFee({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface CreatePlannedCostRevenueDirectEntryPathParams {
  masterConfigurationId: string;
  plannedCostTypeAccountEntryId: string;
  plannedAccountEntryId: string;
  directEntryName: string;
}

export type CreatePlannedCostRevenueDirectEntryError = Fetcher.ErrorWrapper<undefined>;

export type CreatePlannedCostRevenueDirectEntryVariables = {
  pathParams: CreatePlannedCostRevenueDirectEntryPathParams;
} & DiceContext['fetcherOptions'];

export const fetchCreatePlannedCostRevenueDirectEntry = (
  variables: CreatePlannedCostRevenueDirectEntryVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.PlannedDirectEntryDTO,
    CreatePlannedCostRevenueDirectEntryError,
    undefined,
    {},
    {},
    CreatePlannedCostRevenueDirectEntryPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/planned-cost-revenue/{plannedCostTypeAccountEntryId}/account/{plannedAccountEntryId}/direct-entry/{directEntryName}',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreatePlannedCostRevenueDirectEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PlannedDirectEntryDTO,
      CreatePlannedCostRevenueDirectEntryError,
      CreatePlannedCostRevenueDirectEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.PlannedDirectEntryDTO,
    CreatePlannedCostRevenueDirectEntryError,
    CreatePlannedCostRevenueDirectEntryVariables
  >({
    mutationFn: (variables: CreatePlannedCostRevenueDirectEntryVariables) =>
      fetchCreatePlannedCostRevenueDirectEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface CreateVariantConfigurationsPathParams {
  masterConfigurationId: string;
}

export type CreateVariantConfigurationsError = Fetcher.ErrorWrapper<undefined>;

export type CreateVariantConfigurationsVariables = {
  pathParams: CreateVariantConfigurationsPathParams;
} & DiceContext['fetcherOptions'];

export const fetchCreateVariantConfigurations = (
  variables: CreateVariantConfigurationsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.MasterConfigurationEntityDTO,
    CreateVariantConfigurationsError,
    undefined,
    {},
    {},
    CreateVariantConfigurationsPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/create-variant',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreateVariantConfigurations = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MasterConfigurationEntityDTO,
      CreateVariantConfigurationsError,
      CreateVariantConfigurationsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.MasterConfigurationEntityDTO,
    CreateVariantConfigurationsError,
    CreateVariantConfigurationsVariables
  >({
    mutationFn: (variables: CreateVariantConfigurationsVariables) =>
      fetchCreateVariantConfigurations({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface CreateBackupOfMasterConfigurationPathParams {
  masterConfigurationId: string;
}

export type CreateBackupOfMasterConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type CreateBackupOfMasterConfigurationVariables = {
  pathParams: CreateBackupOfMasterConfigurationPathParams;
} & DiceContext['fetcherOptions'];

export const fetchCreateBackupOfMasterConfiguration = (
  variables: CreateBackupOfMasterConfigurationVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.MasterConfigurationEntityDTO,
    CreateBackupOfMasterConfigurationError,
    undefined,
    {},
    {},
    CreateBackupOfMasterConfigurationPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/create-backup',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreateBackupOfMasterConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MasterConfigurationEntityDTO,
      CreateBackupOfMasterConfigurationError,
      CreateBackupOfMasterConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.MasterConfigurationEntityDTO,
    CreateBackupOfMasterConfigurationError,
    CreateBackupOfMasterConfigurationVariables
  >({
    mutationFn: (variables: CreateBackupOfMasterConfigurationVariables) =>
      fetchCreateBackupOfMasterConfiguration({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface CreateCostUnitAllocationBasisConfigPathParams {
  masterConfigurationId: string;
  costUnitAllocationConfigId: string;
  costUnitAllocationBasisConfigName: string;
}

export type CreateCostUnitAllocationBasisConfigError = Fetcher.ErrorWrapper<undefined>;

export type CreateCostUnitAllocationBasisConfigVariables = {
  pathParams: CreateCostUnitAllocationBasisConfigPathParams;
} & DiceContext['fetcherOptions'];

export const fetchCreateCostUnitAllocationBasisConfig = (
  variables: CreateCostUnitAllocationBasisConfigVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.CostUnitAllocationBasisConfigEntryDTO,
    CreateCostUnitAllocationBasisConfigError,
    undefined,
    {},
    {},
    CreateCostUnitAllocationBasisConfigPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/cost-unit-allocations/{costUnitAllocationConfigId}/cost-unit-allocation-basis-configs/{costUnitAllocationBasisConfigName}',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreateCostUnitAllocationBasisConfig = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.CostUnitAllocationBasisConfigEntryDTO,
      CreateCostUnitAllocationBasisConfigError,
      CreateCostUnitAllocationBasisConfigVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.CostUnitAllocationBasisConfigEntryDTO,
    CreateCostUnitAllocationBasisConfigError,
    CreateCostUnitAllocationBasisConfigVariables
  >({
    mutationFn: (variables: CreateCostUnitAllocationBasisConfigVariables) =>
      fetchCreateCostUnitAllocationBasisConfig({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface CopyConfigPathParams {
  masterConfigurationId: string;
  targetYearCalculationId: string;
}

export type CopyConfigError = Fetcher.ErrorWrapper<undefined>;

export type CopyConfigVariables = {
  pathParams: CopyConfigPathParams;
} & DiceContext['fetcherOptions'];

export const fetchCopyConfig = (variables: CopyConfigVariables, signal?: AbortSignal) =>
  diceFetch<
    Schemas.MasterConfigurationEntityDTO,
    CopyConfigError,
    undefined,
    {},
    {},
    CopyConfigPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/copy/year-calculations/{targetYearCalculationId}/master-configurations',
    method: 'post',
    ...variables,
    signal,
  });

export const useCopyConfig = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MasterConfigurationEntityDTO,
      CopyConfigError,
      CopyConfigVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.MasterConfigurationEntityDTO,
    CopyConfigError,
    CopyConfigVariables
  >({
    mutationFn: (variables: CopyConfigVariables) =>
      fetchCopyConfig({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetRangeConfigForMasterConfigurationPathParams {
  masterConfigurationId: string;
}

export type GetRangeConfigForMasterConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type GetRangeConfigForMasterConfigurationVariables = {
  pathParams: GetRangeConfigForMasterConfigurationPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetRangeConfigForMasterConfiguration = (
  variables: GetRangeConfigForMasterConfigurationVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.RangeConfigDTO,
    GetRangeConfigForMasterConfigurationError,
    undefined,
    {},
    {},
    GetRangeConfigForMasterConfigurationPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/account-ranges',
    method: 'get',
    ...variables,
    signal,
  });

export const getRangeConfigForMasterConfigurationQuery = (
  variables: GetRangeConfigForMasterConfigurationVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<Schemas.RangeConfigDTO>;
} => ({
  queryKey: queryKeyFn({
    path: '/master-configurations/{masterConfigurationId}/account-ranges',
    operationId: 'getRangeConfigForMasterConfiguration',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetRangeConfigForMasterConfiguration({ ...variables }, signal),
});

export const useSuspenseGetRangeConfigForMasterConfiguration = <TData = Schemas.RangeConfigDTO>(
  variables: GetRangeConfigForMasterConfigurationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.RangeConfigDTO,
      GetRangeConfigForMasterConfigurationError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    Schemas.RangeConfigDTO,
    GetRangeConfigForMasterConfigurationError,
    TData
  >({
    ...getRangeConfigForMasterConfigurationQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetRangeConfigForMasterConfiguration = <TData = Schemas.RangeConfigDTO>(
  variables: GetRangeConfigForMasterConfigurationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.RangeConfigDTO,
      GetRangeConfigForMasterConfigurationError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    Schemas.RangeConfigDTO,
    GetRangeConfigForMasterConfigurationError,
    TData
  >({
    ...getRangeConfigForMasterConfigurationQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface PostRangePathParams {
  masterConfigurationId: string;
}

export type PostRangeError = Fetcher.ErrorWrapper<undefined>;

export type PostRangeVariables = {
  body?: Schemas.RangeDTO;
  pathParams: PostRangePathParams;
} & DiceContext['fetcherOptions'];

export const fetchPostRange = (variables: PostRangeVariables, signal?: AbortSignal) =>
  diceFetch<Schemas.RangeConfigDTO, PostRangeError, Schemas.RangeDTO, {}, {}, PostRangePathParams>({
    url: '/master-configurations/{masterConfigurationId}/account-ranges',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostRange = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.RangeConfigDTO, PostRangeError, PostRangeVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<Schemas.RangeConfigDTO, PostRangeError, PostRangeVariables>({
    mutationFn: (variables: PostRangeVariables) =>
      fetchPostRange({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SyncMasterConfigurationCostUnitBasicFee1Error = Fetcher.ErrorWrapper<undefined>;

export type SyncMasterConfigurationCostUnitBasicFee1Variables = {
  body?: Schemas.UpdateCostUnitBasicFee;
} & DiceContext['fetcherOptions'];

export const fetchSyncMasterConfigurationCostUnitBasicFee1 = (
  variables: SyncMasterConfigurationCostUnitBasicFee1Variables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    SyncMasterConfigurationCostUnitBasicFee1Error,
    Schemas.UpdateCostUnitBasicFee,
    {},
    {},
    {}
  >({ url: '/master-configuration/cost-unit', method: 'post', ...variables, signal });

export const useSyncMasterConfigurationCostUnitBasicFee1 = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      SyncMasterConfigurationCostUnitBasicFee1Error,
      SyncMasterConfigurationCostUnitBasicFee1Variables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    SyncMasterConfigurationCostUnitBasicFee1Error,
    SyncMasterConfigurationCostUnitBasicFee1Variables
  >({
    mutationFn: (variables: SyncMasterConfigurationCostUnitBasicFee1Variables) =>
      fetchSyncMasterConfigurationCostUnitBasicFee1({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface CopyConfig1PathParams {
  masterConfigId: string;
  targetMasterConfigId: string;
}

export type CopyConfig1Error = Fetcher.ErrorWrapper<undefined>;

export type CopyConfig1Variables = {
  pathParams: CopyConfig1PathParams;
} & DiceContext['fetcherOptions'];

export const fetchCopyConfig1 = (variables: CopyConfig1Variables, signal?: AbortSignal) =>
  diceFetch<undefined, CopyConfig1Error, undefined, {}, {}, CopyConfig1PathParams>({
    url: '/master-configs/{masterConfigId}/copy/master-config/{targetMasterConfigId}',
    method: 'post',
    ...variables,
    signal,
  });

export const useCopyConfig1 = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, CopyConfig1Error, CopyConfig1Variables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, CopyConfig1Error, CopyConfig1Variables>({
    mutationFn: (variables: CopyConfig1Variables) =>
      fetchCopyConfig1({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface CreateAllocationBasisConfigPathParams {
  allocationKeyConfigId: string;
  allocationBasisConfigName: string;
}

export type CreateAllocationBasisConfigError = Fetcher.ErrorWrapper<undefined>;

export type CreateAllocationBasisConfigVariables = {
  pathParams: CreateAllocationBasisConfigPathParams;
} & DiceContext['fetcherOptions'];

export const fetchCreateAllocationBasisConfig = (
  variables: CreateAllocationBasisConfigVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.AllocationBasisConfigEntryDTO,
    CreateAllocationBasisConfigError,
    undefined,
    {},
    {},
    CreateAllocationBasisConfigPathParams
  >({
    url: '/master-configs/allocation-key-configs/{allocationKeyConfigId}/allocation-basis-configs/{allocationBasisConfigName}',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreateAllocationBasisConfig = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AllocationBasisConfigEntryDTO,
      CreateAllocationBasisConfigError,
      CreateAllocationBasisConfigVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.AllocationBasisConfigEntryDTO,
    CreateAllocationBasisConfigError,
    CreateAllocationBasisConfigVariables
  >({
    mutationFn: (variables: CreateAllocationBasisConfigVariables) =>
      fetchCreateAllocationBasisConfig({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetCostCenterChildrenLedgerError = Fetcher.ErrorWrapper<undefined>;

export type GetCostCenterChildrenLedgerResponse = Schemas.CostCenterNode[];

export type GetCostCenterChildrenLedgerVariables = {
  body?: Schemas.CostCenterTreeRequest;
} & DiceContext['fetcherOptions'];

export const fetchGetCostCenterChildrenLedger = (
  variables: GetCostCenterChildrenLedgerVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetCostCenterChildrenLedgerResponse,
    GetCostCenterChildrenLedgerError,
    Schemas.CostCenterTreeRequest,
    {},
    {},
    {}
  >({ url: '/ledger-costCenter', method: 'post', ...variables, signal });

export const useGetCostCenterChildrenLedger = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      GetCostCenterChildrenLedgerResponse,
      GetCostCenterChildrenLedgerError,
      GetCostCenterChildrenLedgerVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    GetCostCenterChildrenLedgerResponse,
    GetCostCenterChildrenLedgerError,
    GetCostCenterChildrenLedgerVariables
  >({
    mutationFn: (variables: GetCostCenterChildrenLedgerVariables) =>
      fetchGetCostCenterChildrenLedger({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetCostCenterChildrenJournalError = Fetcher.ErrorWrapper<undefined>;

export type GetCostCenterChildrenJournalResponse = Schemas.CostCenterNode[];

export type GetCostCenterChildrenJournalVariables = {
  body?: Schemas.CostCenterTreeRequest;
} & DiceContext['fetcherOptions'];

export const fetchGetCostCenterChildrenJournal = (
  variables: GetCostCenterChildrenJournalVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetCostCenterChildrenJournalResponse,
    GetCostCenterChildrenJournalError,
    Schemas.CostCenterTreeRequest,
    {},
    {},
    {}
  >({ url: '/journal-costCenter', method: 'post', ...variables, signal });

export const useGetCostCenterChildrenJournal = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      GetCostCenterChildrenJournalResponse,
      GetCostCenterChildrenJournalError,
      GetCostCenterChildrenJournalVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    GetCostCenterChildrenJournalResponse,
    GetCostCenterChildrenJournalError,
    GetCostCenterChildrenJournalVariables
  >({
    mutationFn: (variables: GetCostCenterChildrenJournalVariables) =>
      fetchGetCostCenterChildrenJournal({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetAllIndexFactorsError = Fetcher.ErrorWrapper<undefined>;

export type GetAllIndexFactorsResponse = Schemas.IndexFactorConfig[];

export type GetAllIndexFactorsVariables = DiceContext['fetcherOptions'];

export const fetchGetAllIndexFactors = (
  variables: GetAllIndexFactorsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<GetAllIndexFactorsResponse, GetAllIndexFactorsError, undefined, {}, {}, {}>({
    url: '/index-factors',
    method: 'get',
    ...variables,
    signal,
  });

export const getAllIndexFactorsQuery = (
  variables: GetAllIndexFactorsVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<GetAllIndexFactorsResponse>;
} => ({
  queryKey: queryKeyFn({
    path: '/index-factors',
    operationId: 'getAllIndexFactors',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetAllIndexFactors({ ...variables }, signal),
});

export const useSuspenseGetAllIndexFactors = <TData = GetAllIndexFactorsResponse>(
  variables: GetAllIndexFactorsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetAllIndexFactorsResponse, GetAllIndexFactorsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<GetAllIndexFactorsResponse, GetAllIndexFactorsError, TData>({
    ...getAllIndexFactorsQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetAllIndexFactors = <TData = GetAllIndexFactorsResponse>(
  variables: GetAllIndexFactorsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetAllIndexFactorsResponse, GetAllIndexFactorsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<GetAllIndexFactorsResponse, GetAllIndexFactorsError, TData>({
    ...getAllIndexFactorsQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export type PostIndexFactorsError = Fetcher.ErrorWrapper<undefined>;

export type PostIndexFactorsVariables = {
  body?: Schemas.IndexFactorConfig;
} & DiceContext['fetcherOptions'];

export const fetchPostIndexFactors = (variables: PostIndexFactorsVariables, signal?: AbortSignal) =>
  diceFetch<
    Schemas.IndexFactorConfig,
    PostIndexFactorsError,
    Schemas.IndexFactorConfig,
    {},
    {},
    {}
  >({ url: '/index-factors', method: 'post', ...variables, signal });

export const usePostIndexFactors = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.IndexFactorConfig,
      PostIndexFactorsError,
      PostIndexFactorsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.IndexFactorConfig,
    PostIndexFactorsError,
    PostIndexFactorsVariables
  >({
    mutationFn: (variables: PostIndexFactorsVariables) =>
      fetchPostIndexFactors({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteIndexFactorsError = Fetcher.ErrorWrapper<undefined>;

export type DeleteIndexFactorsVariables = DiceContext['fetcherOptions'];

export const fetchDeleteIndexFactors = (
  variables: DeleteIndexFactorsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<undefined, DeleteIndexFactorsError, undefined, {}, {}, {}>({
    url: '/index-factors',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteIndexFactors = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteIndexFactorsError, DeleteIndexFactorsVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, DeleteIndexFactorsError, DeleteIndexFactorsVariables>({
    mutationFn: (variables: DeleteIndexFactorsVariables) =>
      fetchDeleteIndexFactors({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface SaveToggleFilterDataQueryParams {
  clientId: string;
  /**
   * @minimum 1900
   * @format int32
   */
  calculationYear: number;
  yearId: string;
  isPostCalculation: boolean;
  erpType: 'SAP_ECC6' | 'SAP_S4' | 'NAVISION_365_BC' | 'KVASY';
  fileType: 'ASSETS' | 'LABELS' | 'LEDGER_ACCOUNT' | 'JOURNAL' | 'ACCOUNT_PLAN';
  toggleName: string;
  toggleValue: boolean;
}

export type SaveToggleFilterDataError = Fetcher.ErrorWrapper<undefined>;

export type SaveToggleFilterDataVariables = {
  queryParams: SaveToggleFilterDataQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchSaveToggleFilterData = (
  variables: SaveToggleFilterDataVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.ImportFileResponse,
    SaveToggleFilterDataError,
    undefined,
    {},
    SaveToggleFilterDataQueryParams,
    {}
  >({ url: '/imports/toggleFilterData', method: 'post', ...variables, signal });

export const useSaveToggleFilterData = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ImportFileResponse,
      SaveToggleFilterDataError,
      SaveToggleFilterDataVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.ImportFileResponse,
    SaveToggleFilterDataError,
    SaveToggleFilterDataVariables
  >({
    mutationFn: (variables: SaveToggleFilterDataVariables) =>
      fetchSaveToggleFilterData({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ImportLedgerAccountError = Fetcher.ErrorWrapper<undefined>;

export type ImportLedgerAccountVariables = {
  body: Schemas.ImportLedgerAccountRequest;
} & DiceContext['fetcherOptions'];

export const fetchImportLedgerAccount = (
  variables: ImportLedgerAccountVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.ImportFileResponse,
    ImportLedgerAccountError,
    Schemas.ImportLedgerAccountRequest,
    {},
    {},
    {}
  >({ url: '/imports/ledger-account', method: 'post', ...variables, signal });

export const useImportLedgerAccount = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ImportFileResponse,
      ImportLedgerAccountError,
      ImportLedgerAccountVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.ImportFileResponse,
    ImportLedgerAccountError,
    ImportLedgerAccountVariables
  >({
    mutationFn: (variables: ImportLedgerAccountVariables) =>
      fetchImportLedgerAccount({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ImportLabelsError = Fetcher.ErrorWrapper<undefined>;

export type ImportLabelsVariables = {
  body: Schemas.ImportRequest;
} & DiceContext['fetcherOptions'];

export const fetchImportLabels = (variables: ImportLabelsVariables, signal?: AbortSignal) =>
  diceFetch<Schemas.ImportFileResponse, ImportLabelsError, Schemas.ImportRequest, {}, {}, {}>({
    url: '/imports/labels',
    method: 'post',
    ...variables,
    signal,
  });

export const useImportLabels = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ImportFileResponse,
      ImportLabelsError,
      ImportLabelsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.ImportFileResponse,
    ImportLabelsError,
    ImportLabelsVariables
  >({
    mutationFn: (variables: ImportLabelsVariables) =>
      fetchImportLabels({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ImportJournalError = Fetcher.ErrorWrapper<undefined>;

export type ImportJournalVariables = {
  body: Schemas.ImportJournalRequest;
} & DiceContext['fetcherOptions'];

export const fetchImportJournal = (variables: ImportJournalVariables, signal?: AbortSignal) =>
  diceFetch<
    Schemas.ImportFileResponse,
    ImportJournalError,
    Schemas.ImportJournalRequest,
    {},
    {},
    {}
  >({ url: '/imports/journals', method: 'post', ...variables, signal });

export const useImportJournal = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ImportFileResponse,
      ImportJournalError,
      ImportJournalVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.ImportFileResponse,
    ImportJournalError,
    ImportJournalVariables
  >({
    mutationFn: (variables: ImportJournalVariables) =>
      fetchImportJournal({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SaveFilterDataError = Fetcher.ErrorWrapper<undefined>;

export type SaveFilterDataVariables = {
  body: Schemas.ImportSaveFilterDataTO;
} & DiceContext['fetcherOptions'];

export const fetchSaveFilterData = (variables: SaveFilterDataVariables, signal?: AbortSignal) =>
  diceFetch<
    Schemas.ImportFileResponse,
    SaveFilterDataError,
    Schemas.ImportSaveFilterDataTO,
    {},
    {},
    {}
  >({ url: '/imports/filter-data', method: 'post', ...variables, signal });

export const useSaveFilterData = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ImportFileResponse,
      SaveFilterDataError,
      SaveFilterDataVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.ImportFileResponse,
    SaveFilterDataError,
    SaveFilterDataVariables
  >({
    mutationFn: (variables: SaveFilterDataVariables) =>
      fetchSaveFilterData({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ImportAssetsError = Fetcher.ErrorWrapper<undefined>;

export type ImportAssetsVariables = {
  body: Schemas.ImportAssetRequest;
} & DiceContext['fetcherOptions'];

export const fetchImportAssets = (variables: ImportAssetsVariables, signal?: AbortSignal) =>
  diceFetch<Schemas.ImportFileResponse, ImportAssetsError, Schemas.ImportAssetRequest, {}, {}, {}>({
    url: '/imports/assets',
    method: 'post',
    ...variables,
    signal,
  });

export const useImportAssets = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ImportFileResponse,
      ImportAssetsError,
      ImportAssetsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.ImportFileResponse,
    ImportAssetsError,
    ImportAssetsVariables
  >({
    mutationFn: (variables: ImportAssetsVariables) =>
      fetchImportAssets({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ImportAccountPlanError = Fetcher.ErrorWrapper<undefined>;

export type ImportAccountPlanVariables = {
  body: Schemas.ImportRequest;
} & DiceContext['fetcherOptions'];

export const fetchImportAccountPlan = (
  variables: ImportAccountPlanVariables,
  signal?: AbortSignal,
) =>
  diceFetch<Schemas.ImportFileResponse, ImportAccountPlanError, Schemas.ImportRequest, {}, {}, {}>({
    url: '/imports/account-plans',
    method: 'post',
    ...variables,
    signal,
  });

export const useImportAccountPlan = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ImportFileResponse,
      ImportAccountPlanError,
      ImportAccountPlanVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.ImportFileResponse,
    ImportAccountPlanError,
    ImportAccountPlanVariables
  >({
    mutationFn: (variables: ImportAccountPlanVariables) =>
      fetchImportAccountPlan({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CreateBasicFeeGroupError = Fetcher.ErrorWrapper<undefined>;

export type CreateBasicFeeGroupVariables = {
  body?: Schemas.CostUnitBasicFeeGroupDTO;
} & DiceContext['fetcherOptions'];

export const fetchCreateBasicFeeGroup = (
  variables: CreateBasicFeeGroupVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.CostUnitBasicFeeGroupDTO,
    CreateBasicFeeGroupError,
    Schemas.CostUnitBasicFeeGroupDTO,
    {},
    {},
    {}
  >({ url: '/cost-unit/basic-fee/groups', method: 'post', ...variables, signal });

export const useCreateBasicFeeGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.CostUnitBasicFeeGroupDTO,
      CreateBasicFeeGroupError,
      CreateBasicFeeGroupVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.CostUnitBasicFeeGroupDTO,
    CreateBasicFeeGroupError,
    CreateBasicFeeGroupVariables
  >({
    mutationFn: (variables: CreateBasicFeeGroupVariables) =>
      fetchCreateBasicFeeGroup({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateBasicFeeGroupsError = Fetcher.ErrorWrapper<undefined>;

export type UpdateBasicFeeGroupsRequestBody = Schemas.CostUnitBasicFeeGroupDTO[];

export type UpdateBasicFeeGroupsVariables = {
  body?: UpdateBasicFeeGroupsRequestBody;
} & DiceContext['fetcherOptions'];

export const fetchUpdateBasicFeeGroups = (
  variables: UpdateBasicFeeGroupsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<undefined, UpdateBasicFeeGroupsError, UpdateBasicFeeGroupsRequestBody, {}, {}, {}>({
    url: '/cost-unit/basic-fee/groups',
    method: 'patch',
    ...variables,
    signal,
  });

export const useUpdateBasicFeeGroups = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdateBasicFeeGroupsError,
      UpdateBasicFeeGroupsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    UpdateBasicFeeGroupsError,
    UpdateBasicFeeGroupsVariables
  >({
    mutationFn: (variables: UpdateBasicFeeGroupsVariables) =>
      fetchUpdateBasicFeeGroups({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface CreateBasicFeeGroupEntryPathParams {
  groupId: string;
}

export type CreateBasicFeeGroupEntryError = Fetcher.ErrorWrapper<undefined>;

export type CreateBasicFeeGroupEntryVariables = {
  body?: Schemas.CostUnitBasicFeeEntryDTO;
  pathParams: CreateBasicFeeGroupEntryPathParams;
} & DiceContext['fetcherOptions'];

export const fetchCreateBasicFeeGroupEntry = (
  variables: CreateBasicFeeGroupEntryVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.CostUnitBasicFeeEntryDTO,
    CreateBasicFeeGroupEntryError,
    Schemas.CostUnitBasicFeeEntryDTO,
    {},
    {},
    CreateBasicFeeGroupEntryPathParams
  >({ url: '/cost-unit/basic-fee/groups/{groupId}/entries', method: 'post', ...variables, signal });

export const useCreateBasicFeeGroupEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.CostUnitBasicFeeEntryDTO,
      CreateBasicFeeGroupEntryError,
      CreateBasicFeeGroupEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.CostUnitBasicFeeEntryDTO,
    CreateBasicFeeGroupEntryError,
    CreateBasicFeeGroupEntryVariables
  >({
    mutationFn: (variables: CreateBasicFeeGroupEntryVariables) =>
      fetchCreateBasicFeeGroupEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetAssetExtendedInfoQueryParams {
  configRequest: Schemas.ConfigRequest;
  ['config-type']: string;
}

export type GetAssetExtendedInfoError = Fetcher.ErrorWrapper<undefined>;

export type GetAssetExtendedInfoVariables = {
  queryParams: GetAssetExtendedInfoQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchGetAssetExtendedInfo = (
  variables: GetAssetExtendedInfoVariables,
  signal?: AbortSignal,
) =>
  diceFetch<string, GetAssetExtendedInfoError, undefined, {}, GetAssetExtendedInfoQueryParams, {}>({
    url: '/config',
    method: 'get',
    ...variables,
    signal,
  });

export const getAssetExtendedInfoQuery = (
  variables: GetAssetExtendedInfoVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<string>;
} => ({
  queryKey: queryKeyFn({
    path: '/config',
    operationId: 'getAssetExtendedInfo',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetAssetExtendedInfo({ ...variables }, signal),
});

export const useSuspenseGetAssetExtendedInfo = <TData = string>(
  variables: GetAssetExtendedInfoVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<string, GetAssetExtendedInfoError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<string, GetAssetExtendedInfoError, TData>({
    ...getAssetExtendedInfoQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetAssetExtendedInfo = <TData = string>(
  variables: GetAssetExtendedInfoVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<string, GetAssetExtendedInfoError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<string, GetAssetExtendedInfoError, TData>({
    ...getAssetExtendedInfoQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface ProcessConfigQueryParams {
  ['config-type']: string;
}

export type ProcessConfigError = Fetcher.ErrorWrapper<undefined>;

export type ProcessConfigVariables = {
  body: Schemas.ConfigRequest;
  queryParams: ProcessConfigQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchProcessConfig = (variables: ProcessConfigVariables, signal?: AbortSignal) =>
  diceFetch<string, ProcessConfigError, Schemas.ConfigRequest, {}, ProcessConfigQueryParams, {}>({
    url: '/config',
    method: 'post',
    ...variables,
    signal,
  });

export const useProcessConfig = (
  options?: Omit<
    reactQuery.UseMutationOptions<string, ProcessConfigError, ProcessConfigVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<string, ProcessConfigError, ProcessConfigVariables>({
    mutationFn: (variables: ProcessConfigVariables) =>
      fetchProcessConfig({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetClientsQueryParams {
  onlyArchived?: boolean;
}

export interface GetClientsHeaders {
  authorization?: string;
}

export type GetClientsError = Fetcher.ErrorWrapper<undefined>;

export type GetClientsResponse = Schemas.ClientDTO[];

export type GetClientsVariables = {
  headers?: GetClientsHeaders;
  queryParams?: GetClientsQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchGetClients = (variables: GetClientsVariables, signal?: AbortSignal) =>
  diceFetch<
    GetClientsResponse,
    GetClientsError,
    undefined,
    GetClientsHeaders,
    GetClientsQueryParams,
    {}
  >({ url: '/clients', method: 'get', ...variables, signal });

export const getClientsQuery = (
  variables: GetClientsVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<GetClientsResponse>;
} => ({
  queryKey: queryKeyFn({
    path: '/clients',
    operationId: 'getClients',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) => fetchGetClients({ ...variables }, signal),
});

export const useSuspenseGetClients = <TData = GetClientsResponse>(
  variables: GetClientsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetClientsResponse, GetClientsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<GetClientsResponse, GetClientsError, TData>({
    ...getClientsQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetClients = <TData = GetClientsResponse>(
  variables: GetClientsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetClientsResponse, GetClientsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<GetClientsResponse, GetClientsError, TData>({
    ...getClientsQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface SaveClientsHeaders {
  authorization?: string;
}

export type SaveClientsError = Fetcher.ErrorWrapper<undefined>;

export type SaveClientsResponse = Schemas.ClientDTO[];

export type SaveClientsRequestBody = Schemas.ClientDTO[];

export type SaveClientsVariables = {
  body?: SaveClientsRequestBody;
  headers?: SaveClientsHeaders;
} & DiceContext['fetcherOptions'];

export const fetchSaveClients = (variables: SaveClientsVariables, signal?: AbortSignal) =>
  diceFetch<
    SaveClientsResponse,
    SaveClientsError,
    SaveClientsRequestBody,
    SaveClientsHeaders,
    {},
    {}
  >({ url: '/clients', method: 'post', ...variables, signal });

export const useSaveClients = (
  options?: Omit<
    reactQuery.UseMutationOptions<SaveClientsResponse, SaveClientsError, SaveClientsVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<SaveClientsResponse, SaveClientsError, SaveClientsVariables>({
    mutationFn: (variables: SaveClientsVariables) =>
      fetchSaveClients({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface DeleteClientQueryParams {
  /**
   * @format int32
   */
  clientId: number;
}

export interface DeleteClientHeaders {
  authorization?: string;
}

export type DeleteClientError = Fetcher.ErrorWrapper<undefined>;

export type DeleteClientVariables = {
  headers?: DeleteClientHeaders;
  queryParams: DeleteClientQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchDeleteClient = (variables: DeleteClientVariables, signal?: AbortSignal) =>
  diceFetch<
    undefined,
    DeleteClientError,
    undefined,
    DeleteClientHeaders,
    DeleteClientQueryParams,
    {}
  >({ url: '/clients', method: 'delete', ...variables, signal });

export const useDeleteClient = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteClientError, DeleteClientVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, DeleteClientError, DeleteClientVariables>({
    mutationFn: (variables: DeleteClientVariables) =>
      fetchDeleteClient({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface AddPublicFacilityFavorite1PathParams {
  clientId: string;
  publicFacilityId: string;
}

export interface AddPublicFacilityFavorite1Headers {
  authorization?: string;
}

export type AddPublicFacilityFavorite1Error = Fetcher.ErrorWrapper<undefined>;

export type AddPublicFacilityFavorite1Variables = {
  headers?: AddPublicFacilityFavorite1Headers;
  pathParams: AddPublicFacilityFavorite1PathParams;
} & DiceContext['fetcherOptions'];

export const fetchAddPublicFacilityFavorite1 = (
  variables: AddPublicFacilityFavorite1Variables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    AddPublicFacilityFavorite1Error,
    undefined,
    AddPublicFacilityFavorite1Headers,
    {},
    AddPublicFacilityFavorite1PathParams
  >({
    url: '/clients/{clientId}/public-facilities/{publicFacilityId}/favorites',
    method: 'post',
    ...variables,
    signal,
  });

export const useAddPublicFacilityFavorite1 = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      AddPublicFacilityFavorite1Error,
      AddPublicFacilityFavorite1Variables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    AddPublicFacilityFavorite1Error,
    AddPublicFacilityFavorite1Variables
  >({
    mutationFn: (variables: AddPublicFacilityFavorite1Variables) =>
      fetchAddPublicFacilityFavorite1({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface RemovePublicFacilityFavorite1PathParams {
  clientId: string;
  publicFacilityId: string;
}

export interface RemovePublicFacilityFavorite1Headers {
  authorization?: string;
}

export type RemovePublicFacilityFavorite1Error = Fetcher.ErrorWrapper<undefined>;

export type RemovePublicFacilityFavorite1Variables = {
  headers?: RemovePublicFacilityFavorite1Headers;
  pathParams: RemovePublicFacilityFavorite1PathParams;
} & DiceContext['fetcherOptions'];

export const fetchRemovePublicFacilityFavorite1 = (
  variables: RemovePublicFacilityFavorite1Variables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    RemovePublicFacilityFavorite1Error,
    undefined,
    RemovePublicFacilityFavorite1Headers,
    {},
    RemovePublicFacilityFavorite1PathParams
  >({
    url: '/clients/{clientId}/public-facilities/{publicFacilityId}/favorites',
    method: 'delete',
    ...variables,
    signal,
  });

export const useRemovePublicFacilityFavorite1 = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      RemovePublicFacilityFavorite1Error,
      RemovePublicFacilityFavorite1Variables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    RemovePublicFacilityFavorite1Error,
    RemovePublicFacilityFavorite1Variables
  >({
    mutationFn: (variables: RemovePublicFacilityFavorite1Variables) =>
      fetchRemovePublicFacilityFavorite1({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface ShareClientQueryParams {
  /**
   * @format int32
   */
  clientId: number;
}

export interface ShareClientHeaders {
  authorization?: string;
}

export type ShareClientError = Fetcher.ErrorWrapper<undefined>;

export type ShareClientRequestBody = string[];

export type ShareClientVariables = {
  body?: ShareClientRequestBody;
  headers?: ShareClientHeaders;
  queryParams: ShareClientQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchShareClient = (variables: ShareClientVariables, signal?: AbortSignal) =>
  diceFetch<
    undefined,
    ShareClientError,
    ShareClientRequestBody,
    ShareClientHeaders,
    ShareClientQueryParams,
    {}
  >({ url: '/clients/share', method: 'post', ...variables, signal });

export const useShareClient = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ShareClientError, ShareClientVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, ShareClientError, ShareClientVariables>({
    mutationFn: (variables: ShareClientVariables) =>
      fetchShareClient({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface CreateBabCostCenterPathParams {
  yearId: string;
  masterConfigId: string;
  creationType: 'PROFIT_CENTER' | 'COST_CENTER' | 'JOB_ID';
}

export type CreateBabCostCenterError = Fetcher.ErrorWrapper<undefined>;

export type CreateBabCostCenterVariables = {
  pathParams: CreateBabCostCenterPathParams;
} & DiceContext['fetcherOptions'];

export const fetchCreateBabCostCenter = (
  variables: CreateBabCostCenterVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.BabCostCenterCreationResponse,
    CreateBabCostCenterError,
    undefined,
    {},
    {},
    CreateBabCostCenterPathParams
  >({
    url: '/bab-cost-centers/{yearId}/{masterConfigId}/{creationType}',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreateBabCostCenter = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.BabCostCenterCreationResponse,
      CreateBabCostCenterError,
      CreateBabCostCenterVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.BabCostCenterCreationResponse,
    CreateBabCostCenterError,
    CreateBabCostCenterVariables
  >({
    mutationFn: (variables: CreateBabCostCenterVariables) =>
      fetchCreateBabCostCenter({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetCostCenterChildrenAssetError = Fetcher.ErrorWrapper<undefined>;

export type GetCostCenterChildrenAssetResponse = Schemas.CostCenterNode[];

export type GetCostCenterChildrenAssetVariables = {
  body?: Schemas.CostCenterTreeRequest;
} & DiceContext['fetcherOptions'];

export const fetchGetCostCenterChildrenAsset = (
  variables: GetCostCenterChildrenAssetVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetCostCenterChildrenAssetResponse,
    GetCostCenterChildrenAssetError,
    Schemas.CostCenterTreeRequest,
    {},
    {},
    {}
  >({ url: '/asset-costCenter', method: 'post', ...variables, signal });

export const useGetCostCenterChildrenAsset = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      GetCostCenterChildrenAssetResponse,
      GetCostCenterChildrenAssetError,
      GetCostCenterChildrenAssetVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    GetCostCenterChildrenAssetResponse,
    GetCostCenterChildrenAssetError,
    GetCostCenterChildrenAssetVariables
  >({
    mutationFn: (variables: GetCostCenterChildrenAssetVariables) =>
      fetchGetCostCenterChildrenAsset({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type MapLabelsToAssetsByYearIdError = Fetcher.ErrorWrapper<undefined>;

export type MapLabelsToAssetsByYearIdVariables = {
  body?: Schemas.AssetConfigMappingRequest;
} & DiceContext['fetcherOptions'];

export const fetchMapLabelsToAssetsByYearId = (
  variables: MapLabelsToAssetsByYearIdVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.AssetConfigMappingResponseObject,
    MapLabelsToAssetsByYearIdError,
    Schemas.AssetConfigMappingRequest,
    {},
    {},
    {}
  >({ url: '/asset-cc-config/labels', method: 'post', ...variables, signal });

export const useMapLabelsToAssetsByYearId = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AssetConfigMappingResponseObject,
      MapLabelsToAssetsByYearIdError,
      MapLabelsToAssetsByYearIdVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.AssetConfigMappingResponseObject,
    MapLabelsToAssetsByYearIdError,
    MapLabelsToAssetsByYearIdVariables
  >({
    mutationFn: (variables: MapLabelsToAssetsByYearIdVariables) =>
      fetchMapLabelsToAssetsByYearId({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type MapOnlyBabCcToAssetsError = Fetcher.ErrorWrapper<undefined>;

export type MapOnlyBabCcToAssetsVariables = {
  body?: Schemas.AssetConfigMappingRequest;
} & DiceContext['fetcherOptions'];

export const fetchMapOnlyBabCcToAssets = (
  variables: MapOnlyBabCcToAssetsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.AssetConfigMappingResponseObject,
    MapOnlyBabCcToAssetsError,
    Schemas.AssetConfigMappingRequest,
    {},
    {},
    {}
  >({ url: '/asset-cc-config/bab-cc', method: 'post', ...variables, signal });

export const useMapOnlyBabCcToAssets = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AssetConfigMappingResponseObject,
      MapOnlyBabCcToAssetsError,
      MapOnlyBabCcToAssetsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    Schemas.AssetConfigMappingResponseObject,
    MapOnlyBabCcToAssetsError,
    MapOnlyBabCcToAssetsVariables
  >({
    mutationFn: (variables: MapOnlyBabCcToAssetsVariables) =>
      fetchMapOnlyBabCcToAssets({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdatePriceSubGroupError = Fetcher.ErrorWrapper<undefined>;

export type UpdatePriceSubGroupRequestBody = Schemas.PatchPriceSheetSubGroupDTO[];

export type UpdatePriceSubGroupVariables = {
  body?: UpdatePriceSubGroupRequestBody;
} & DiceContext['fetcherOptions'];

export const fetchUpdatePriceSubGroup = (
  variables: UpdatePriceSubGroupVariables,
  signal?: AbortSignal,
) =>
  diceFetch<undefined, UpdatePriceSubGroupError, UpdatePriceSubGroupRequestBody, {}, {}, {}>({
    url: '/price-groups/sub-groups',
    method: 'patch',
    ...variables,
    signal,
  });

export const useUpdatePriceSubGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdatePriceSubGroupError,
      UpdatePriceSubGroupVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, UpdatePriceSubGroupError, UpdatePriceSubGroupVariables>({
    mutationFn: (variables: UpdatePriceSubGroupVariables) =>
      fetchUpdatePriceSubGroup({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type MovePriceSubGroupError = Fetcher.ErrorWrapper<undefined>;

export type MovePriceSubGroupVariables = {
  body?: Schemas.MovePriceSheetSubgroupDTO;
} & DiceContext['fetcherOptions'];

export const fetchMovePriceSubGroup = (
  variables: MovePriceSubGroupVariables,
  signal?: AbortSignal,
) =>
  diceFetch<undefined, MovePriceSubGroupError, Schemas.MovePriceSheetSubgroupDTO, {}, {}, {}>({
    url: '/price-groups/sub-groups/move',
    method: 'patch',
    ...variables,
    signal,
  });

export const useMovePriceSubGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, MovePriceSubGroupError, MovePriceSubGroupVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, MovePriceSubGroupError, MovePriceSubGroupVariables>({
    mutationFn: (variables: MovePriceSubGroupVariables) =>
      fetchMovePriceSubGroup({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdatePriceSubGroupEntriesError = Fetcher.ErrorWrapper<undefined>;

export type UpdatePriceSubGroupEntriesRequestBody = Schemas.PriceSheetSubGroupEntryDTO[];

export type UpdatePriceSubGroupEntriesVariables = {
  body?: UpdatePriceSubGroupEntriesRequestBody;
} & DiceContext['fetcherOptions'];

export const fetchUpdatePriceSubGroupEntries = (
  variables: UpdatePriceSubGroupEntriesVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    UpdatePriceSubGroupEntriesError,
    UpdatePriceSubGroupEntriesRequestBody,
    {},
    {},
    {}
  >({ url: '/price-groups/sub-groups/entries', method: 'patch', ...variables, signal });

export const useUpdatePriceSubGroupEntries = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdatePriceSubGroupEntriesError,
      UpdatePriceSubGroupEntriesVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    UpdatePriceSubGroupEntriesError,
    UpdatePriceSubGroupEntriesVariables
  >({
    mutationFn: (variables: UpdatePriceSubGroupEntriesVariables) =>
      fetchUpdatePriceSubGroupEntries({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateBasicFeeEntriesError = Fetcher.ErrorWrapper<undefined>;

export type UpdateBasicFeeEntriesRequestBody = Schemas.CostUnitBasicFeeEntryDTO[];

export type UpdateBasicFeeEntriesVariables = {
  body?: UpdateBasicFeeEntriesRequestBody;
} & DiceContext['fetcherOptions'];

export const fetchUpdateBasicFeeEntries = (
  variables: UpdateBasicFeeEntriesVariables,
  signal?: AbortSignal,
) =>
  diceFetch<undefined, UpdateBasicFeeEntriesError, UpdateBasicFeeEntriesRequestBody, {}, {}, {}>({
    url: '/cost-unit/basic-fee/groups/entries',
    method: 'patch',
    ...variables,
    signal,
  });

export const useUpdateBasicFeeEntries = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdateBasicFeeEntriesError,
      UpdateBasicFeeEntriesVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    UpdateBasicFeeEntriesError,
    UpdateBasicFeeEntriesVariables
  >({
    mutationFn: (variables: UpdateBasicFeeEntriesVariables) =>
      fetchUpdateBasicFeeEntries({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetProfitCenterTreeForCostUnitPathParams {
  yearId: string;
  masterConfigId: string;
}

export type GetProfitCenterTreeForCostUnitError = Fetcher.ErrorWrapper<undefined>;

export type GetProfitCenterTreeForCostUnitResponse = Record<
  string,
  Schemas.AcctTrxSelectCostUnitCriteriaDTO
>;

export type GetProfitCenterTreeForCostUnitVariables = {
  pathParams: GetProfitCenterTreeForCostUnitPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetProfitCenterTreeForCostUnit = (
  variables: GetProfitCenterTreeForCostUnitVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetProfitCenterTreeForCostUnitResponse,
    GetProfitCenterTreeForCostUnitError,
    undefined,
    {},
    {},
    GetProfitCenterTreeForCostUnitPathParams
  >({
    url: '/years/{yearId}/master-configs/{masterConfigId}/cost-units/profit-center-trees',
    method: 'get',
    ...variables,
    signal,
  });

export const getProfitCenterTreeForCostUnitQuery = (
  variables: GetProfitCenterTreeForCostUnitVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({
    signal,
  }: {
    signal?: AbortSignal;
  }) => Promise<GetProfitCenterTreeForCostUnitResponse>;
} => ({
  queryKey: queryKeyFn({
    path: '/years/{yearId}/master-configs/{masterConfigId}/cost-units/profit-center-trees',
    operationId: 'getProfitCenterTreeForCostUnit',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetProfitCenterTreeForCostUnit({ ...variables }, signal),
});

export const useSuspenseGetProfitCenterTreeForCostUnit = <
  TData = GetProfitCenterTreeForCostUnitResponse,
>(
  variables: GetProfitCenterTreeForCostUnitVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetProfitCenterTreeForCostUnitResponse,
      GetProfitCenterTreeForCostUnitError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetProfitCenterTreeForCostUnitResponse,
    GetProfitCenterTreeForCostUnitError,
    TData
  >({
    ...getProfitCenterTreeForCostUnitQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetProfitCenterTreeForCostUnit = <TData = GetProfitCenterTreeForCostUnitResponse>(
  variables: GetProfitCenterTreeForCostUnitVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetProfitCenterTreeForCostUnitResponse,
      GetProfitCenterTreeForCostUnitError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    GetProfitCenterTreeForCostUnitResponse,
    GetProfitCenterTreeForCostUnitError,
    TData
  >({
    ...getProfitCenterTreeForCostUnitQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface GetDataContainerByIdPathParams {
  ids: string[];
}

export type GetDataContainerByIdError = Fetcher.ErrorWrapper<undefined>;

export type GetDataContainerByIdResponse = Schemas.YearDTO[];

export type GetDataContainerByIdVariables = {
  pathParams: GetDataContainerByIdPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetDataContainerById = (
  variables: GetDataContainerByIdVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetDataContainerByIdResponse,
    GetDataContainerByIdError,
    undefined,
    {},
    {},
    GetDataContainerByIdPathParams
  >({ url: '/years/{ids}', method: 'get', ...variables, signal });

export const getDataContainerByIdQuery = (
  variables: GetDataContainerByIdVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<GetDataContainerByIdResponse>;
} => ({
  queryKey: queryKeyFn({
    path: '/years/{ids}',
    operationId: 'getDataContainerById',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetDataContainerById({ ...variables }, signal),
});

export const useSuspenseGetDataContainerById = <TData = GetDataContainerByIdResponse>(
  variables: GetDataContainerByIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetDataContainerByIdResponse, GetDataContainerByIdError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetDataContainerByIdResponse,
    GetDataContainerByIdError,
    TData
  >({
    ...getDataContainerByIdQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetDataContainerById = <TData = GetDataContainerByIdResponse>(
  variables: GetDataContainerByIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetDataContainerByIdResponse, GetDataContainerByIdError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<GetDataContainerByIdResponse, GetDataContainerByIdError, TData>({
    ...getDataContainerByIdQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface GetYearCalculationPathParams {
  yearCalculationId: string;
}

export type GetYearCalculationError = Fetcher.ErrorWrapper<undefined>;

export type GetYearCalculationVariables = {
  pathParams: GetYearCalculationPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetYearCalculation = (
  variables: GetYearCalculationVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.YearCalculationDTO,
    GetYearCalculationError,
    undefined,
    {},
    {},
    GetYearCalculationPathParams
  >({ url: '/year-calculations/{yearCalculationId}', method: 'get', ...variables, signal });

export const getYearCalculationQuery = (
  variables: GetYearCalculationVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<Schemas.YearCalculationDTO>;
} => ({
  queryKey: queryKeyFn({
    path: '/year-calculations/{yearCalculationId}',
    operationId: 'getYearCalculation',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetYearCalculation({ ...variables }, signal),
});

export const useSuspenseGetYearCalculation = <TData = Schemas.YearCalculationDTO>(
  variables: GetYearCalculationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.YearCalculationDTO, GetYearCalculationError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<Schemas.YearCalculationDTO, GetYearCalculationError, TData>({
    ...getYearCalculationQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetYearCalculation = <TData = Schemas.YearCalculationDTO>(
  variables: GetYearCalculationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.YearCalculationDTO, GetYearCalculationError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<Schemas.YearCalculationDTO, GetYearCalculationError, TData>({
    ...getYearCalculationQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface GetMasterConfigurationByYearCalculationPathParams {
  yearCalculationId: string;
}

export interface GetMasterConfigurationByYearCalculationHeaders {
  authorization?: string;
}

export type GetMasterConfigurationByYearCalculationError = Fetcher.ErrorWrapper<undefined>;

export type GetMasterConfigurationByYearCalculationResponse =
  Schemas.MasterConfigurationEntityDTO[];

export type GetMasterConfigurationByYearCalculationVariables = {
  headers?: GetMasterConfigurationByYearCalculationHeaders;
  pathParams: GetMasterConfigurationByYearCalculationPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetMasterConfigurationByYearCalculation = (
  variables: GetMasterConfigurationByYearCalculationVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetMasterConfigurationByYearCalculationResponse,
    GetMasterConfigurationByYearCalculationError,
    undefined,
    GetMasterConfigurationByYearCalculationHeaders,
    {},
    GetMasterConfigurationByYearCalculationPathParams
  >({
    url: '/year-calculations/{yearCalculationId}/master-configurations/entities',
    method: 'get',
    ...variables,
    signal,
  });

export const getMasterConfigurationByYearCalculationQuery = (
  variables: GetMasterConfigurationByYearCalculationVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({
    signal,
  }: {
    signal?: AbortSignal;
  }) => Promise<GetMasterConfigurationByYearCalculationResponse>;
} => ({
  queryKey: queryKeyFn({
    path: '/year-calculations/{yearCalculationId}/master-configurations/entities',
    operationId: 'getMasterConfigurationByYearCalculation',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetMasterConfigurationByYearCalculation({ ...variables }, signal),
});

export const useSuspenseGetMasterConfigurationByYearCalculation = <
  TData = GetMasterConfigurationByYearCalculationResponse,
>(
  variables: GetMasterConfigurationByYearCalculationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetMasterConfigurationByYearCalculationResponse,
      GetMasterConfigurationByYearCalculationError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetMasterConfigurationByYearCalculationResponse,
    GetMasterConfigurationByYearCalculationError,
    TData
  >({
    ...getMasterConfigurationByYearCalculationQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetMasterConfigurationByYearCalculation = <
  TData = GetMasterConfigurationByYearCalculationResponse,
>(
  variables: GetMasterConfigurationByYearCalculationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetMasterConfigurationByYearCalculationResponse,
      GetMasterConfigurationByYearCalculationError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    GetMasterConfigurationByYearCalculationResponse,
    GetMasterConfigurationByYearCalculationError,
    TData
  >({
    ...getMasterConfigurationByYearCalculationQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface GetFilesMetadataPathParams {
  yearCalculationId: string;
}

export type GetFilesMetadataError = Fetcher.ErrorWrapper<undefined>;

export type GetFilesMetadataResponse = Schemas.FileMetadataDTO[];

export type GetFilesMetadataVariables = {
  pathParams: GetFilesMetadataPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetFilesMetadata = (variables: GetFilesMetadataVariables, signal?: AbortSignal) =>
  diceFetch<
    GetFilesMetadataResponse,
    GetFilesMetadataError,
    undefined,
    {},
    {},
    GetFilesMetadataPathParams
  >({
    url: '/year-calculations/{yearCalculationId}/files-metadata',
    method: 'get',
    ...variables,
    signal,
  });

export const getFilesMetadataQuery = (
  variables: GetFilesMetadataVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<GetFilesMetadataResponse>;
} => ({
  queryKey: queryKeyFn({
    path: '/year-calculations/{yearCalculationId}/files-metadata',
    operationId: 'getFilesMetadata',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetFilesMetadata({ ...variables }, signal),
});

export const useSuspenseGetFilesMetadata = <TData = GetFilesMetadataResponse>(
  variables: GetFilesMetadataVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetFilesMetadataResponse, GetFilesMetadataError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<GetFilesMetadataResponse, GetFilesMetadataError, TData>({
    ...getFilesMetadataQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetFilesMetadata = <TData = GetFilesMetadataResponse>(
  variables: GetFilesMetadataVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetFilesMetadataResponse, GetFilesMetadataError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<GetFilesMetadataResponse, GetFilesMetadataError, TData>({
    ...getFilesMetadataQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface DeleteFileMetadataPathParams {
  yearCalculationId: string;
}

export type DeleteFileMetadataError = Fetcher.ErrorWrapper<undefined>;

export type DeleteFileMetadataVariables = {
  pathParams: DeleteFileMetadataPathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeleteFileMetadata = (
  variables: DeleteFileMetadataVariables,
  signal?: AbortSignal,
) =>
  diceFetch<undefined, DeleteFileMetadataError, undefined, {}, {}, DeleteFileMetadataPathParams>({
    url: '/year-calculations/{yearCalculationId}/files-metadata',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteFileMetadata = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteFileMetadataError, DeleteFileMetadataVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, DeleteFileMetadataError, DeleteFileMetadataVariables>({
    mutationFn: (variables: DeleteFileMetadataVariables) =>
      fetchDeleteFileMetadata({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetFilesMetadataStatusPathParams {
  yearCalculationId: string;
}

export type GetFilesMetadataStatusError = Fetcher.ErrorWrapper<undefined>;

export type GetFilesMetadataStatusVariables = {
  pathParams: GetFilesMetadataStatusPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetFilesMetadataStatus = (
  variables: GetFilesMetadataStatusVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.InputDataStatusDTO,
    GetFilesMetadataStatusError,
    undefined,
    {},
    {},
    GetFilesMetadataStatusPathParams
  >({
    url: '/year-calculations/{yearCalculationId}/files-metadata/status',
    method: 'get',
    ...variables,
    signal,
  });

export const getFilesMetadataStatusQuery = (
  variables: GetFilesMetadataStatusVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<Schemas.InputDataStatusDTO>;
} => ({
  queryKey: queryKeyFn({
    path: '/year-calculations/{yearCalculationId}/files-metadata/status',
    operationId: 'getFilesMetadataStatus',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetFilesMetadataStatus({ ...variables }, signal),
});

export const useSuspenseGetFilesMetadataStatus = <TData = Schemas.InputDataStatusDTO>(
  variables: GetFilesMetadataStatusVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.InputDataStatusDTO, GetFilesMetadataStatusError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    Schemas.InputDataStatusDTO,
    GetFilesMetadataStatusError,
    TData
  >({
    ...getFilesMetadataStatusQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetFilesMetadataStatus = <TData = Schemas.InputDataStatusDTO>(
  variables: GetFilesMetadataStatusVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.InputDataStatusDTO, GetFilesMetadataStatusError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<Schemas.InputDataStatusDTO, GetFilesMetadataStatusError, TData>({
    ...getFilesMetadataStatusQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface GetMasterConfigurationsPathParams {
  publicFacilityId: string;
}

export type GetMasterConfigurationsError = Fetcher.ErrorWrapper<undefined>;

export type GetMasterConfigurationsResponse = Schemas.YearExtendedDTOV2[];

export type GetMasterConfigurationsVariables = {
  pathParams: GetMasterConfigurationsPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetMasterConfigurations = (
  variables: GetMasterConfigurationsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetMasterConfigurationsResponse,
    GetMasterConfigurationsError,
    undefined,
    {},
    {},
    GetMasterConfigurationsPathParams
  >({
    url: '/public-facilities/{publicFacilityId}/years/year-calculations/master-configurations',
    method: 'get',
    ...variables,
    signal,
  });

export const getMasterConfigurationsQuery = (
  variables: GetMasterConfigurationsVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<GetMasterConfigurationsResponse>;
} => ({
  queryKey: queryKeyFn({
    path: '/public-facilities/{publicFacilityId}/years/year-calculations/master-configurations',
    operationId: 'getMasterConfigurations',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetMasterConfigurations({ ...variables }, signal),
});

export const useSuspenseGetMasterConfigurations = <TData = GetMasterConfigurationsResponse>(
  variables: GetMasterConfigurationsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetMasterConfigurationsResponse,
      GetMasterConfigurationsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetMasterConfigurationsResponse,
    GetMasterConfigurationsError,
    TData
  >({
    ...getMasterConfigurationsQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetMasterConfigurations = <TData = GetMasterConfigurationsResponse>(
  variables: GetMasterConfigurationsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetMasterConfigurationsResponse,
      GetMasterConfigurationsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<GetMasterConfigurationsResponse, GetMasterConfigurationsError, TData>({
    ...getMasterConfigurationsQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface GetPriceSheetConfigPathParams {
  masterConfigId: string;
  yearId: string;
}

export type GetPriceSheetConfigError = Fetcher.ErrorWrapper<undefined>;

export type GetPriceSheetConfigResponse = Schemas.PriceSheetGroupDTO[];

export type GetPriceSheetConfigVariables = {
  pathParams: GetPriceSheetConfigPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetPriceSheetConfig = (
  variables: GetPriceSheetConfigVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetPriceSheetConfigResponse,
    GetPriceSheetConfigError,
    undefined,
    {},
    {},
    GetPriceSheetConfigPathParams
  >({
    url: '/price-groups/years/{yearId}/master-configs/{masterConfigId}',
    method: 'get',
    ...variables,
    signal,
  });

export const getPriceSheetConfigQuery = (
  variables: GetPriceSheetConfigVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<GetPriceSheetConfigResponse>;
} => ({
  queryKey: queryKeyFn({
    path: '/price-groups/years/{yearId}/master-configs/{masterConfigId}',
    operationId: 'getPriceSheetConfig',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetPriceSheetConfig({ ...variables }, signal),
});

export const useSuspenseGetPriceSheetConfig = <TData = GetPriceSheetConfigResponse>(
  variables: GetPriceSheetConfigVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetPriceSheetConfigResponse, GetPriceSheetConfigError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<GetPriceSheetConfigResponse, GetPriceSheetConfigError, TData>({
    ...getPriceSheetConfigQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetPriceSheetConfig = <TData = GetPriceSheetConfigResponse>(
  variables: GetPriceSheetConfigVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetPriceSheetConfigResponse, GetPriceSheetConfigError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<GetPriceSheetConfigResponse, GetPriceSheetConfigError, TData>({
    ...getPriceSheetConfigQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface GetPriceSheetReportPathParams {
  masterConfigId: string;
  yearId: string;
}

export type GetPriceSheetReportError = Fetcher.ErrorWrapper<undefined>;

export type GetPriceSheetReportResponse = Schemas.PriceSheetReportGroupDTO[];

export type GetPriceSheetReportVariables = {
  pathParams: GetPriceSheetReportPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetPriceSheetReport = (
  variables: GetPriceSheetReportVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetPriceSheetReportResponse,
    GetPriceSheetReportError,
    undefined,
    {},
    {},
    GetPriceSheetReportPathParams
  >({
    url: '/price-groups/years/{yearId}/master-configs/{masterConfigId}/reports',
    method: 'get',
    ...variables,
    signal,
  });

export const getPriceSheetReportQuery = (
  variables: GetPriceSheetReportVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<GetPriceSheetReportResponse>;
} => ({
  queryKey: queryKeyFn({
    path: '/price-groups/years/{yearId}/master-configs/{masterConfigId}/reports',
    operationId: 'getPriceSheetReport',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetPriceSheetReport({ ...variables }, signal),
});

export const useSuspenseGetPriceSheetReport = <TData = GetPriceSheetReportResponse>(
  variables: GetPriceSheetReportVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetPriceSheetReportResponse, GetPriceSheetReportError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<GetPriceSheetReportResponse, GetPriceSheetReportError, TData>({
    ...getPriceSheetReportQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetPriceSheetReport = <TData = GetPriceSheetReportResponse>(
  variables: GetPriceSheetReportVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetPriceSheetReportResponse, GetPriceSheetReportError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<GetPriceSheetReportResponse, GetPriceSheetReportError, TData>({
    ...getPriceSheetReportQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export type TestEndpointError = Fetcher.ErrorWrapper<undefined>;

export type TestEndpointVariables = DiceContext['fetcherOptions'];

export const fetchTestEndpoint = (variables: TestEndpointVariables, signal?: AbortSignal) =>
  diceFetch<string, TestEndpointError, undefined, {}, {}, {}>({
    url: '/migration/test-endpoint',
    method: 'get',
    ...variables,
    signal,
  });

export const testEndpointQuery = (
  variables: TestEndpointVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<string>;
} => ({
  queryKey: queryKeyFn({
    path: '/migration/test-endpoint',
    operationId: 'testEndpoint',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) => fetchTestEndpoint({ ...variables }, signal),
});

export const useSuspenseTestEndpoint = <TData = string>(
  variables: TestEndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<string, TestEndpointError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<string, TestEndpointError, TData>({
    ...testEndpointQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useTestEndpoint = <TData = string>(
  variables: TestEndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<string, TestEndpointError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<string, TestEndpointError, TData>({
    ...testEndpointQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface GetPlannedCostRevenueConfigPathParams {
  masterConfigurationId: string;
}

export type GetPlannedCostRevenueConfigError = Fetcher.ErrorWrapper<undefined>;

export type GetPlannedCostRevenueConfigVariables = {
  pathParams: GetPlannedCostRevenueConfigPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetPlannedCostRevenueConfig = (
  variables: GetPlannedCostRevenueConfigVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.PlannedCostRevenueConfigDTO,
    GetPlannedCostRevenueConfigError,
    undefined,
    {},
    {},
    GetPlannedCostRevenueConfigPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/planned-cost-revenue',
    method: 'get',
    ...variables,
    signal,
  });

export const getPlannedCostRevenueConfigQuery = (
  variables: GetPlannedCostRevenueConfigVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<Schemas.PlannedCostRevenueConfigDTO>;
} => ({
  queryKey: queryKeyFn({
    path: '/master-configurations/{masterConfigurationId}/planned-cost-revenue',
    operationId: 'getPlannedCostRevenueConfig',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetPlannedCostRevenueConfig({ ...variables }, signal),
});

export const useSuspenseGetPlannedCostRevenueConfig = <TData = Schemas.PlannedCostRevenueConfigDTO>(
  variables: GetPlannedCostRevenueConfigVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PlannedCostRevenueConfigDTO,
      GetPlannedCostRevenueConfigError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    Schemas.PlannedCostRevenueConfigDTO,
    GetPlannedCostRevenueConfigError,
    TData
  >({
    ...getPlannedCostRevenueConfigQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetPlannedCostRevenueConfig = <TData = Schemas.PlannedCostRevenueConfigDTO>(
  variables: GetPlannedCostRevenueConfigVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PlannedCostRevenueConfigDTO,
      GetPlannedCostRevenueConfigError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    Schemas.PlannedCostRevenueConfigDTO,
    GetPlannedCostRevenueConfigError,
    TData
  >({
    ...getPlannedCostRevenueConfigQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface GetPlannedCostRevenuePreselectedValuesPathParams {
  masterConfigurationId: string;
}

export type GetPlannedCostRevenuePreselectedValuesError = Fetcher.ErrorWrapper<undefined>;

export type GetPlannedCostRevenuePreselectedValuesVariables = {
  pathParams: GetPlannedCostRevenuePreselectedValuesPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetPlannedCostRevenuePreselectedValues = (
  variables: GetPlannedCostRevenuePreselectedValuesVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.PlannedCostRevenuePreselectedValuesDTO,
    GetPlannedCostRevenuePreselectedValuesError,
    undefined,
    {},
    {},
    GetPlannedCostRevenuePreselectedValuesPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/planned-cost-revenue/preselected-values',
    method: 'get',
    ...variables,
    signal,
  });

export const getPlannedCostRevenuePreselectedValuesQuery = (
  variables: GetPlannedCostRevenuePreselectedValuesVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({
    signal,
  }: {
    signal?: AbortSignal;
  }) => Promise<Schemas.PlannedCostRevenuePreselectedValuesDTO>;
} => ({
  queryKey: queryKeyFn({
    path: '/master-configurations/{masterConfigurationId}/planned-cost-revenue/preselected-values',
    operationId: 'getPlannedCostRevenuePreselectedValues',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetPlannedCostRevenuePreselectedValues({ ...variables }, signal),
});

export const useSuspenseGetPlannedCostRevenuePreselectedValues = <
  TData = Schemas.PlannedCostRevenuePreselectedValuesDTO,
>(
  variables: GetPlannedCostRevenuePreselectedValuesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PlannedCostRevenuePreselectedValuesDTO,
      GetPlannedCostRevenuePreselectedValuesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    Schemas.PlannedCostRevenuePreselectedValuesDTO,
    GetPlannedCostRevenuePreselectedValuesError,
    TData
  >({
    ...getPlannedCostRevenuePreselectedValuesQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetPlannedCostRevenuePreselectedValues = <
  TData = Schemas.PlannedCostRevenuePreselectedValuesDTO,
>(
  variables: GetPlannedCostRevenuePreselectedValuesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PlannedCostRevenuePreselectedValuesDTO,
      GetPlannedCostRevenuePreselectedValuesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    Schemas.PlannedCostRevenuePreselectedValuesDTO,
    GetPlannedCostRevenuePreselectedValuesError,
    TData
  >({
    ...getPlannedCostRevenuePreselectedValuesQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface GetCostUnitAllocationPreselectedValuesPathParams {
  masterConfigurationId: string;
}

export type GetCostUnitAllocationPreselectedValuesError = Fetcher.ErrorWrapper<undefined>;

export type GetCostUnitAllocationPreselectedValuesVariables = {
  pathParams: GetCostUnitAllocationPreselectedValuesPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetCostUnitAllocationPreselectedValues = (
  variables: GetCostUnitAllocationPreselectedValuesVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.CostUnitAllocationPreselectedValuesDTO,
    GetCostUnitAllocationPreselectedValuesError,
    undefined,
    {},
    {},
    GetCostUnitAllocationPreselectedValuesPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/cost-unit-allocations/preselected-values',
    method: 'get',
    ...variables,
    signal,
  });

export const getCostUnitAllocationPreselectedValuesQuery = (
  variables: GetCostUnitAllocationPreselectedValuesVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({
    signal,
  }: {
    signal?: AbortSignal;
  }) => Promise<Schemas.CostUnitAllocationPreselectedValuesDTO>;
} => ({
  queryKey: queryKeyFn({
    path: '/master-configurations/{masterConfigurationId}/cost-unit-allocations/preselected-values',
    operationId: 'getCostUnitAllocationPreselectedValues',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetCostUnitAllocationPreselectedValues({ ...variables }, signal),
});

export const useSuspenseGetCostUnitAllocationPreselectedValues = <
  TData = Schemas.CostUnitAllocationPreselectedValuesDTO,
>(
  variables: GetCostUnitAllocationPreselectedValuesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.CostUnitAllocationPreselectedValuesDTO,
      GetCostUnitAllocationPreselectedValuesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    Schemas.CostUnitAllocationPreselectedValuesDTO,
    GetCostUnitAllocationPreselectedValuesError,
    TData
  >({
    ...getCostUnitAllocationPreselectedValuesQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetCostUnitAllocationPreselectedValues = <
  TData = Schemas.CostUnitAllocationPreselectedValuesDTO,
>(
  variables: GetCostUnitAllocationPreselectedValuesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.CostUnitAllocationPreselectedValuesDTO,
      GetCostUnitAllocationPreselectedValuesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    Schemas.CostUnitAllocationPreselectedValuesDTO,
    GetCostUnitAllocationPreselectedValuesError,
    TData
  >({
    ...getCostUnitAllocationPreselectedValuesQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface GetMasterConfigurationByIdPathParams {
  id: string;
}

export type GetMasterConfigurationByIdError = Fetcher.ErrorWrapper<undefined>;

export type GetMasterConfigurationByIdVariables = {
  pathParams: GetMasterConfigurationByIdPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetMasterConfigurationById = (
  variables: GetMasterConfigurationByIdVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.MasterConfiguration,
    GetMasterConfigurationByIdError,
    undefined,
    {},
    {},
    GetMasterConfigurationByIdPathParams
  >({ url: '/master-configuration/{id}', method: 'get', ...variables, signal });

export const getMasterConfigurationByIdQuery = (
  variables: GetMasterConfigurationByIdVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<Schemas.MasterConfiguration>;
} => ({
  queryKey: queryKeyFn({
    path: '/master-configuration/{id}',
    operationId: 'getMasterConfigurationById',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetMasterConfigurationById({ ...variables }, signal),
});

export const useSuspenseGetMasterConfigurationById = <TData = Schemas.MasterConfiguration>(
  variables: GetMasterConfigurationByIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.MasterConfiguration, GetMasterConfigurationByIdError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    Schemas.MasterConfiguration,
    GetMasterConfigurationByIdError,
    TData
  >({
    ...getMasterConfigurationByIdQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetMasterConfigurationById = <TData = Schemas.MasterConfiguration>(
  variables: GetMasterConfigurationByIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.MasterConfiguration, GetMasterConfigurationByIdError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<Schemas.MasterConfiguration, GetMasterConfigurationByIdError, TData>({
    ...getMasterConfigurationByIdQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface DeleteMasterConfigurationByIdPathParams {
  id: string;
}

export interface DeleteMasterConfigurationByIdHeaders {
  authorization?: string;
}

export type DeleteMasterConfigurationByIdError = Fetcher.ErrorWrapper<undefined>;

export type DeleteMasterConfigurationByIdVariables = {
  headers?: DeleteMasterConfigurationByIdHeaders;
  pathParams: DeleteMasterConfigurationByIdPathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeleteMasterConfigurationById = (
  variables: DeleteMasterConfigurationByIdVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    DeleteMasterConfigurationByIdError,
    undefined,
    DeleteMasterConfigurationByIdHeaders,
    {},
    DeleteMasterConfigurationByIdPathParams
  >({ url: '/master-configuration/{id}', method: 'delete', ...variables, signal });

export const useDeleteMasterConfigurationById = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteMasterConfigurationByIdError,
      DeleteMasterConfigurationByIdVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    DeleteMasterConfigurationByIdError,
    DeleteMasterConfigurationByIdVariables
  >({
    mutationFn: (variables: DeleteMasterConfigurationByIdVariables) =>
      fetchDeleteMasterConfigurationById({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetAllMasterConfigurationsByYearError = Fetcher.ErrorWrapper<undefined>;

export type GetAllMasterConfigurationsByYearResponse = Record<
  string,
  Schemas.MasterConfiguration[]
>;

export type GetAllMasterConfigurationsByYearVariables = DiceContext['fetcherOptions'];

export const fetchGetAllMasterConfigurationsByYear = (
  variables: GetAllMasterConfigurationsByYearVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetAllMasterConfigurationsByYearResponse,
    GetAllMasterConfigurationsByYearError,
    undefined,
    {},
    {},
    {}
  >({ url: '/master-configuration/year', method: 'get', ...variables, signal });

export const getAllMasterConfigurationsByYearQuery = (
  variables: GetAllMasterConfigurationsByYearVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({
    signal,
  }: {
    signal?: AbortSignal;
  }) => Promise<GetAllMasterConfigurationsByYearResponse>;
} => ({
  queryKey: queryKeyFn({
    path: '/master-configuration/year',
    operationId: 'getAllMasterConfigurationsByYear',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetAllMasterConfigurationsByYear({ ...variables }, signal),
});

export const useSuspenseGetAllMasterConfigurationsByYear = <
  TData = GetAllMasterConfigurationsByYearResponse,
>(
  variables: GetAllMasterConfigurationsByYearVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAllMasterConfigurationsByYearResponse,
      GetAllMasterConfigurationsByYearError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetAllMasterConfigurationsByYearResponse,
    GetAllMasterConfigurationsByYearError,
    TData
  >({
    ...getAllMasterConfigurationsByYearQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetAllMasterConfigurationsByYear = <
  TData = GetAllMasterConfigurationsByYearResponse,
>(
  variables: GetAllMasterConfigurationsByYearVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAllMasterConfigurationsByYearResponse,
      GetAllMasterConfigurationsByYearError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    GetAllMasterConfigurationsByYearResponse,
    GetAllMasterConfigurationsByYearError,
    TData
  >({
    ...getAllMasterConfigurationsByYearQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface GetMasterConfigurationsByYearIdPathParams {
  yearId: string;
}

export type GetMasterConfigurationsByYearIdError = Fetcher.ErrorWrapper<undefined>;

export type GetMasterConfigurationsByYearIdResponse = Schemas.MasterConfiguration[];

export type GetMasterConfigurationsByYearIdVariables = {
  pathParams: GetMasterConfigurationsByYearIdPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetMasterConfigurationsByYearId = (
  variables: GetMasterConfigurationsByYearIdVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetMasterConfigurationsByYearIdResponse,
    GetMasterConfigurationsByYearIdError,
    undefined,
    {},
    {},
    GetMasterConfigurationsByYearIdPathParams
  >({ url: '/master-configuration/year/{yearId}', method: 'get', ...variables, signal });

export const getMasterConfigurationsByYearIdQuery = (
  variables: GetMasterConfigurationsByYearIdVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({
    signal,
  }: {
    signal?: AbortSignal;
  }) => Promise<GetMasterConfigurationsByYearIdResponse>;
} => ({
  queryKey: queryKeyFn({
    path: '/master-configuration/year/{yearId}',
    operationId: 'getMasterConfigurationsByYearId',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetMasterConfigurationsByYearId({ ...variables }, signal),
});

export const useSuspenseGetMasterConfigurationsByYearId = <
  TData = GetMasterConfigurationsByYearIdResponse,
>(
  variables: GetMasterConfigurationsByYearIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetMasterConfigurationsByYearIdResponse,
      GetMasterConfigurationsByYearIdError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetMasterConfigurationsByYearIdResponse,
    GetMasterConfigurationsByYearIdError,
    TData
  >({
    ...getMasterConfigurationsByYearIdQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetMasterConfigurationsByYearId = <TData = GetMasterConfigurationsByYearIdResponse>(
  variables: GetMasterConfigurationsByYearIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetMasterConfigurationsByYearIdResponse,
      GetMasterConfigurationsByYearIdError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    GetMasterConfigurationsByYearIdResponse,
    GetMasterConfigurationsByYearIdError,
    TData
  >({
    ...getMasterConfigurationsByYearIdQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface GetConfigsStatusPathParams {
  masterConfigurationId: string;
}

export type GetConfigsStatusError = Fetcher.ErrorWrapper<undefined>;

export type GetConfigsStatusVariables = {
  pathParams: GetConfigsStatusPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetConfigsStatus = (variables: GetConfigsStatusVariables, signal?: AbortSignal) =>
  diceFetch<
    Schemas.ConfigsStatusDTO,
    GetConfigsStatusError,
    undefined,
    {},
    {},
    GetConfigsStatusPathParams
  >({ url: '/master-configs/{masterConfigurationId}/status', method: 'get', ...variables, signal });

export const getConfigsStatusQuery = (
  variables: GetConfigsStatusVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<Schemas.ConfigsStatusDTO>;
} => ({
  queryKey: queryKeyFn({
    path: '/master-configs/{masterConfigurationId}/status',
    operationId: 'getConfigsStatus',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetConfigsStatus({ ...variables }, signal),
});

export const useSuspenseGetConfigsStatus = <TData = Schemas.ConfigsStatusDTO>(
  variables: GetConfigsStatusVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ConfigsStatusDTO, GetConfigsStatusError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<Schemas.ConfigsStatusDTO, GetConfigsStatusError, TData>({
    ...getConfigsStatusQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetConfigsStatus = <TData = Schemas.ConfigsStatusDTO>(
  variables: GetConfigsStatusVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ConfigsStatusDTO, GetConfigsStatusError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<Schemas.ConfigsStatusDTO, GetConfigsStatusError, TData>({
    ...getConfigsStatusQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface GetAllocationKeyConfigPathParams {
  masterConfigurationId: string;
}

export type GetAllocationKeyConfigError = Fetcher.ErrorWrapper<undefined>;

export type GetAllocationKeyConfigResponse = Schemas.AllocationKeyConfigDTO[];

export type GetAllocationKeyConfigVariables = {
  pathParams: GetAllocationKeyConfigPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetAllocationKeyConfig = (
  variables: GetAllocationKeyConfigVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetAllocationKeyConfigResponse,
    GetAllocationKeyConfigError,
    undefined,
    {},
    {},
    GetAllocationKeyConfigPathParams
  >({
    url: '/master-configs/{masterConfigurationId}/allocation-key-configs',
    method: 'get',
    ...variables,
    signal,
  });

export const getAllocationKeyConfigQuery = (
  variables: GetAllocationKeyConfigVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<GetAllocationKeyConfigResponse>;
} => ({
  queryKey: queryKeyFn({
    path: '/master-configs/{masterConfigurationId}/allocation-key-configs',
    operationId: 'getAllocationKeyConfig',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetAllocationKeyConfig({ ...variables }, signal),
});

export const useSuspenseGetAllocationKeyConfig = <TData = GetAllocationKeyConfigResponse>(
  variables: GetAllocationKeyConfigVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetAllocationKeyConfigResponse, GetAllocationKeyConfigError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetAllocationKeyConfigResponse,
    GetAllocationKeyConfigError,
    TData
  >({
    ...getAllocationKeyConfigQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetAllocationKeyConfig = <TData = GetAllocationKeyConfigResponse>(
  variables: GetAllocationKeyConfigVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetAllocationKeyConfigResponse, GetAllocationKeyConfigError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<GetAllocationKeyConfigResponse, GetAllocationKeyConfigError, TData>({
    ...getAllocationKeyConfigQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface GetAllocationKeyConfigStatusPathParams {
  masterConfigurationId: string;
}

export type GetAllocationKeyConfigStatusError = Fetcher.ErrorWrapper<undefined>;

export type GetAllocationKeyConfigStatusVariables = {
  pathParams: GetAllocationKeyConfigStatusPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetAllocationKeyConfigStatus = (
  variables: GetAllocationKeyConfigStatusVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    'FULLY_ALLOCATED' | 'PARTIALLY_ALLOCATED' | 'NOTHING_ALLOCATED',
    GetAllocationKeyConfigStatusError,
    undefined,
    {},
    {},
    GetAllocationKeyConfigStatusPathParams
  >({
    url: '/master-configs/{masterConfigurationId}/allocation-key-configs-status',
    method: 'get',
    ...variables,
    signal,
  });

export const getAllocationKeyConfigStatusQuery = (
  variables: GetAllocationKeyConfigStatusVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({
    signal,
  }: {
    signal?: AbortSignal;
  }) => Promise<'FULLY_ALLOCATED' | 'PARTIALLY_ALLOCATED' | 'NOTHING_ALLOCATED'>;
} => ({
  queryKey: queryKeyFn({
    path: '/master-configs/{masterConfigurationId}/allocation-key-configs-status',
    operationId: 'getAllocationKeyConfigStatus',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetAllocationKeyConfigStatus({ ...variables }, signal),
});

export const useSuspenseGetAllocationKeyConfigStatus = <
  TData = 'FULLY_ALLOCATED' | 'PARTIALLY_ALLOCATED' | 'NOTHING_ALLOCATED',
>(
  variables: GetAllocationKeyConfigStatusVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      'FULLY_ALLOCATED' | 'PARTIALLY_ALLOCATED' | 'NOTHING_ALLOCATED',
      GetAllocationKeyConfigStatusError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    'FULLY_ALLOCATED' | 'PARTIALLY_ALLOCATED' | 'NOTHING_ALLOCATED',
    GetAllocationKeyConfigStatusError,
    TData
  >({
    ...getAllocationKeyConfigStatusQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetAllocationKeyConfigStatus = <
  TData = 'FULLY_ALLOCATED' | 'PARTIALLY_ALLOCATED' | 'NOTHING_ALLOCATED',
>(
  variables: GetAllocationKeyConfigStatusVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      'FULLY_ALLOCATED' | 'PARTIALLY_ALLOCATED' | 'NOTHING_ALLOCATED',
      GetAllocationKeyConfigStatusError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    'FULLY_ALLOCATED' | 'PARTIALLY_ALLOCATED' | 'NOTHING_ALLOCATED',
    GetAllocationKeyConfigStatusError,
    TData
  >({
    ...getAllocationKeyConfigStatusQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface GetAccountsByMasterConfigurationPathParams {
  masterConfigurationId: string;
}

export type GetAccountsByMasterConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type GetAccountsByMasterConfigurationResponse = Schemas.AccountDTO[];

export type GetAccountsByMasterConfigurationVariables = {
  pathParams: GetAccountsByMasterConfigurationPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetAccountsByMasterConfiguration = (
  variables: GetAccountsByMasterConfigurationVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetAccountsByMasterConfigurationResponse,
    GetAccountsByMasterConfigurationError,
    undefined,
    {},
    {},
    GetAccountsByMasterConfigurationPathParams
  >({
    url: '/master-configs/{masterConfigurationId}/accounts',
    method: 'get',
    ...variables,
    signal,
  });

export const getAccountsByMasterConfigurationQuery = (
  variables: GetAccountsByMasterConfigurationVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({
    signal,
  }: {
    signal?: AbortSignal;
  }) => Promise<GetAccountsByMasterConfigurationResponse>;
} => ({
  queryKey: queryKeyFn({
    path: '/master-configs/{masterConfigurationId}/accounts',
    operationId: 'getAccountsByMasterConfiguration',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetAccountsByMasterConfiguration({ ...variables }, signal),
});

export const useSuspenseGetAccountsByMasterConfiguration = <
  TData = GetAccountsByMasterConfigurationResponse,
>(
  variables: GetAccountsByMasterConfigurationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAccountsByMasterConfigurationResponse,
      GetAccountsByMasterConfigurationError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetAccountsByMasterConfigurationResponse,
    GetAccountsByMasterConfigurationError,
    TData
  >({
    ...getAccountsByMasterConfigurationQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetAccountsByMasterConfiguration = <
  TData = GetAccountsByMasterConfigurationResponse,
>(
  variables: GetAccountsByMasterConfigurationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAccountsByMasterConfigurationResponse,
      GetAccountsByMasterConfigurationError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    GetAccountsByMasterConfigurationResponse,
    GetAccountsByMasterConfigurationError,
    TData
  >({
    ...getAccountsByMasterConfigurationQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface GetConfigPathParams {
  masterConfigId: string;
}

export type GetConfigError = Fetcher.ErrorWrapper<undefined>;

export type GetConfigVariables = {
  pathParams: GetConfigPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetConfig = (variables: GetConfigVariables, signal?: AbortSignal) =>
  diceFetch<
    Schemas.CostTypeAccountConfigDTO,
    GetConfigError,
    undefined,
    {},
    {},
    GetConfigPathParams
  >({
    url: '/master-configs/{masterConfigId}/cost-type-accounts',
    method: 'get',
    ...variables,
    signal,
  });

export const getConfigQuery = (
  variables: GetConfigVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<Schemas.CostTypeAccountConfigDTO>;
} => ({
  queryKey: queryKeyFn({
    path: '/master-configs/{masterConfigId}/cost-type-accounts',
    operationId: 'getConfig',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) => fetchGetConfig({ ...variables }, signal),
});

export const useSuspenseGetConfig = <TData = Schemas.CostTypeAccountConfigDTO>(
  variables: GetConfigVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.CostTypeAccountConfigDTO, GetConfigError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<Schemas.CostTypeAccountConfigDTO, GetConfigError, TData>({
    ...getConfigQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetConfig = <TData = Schemas.CostTypeAccountConfigDTO>(
  variables: GetConfigVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.CostTypeAccountConfigDTO, GetConfigError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<Schemas.CostTypeAccountConfigDTO, GetConfigError, TData>({
    ...getConfigQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface DeleteConfigsPathParams {
  masterConfigId: string;
}

export type DeleteConfigsError = Fetcher.ErrorWrapper<undefined>;

export type DeleteConfigsVariables = {
  pathParams: DeleteConfigsPathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeleteConfigs = (variables: DeleteConfigsVariables, signal?: AbortSignal) =>
  diceFetch<undefined, DeleteConfigsError, undefined, {}, {}, DeleteConfigsPathParams>({
    url: '/master-configs/{masterConfigId}/cost-type-accounts',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteConfigs = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteConfigsError, DeleteConfigsVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, DeleteConfigsError, DeleteConfigsVariables>({
    mutationFn: (variables: DeleteConfigsVariables) =>
      fetchDeleteConfigs({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetIndexFactorsAllYearsError = Fetcher.ErrorWrapper<undefined>;

export type GetIndexFactorsAllYearsResponse = Schemas.IndexFactorYear[];

export type GetIndexFactorsAllYearsVariables = DiceContext['fetcherOptions'];

export const fetchGetIndexFactorsAllYears = (
  variables: GetIndexFactorsAllYearsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<GetIndexFactorsAllYearsResponse, GetIndexFactorsAllYearsError, undefined, {}, {}, {}>({
    url: '/index-factors/years',
    method: 'get',
    ...variables,
    signal,
  });

export const getIndexFactorsAllYearsQuery = (
  variables: GetIndexFactorsAllYearsVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<GetIndexFactorsAllYearsResponse>;
} => ({
  queryKey: queryKeyFn({
    path: '/index-factors/years',
    operationId: 'getIndexFactorsAllYears',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetIndexFactorsAllYears({ ...variables }, signal),
});

export const useSuspenseGetIndexFactorsAllYears = <TData = GetIndexFactorsAllYearsResponse>(
  variables: GetIndexFactorsAllYearsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetIndexFactorsAllYearsResponse,
      GetIndexFactorsAllYearsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetIndexFactorsAllYearsResponse,
    GetIndexFactorsAllYearsError,
    TData
  >({
    ...getIndexFactorsAllYearsQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetIndexFactorsAllYears = <TData = GetIndexFactorsAllYearsResponse>(
  variables: GetIndexFactorsAllYearsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetIndexFactorsAllYearsResponse,
      GetIndexFactorsAllYearsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<GetIndexFactorsAllYearsResponse, GetIndexFactorsAllYearsError, TData>({
    ...getIndexFactorsAllYearsQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface GetIndexFactorForYearAndFieldPathParams {
  configYear: string;
  distinctByField: string;
}

export type GetIndexFactorForYearAndFieldError = Fetcher.ErrorWrapper<undefined>;

export type GetIndexFactorForYearAndFieldResponse = Schemas.IndexFactor[];

export type GetIndexFactorForYearAndFieldVariables = {
  pathParams: GetIndexFactorForYearAndFieldPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetIndexFactorForYearAndField = (
  variables: GetIndexFactorForYearAndFieldVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetIndexFactorForYearAndFieldResponse,
    GetIndexFactorForYearAndFieldError,
    undefined,
    {},
    {},
    GetIndexFactorForYearAndFieldPathParams
  >({
    url: '/index-factors/years/{configYear}/distinct-field/{distinctByField}',
    method: 'get',
    ...variables,
    signal,
  });

export const getIndexFactorForYearAndFieldQuery = (
  variables: GetIndexFactorForYearAndFieldVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<GetIndexFactorForYearAndFieldResponse>;
} => ({
  queryKey: queryKeyFn({
    path: '/index-factors/years/{configYear}/distinct-field/{distinctByField}',
    operationId: 'getIndexFactorForYearAndField',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetIndexFactorForYearAndField({ ...variables }, signal),
});

export const useSuspenseGetIndexFactorForYearAndField = <
  TData = GetIndexFactorForYearAndFieldResponse,
>(
  variables: GetIndexFactorForYearAndFieldVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetIndexFactorForYearAndFieldResponse,
      GetIndexFactorForYearAndFieldError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetIndexFactorForYearAndFieldResponse,
    GetIndexFactorForYearAndFieldError,
    TData
  >({
    ...getIndexFactorForYearAndFieldQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetIndexFactorForYearAndField = <TData = GetIndexFactorForYearAndFieldResponse>(
  variables: GetIndexFactorForYearAndFieldVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetIndexFactorForYearAndFieldResponse,
      GetIndexFactorForYearAndFieldError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    GetIndexFactorForYearAndFieldResponse,
    GetIndexFactorForYearAndFieldError,
    TData
  >({
    ...getIndexFactorForYearAndFieldQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface GetIndexFactorYearsPathParams {
  onlyYears: string;
}

export type GetIndexFactorYearsError = Fetcher.ErrorWrapper<undefined>;

export type GetIndexFactorYearsResponse = string[];

export type GetIndexFactorYearsVariables = {
  pathParams: GetIndexFactorYearsPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetIndexFactorYears = (
  variables: GetIndexFactorYearsVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetIndexFactorYearsResponse,
    GetIndexFactorYearsError,
    undefined,
    {},
    {},
    GetIndexFactorYearsPathParams
  >({ url: '/index-factors/only-years/{onlyYears}', method: 'get', ...variables, signal });

export const getIndexFactorYearsQuery = (
  variables: GetIndexFactorYearsVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<GetIndexFactorYearsResponse>;
} => ({
  queryKey: queryKeyFn({
    path: '/index-factors/only-years/{onlyYears}',
    operationId: 'getIndexFactorYears',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetIndexFactorYears({ ...variables }, signal),
});

export const useSuspenseGetIndexFactorYears = <TData = GetIndexFactorYearsResponse>(
  variables: GetIndexFactorYearsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetIndexFactorYearsResponse, GetIndexFactorYearsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<GetIndexFactorYearsResponse, GetIndexFactorYearsError, TData>({
    ...getIndexFactorYearsQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetIndexFactorYears = <TData = GetIndexFactorYearsResponse>(
  variables: GetIndexFactorYearsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetIndexFactorYearsResponse, GetIndexFactorYearsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<GetIndexFactorYearsResponse, GetIndexFactorYearsError, TData>({
    ...getIndexFactorYearsQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export type GetIndexFactorDataSourcesError = Fetcher.ErrorWrapper<undefined>;

export type GetIndexFactorDataSourcesResponse = ('WIBERA' | 'OTHERS')[];

export type GetIndexFactorDataSourcesVariables = DiceContext['fetcherOptions'];

export const fetchGetIndexFactorDataSources = (
  variables: GetIndexFactorDataSourcesVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetIndexFactorDataSourcesResponse,
    GetIndexFactorDataSourcesError,
    undefined,
    {},
    {},
    {}
  >({ url: '/index-factors/data-sources', method: 'get', ...variables, signal });

export const getIndexFactorDataSourcesQuery = (
  variables: GetIndexFactorDataSourcesVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<GetIndexFactorDataSourcesResponse>;
} => ({
  queryKey: queryKeyFn({
    path: '/index-factors/data-sources',
    operationId: 'getIndexFactorDataSources',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetIndexFactorDataSources({ ...variables }, signal),
});

export const useSuspenseGetIndexFactorDataSources = <TData = GetIndexFactorDataSourcesResponse>(
  variables: GetIndexFactorDataSourcesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetIndexFactorDataSourcesResponse,
      GetIndexFactorDataSourcesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetIndexFactorDataSourcesResponse,
    GetIndexFactorDataSourcesError,
    TData
  >({
    ...getIndexFactorDataSourcesQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetIndexFactorDataSources = <TData = GetIndexFactorDataSourcesResponse>(
  variables: GetIndexFactorDataSourcesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetIndexFactorDataSourcesResponse,
      GetIndexFactorDataSourcesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    GetIndexFactorDataSourcesResponse,
    GetIndexFactorDataSourcesError,
    TData
  >({
    ...getIndexFactorDataSourcesQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface GetImportLedgerAccountFiltersQueryParams {
  esraClientId: string;
  yearCalculationId: string;
  /**
   * @minimum 1900
   * @format int32
   */
  calculationYear: number;
  erpType: string;
}

export type GetImportLedgerAccountFiltersError = Fetcher.ErrorWrapper<undefined>;

export type GetImportLedgerAccountFiltersVariables = {
  queryParams: GetImportLedgerAccountFiltersQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchGetImportLedgerAccountFilters = (
  variables: GetImportLedgerAccountFiltersVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.ImportLedgerFilteringGroupResponse,
    GetImportLedgerAccountFiltersError,
    undefined,
    {},
    GetImportLedgerAccountFiltersQueryParams,
    {}
  >({ url: '/imports/ledger-account-filters', method: 'get', ...variables, signal });

export const getImportLedgerAccountFiltersQuery = (
  variables: GetImportLedgerAccountFiltersVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({
    signal,
  }: {
    signal?: AbortSignal;
  }) => Promise<Schemas.ImportLedgerFilteringGroupResponse>;
} => ({
  queryKey: queryKeyFn({
    path: '/imports/ledger-account-filters',
    operationId: 'getImportLedgerAccountFilters',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetImportLedgerAccountFilters({ ...variables }, signal),
});

export const useSuspenseGetImportLedgerAccountFilters = <
  TData = Schemas.ImportLedgerFilteringGroupResponse,
>(
  variables: GetImportLedgerAccountFiltersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ImportLedgerFilteringGroupResponse,
      GetImportLedgerAccountFiltersError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    Schemas.ImportLedgerFilteringGroupResponse,
    GetImportLedgerAccountFiltersError,
    TData
  >({
    ...getImportLedgerAccountFiltersQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetImportLedgerAccountFilters = <
  TData = Schemas.ImportLedgerFilteringGroupResponse,
>(
  variables: GetImportLedgerAccountFiltersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ImportLedgerFilteringGroupResponse,
      GetImportLedgerAccountFiltersError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    Schemas.ImportLedgerFilteringGroupResponse,
    GetImportLedgerAccountFiltersError,
    TData
  >({
    ...getImportLedgerAccountFiltersQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface GetImportJournalFiltersQueryParams {
  esraClientId: string;
  yearCalculationId: string;
  /**
   * @minimum 1900
   * @format int32
   */
  calculationYear: number;
  isPostCalculation: boolean;
  erpType: string;
}

export type GetImportJournalFiltersError = Fetcher.ErrorWrapper<undefined>;

export type GetImportJournalFiltersVariables = {
  queryParams: GetImportJournalFiltersQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchGetImportJournalFilters = (
  variables: GetImportJournalFiltersVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.ImportJournalFilteringGroupResponse,
    GetImportJournalFiltersError,
    undefined,
    {},
    GetImportJournalFiltersQueryParams,
    {}
  >({ url: '/imports/journal-filters', method: 'get', ...variables, signal });

export const getImportJournalFiltersQuery = (
  variables: GetImportJournalFiltersVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({
    signal,
  }: {
    signal?: AbortSignal;
  }) => Promise<Schemas.ImportJournalFilteringGroupResponse>;
} => ({
  queryKey: queryKeyFn({
    path: '/imports/journal-filters',
    operationId: 'getImportJournalFilters',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetImportJournalFilters({ ...variables }, signal),
});

export const useSuspenseGetImportJournalFilters = <
  TData = Schemas.ImportJournalFilteringGroupResponse,
>(
  variables: GetImportJournalFiltersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ImportJournalFilteringGroupResponse,
      GetImportJournalFiltersError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    Schemas.ImportJournalFilteringGroupResponse,
    GetImportJournalFiltersError,
    TData
  >({
    ...getImportJournalFiltersQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetImportJournalFilters = <TData = Schemas.ImportJournalFilteringGroupResponse>(
  variables: GetImportJournalFiltersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ImportJournalFilteringGroupResponse,
      GetImportJournalFiltersError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    Schemas.ImportJournalFilteringGroupResponse,
    GetImportJournalFiltersError,
    TData
  >({
    ...getImportJournalFiltersQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export type GetErpTypeError = Fetcher.ErrorWrapper<undefined>;

export type GetErpTypeResponse = ('SAP_ECC6' | 'SAP_S4' | 'NAVISION_365_BC' | 'KVASY')[];

export type GetErpTypeVariables = DiceContext['fetcherOptions'];

export const fetchGetErpType = (variables: GetErpTypeVariables, signal?: AbortSignal) =>
  diceFetch<GetErpTypeResponse, GetErpTypeError, undefined, {}, {}, {}>({
    url: '/imports/erpType',
    method: 'get',
    ...variables,
    signal,
  });

export const getErpTypeQuery = (
  variables: GetErpTypeVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<GetErpTypeResponse>;
} => ({
  queryKey: queryKeyFn({
    path: '/imports/erpType',
    operationId: 'getErpType',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) => fetchGetErpType({ ...variables }, signal),
});

export const useSuspenseGetErpType = <TData = GetErpTypeResponse>(
  variables: GetErpTypeVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetErpTypeResponse, GetErpTypeError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<GetErpTypeResponse, GetErpTypeError, TData>({
    ...getErpTypeQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetErpType = <TData = GetErpTypeResponse>(
  variables: GetErpTypeVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetErpTypeResponse, GetErpTypeError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<GetErpTypeResponse, GetErpTypeError, TData>({
    ...getErpTypeQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface GetImportAssetFiltersQueryParams {
  esraClientId: string;
  yearCalculationId: string;
  /**
   * @minimum 1900
   * @format int32
   */
  calculationYear: number;
  erpType: string;
}

export type GetImportAssetFiltersError = Fetcher.ErrorWrapper<undefined>;

export type GetImportAssetFiltersVariables = {
  queryParams: GetImportAssetFiltersQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchGetImportAssetFilters = (
  variables: GetImportAssetFiltersVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.ImportAssetFilteringGroupResponse,
    GetImportAssetFiltersError,
    undefined,
    {},
    GetImportAssetFiltersQueryParams,
    {}
  >({ url: '/imports/asset-filters', method: 'get', ...variables, signal });

export const getImportAssetFiltersQuery = (
  variables: GetImportAssetFiltersVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({
    signal,
  }: {
    signal?: AbortSignal;
  }) => Promise<Schemas.ImportAssetFilteringGroupResponse>;
} => ({
  queryKey: queryKeyFn({
    path: '/imports/asset-filters',
    operationId: 'getImportAssetFilters',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetImportAssetFilters({ ...variables }, signal),
});

export const useSuspenseGetImportAssetFilters = <TData = Schemas.ImportAssetFilteringGroupResponse>(
  variables: GetImportAssetFiltersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ImportAssetFilteringGroupResponse,
      GetImportAssetFiltersError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    Schemas.ImportAssetFilteringGroupResponse,
    GetImportAssetFiltersError,
    TData
  >({
    ...getImportAssetFiltersQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetImportAssetFilters = <TData = Schemas.ImportAssetFilteringGroupResponse>(
  variables: GetImportAssetFiltersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ImportAssetFilteringGroupResponse,
      GetImportAssetFiltersError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    Schemas.ImportAssetFilteringGroupResponse,
    GetImportAssetFiltersError,
    TData
  >({
    ...getImportAssetFiltersQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export type HealthcheckError = Fetcher.ErrorWrapper<undefined>;

export type HealthcheckVariables = DiceContext['fetcherOptions'];

export const fetchHealthcheck = (variables: HealthcheckVariables, signal?: AbortSignal) =>
  diceFetch<string, HealthcheckError, undefined, {}, {}, {}>({
    url: '/healthcheck',
    method: 'get',
    ...variables,
    signal,
  });

export const healthcheckQuery = (
  variables: HealthcheckVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<string>;
} => ({
  queryKey: queryKeyFn({
    path: '/healthcheck',
    operationId: 'healthcheck',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) => fetchHealthcheck({ ...variables }, signal),
});

export const useSuspenseHealthcheck = <TData = string>(
  variables: HealthcheckVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<string, HealthcheckError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<string, HealthcheckError, TData>({
    ...healthcheckQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useHealthcheck = <TData = string>(
  variables: HealthcheckVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<string, HealthcheckError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<string, HealthcheckError, TData>({
    ...healthcheckQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface GetFileQueryParams {
  fileType: 'ASSETS' | 'LABELS' | 'LEDGER_ACCOUNT' | 'JOURNAL' | 'ACCOUNT_PLAN';
  yearId: string;
}

export type GetFileError = Fetcher.ErrorWrapper<undefined>;

export type GetFileVariables = {
  queryParams: GetFileQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchGetFile = (variables: GetFileVariables, signal?: AbortSignal) =>
  diceFetch<Schemas.FileResponse, GetFileError, undefined, {}, GetFileQueryParams, {}>({
    url: '/files',
    method: 'get',
    ...variables,
    signal,
  });

export const getFileQuery = (
  variables: GetFileVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<Schemas.FileResponse>;
} => ({
  queryKey: queryKeyFn({
    path: '/files',
    operationId: 'getFile',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) => fetchGetFile({ ...variables }, signal),
});

export const useSuspenseGetFile = <TData = Schemas.FileResponse>(
  variables: GetFileVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.FileResponse, GetFileError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<Schemas.FileResponse, GetFileError, TData>({
    ...getFileQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetFile = <TData = Schemas.FileResponse>(
  variables: GetFileVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.FileResponse, GetFileError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<Schemas.FileResponse, GetFileError, TData>({
    ...getFileQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export type GetEsraExceptionError = Fetcher.ErrorWrapper<undefined>;

export type GetEsraExceptionVariables = DiceContext['fetcherOptions'];

export const fetchGetEsraException = (variables: GetEsraExceptionVariables, signal?: AbortSignal) =>
  diceFetch<Schemas.EsraException, GetEsraExceptionError, undefined, {}, {}, {}>({
    url: '/exception',
    method: 'get',
    ...variables,
    signal,
  });

export const getEsraExceptionQuery = (
  variables: GetEsraExceptionVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<Schemas.EsraException>;
} => ({
  queryKey: queryKeyFn({
    path: '/exception',
    operationId: 'getEsraException',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetEsraException({ ...variables }, signal),
});

export const useSuspenseGetEsraException = <TData = Schemas.EsraException>(
  variables: GetEsraExceptionVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.EsraException, GetEsraExceptionError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<Schemas.EsraException, GetEsraExceptionError, TData>({
    ...getEsraExceptionQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetEsraException = <TData = Schemas.EsraException>(
  variables: GetEsraExceptionVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.EsraException, GetEsraExceptionError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<Schemas.EsraException, GetEsraExceptionError, TData>({
    ...getEsraExceptionQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface GetBasicFeeConfigPathParams {
  yearId: string;
  masterConfigId: string;
  costUnitShortName: string;
}

export type GetBasicFeeConfigError = Fetcher.ErrorWrapper<undefined>;

export type GetBasicFeeConfigVariables = {
  pathParams: GetBasicFeeConfigPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetBasicFeeConfig = (
  variables: GetBasicFeeConfigVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.CostUnitBasicFeeDTO,
    GetBasicFeeConfigError,
    undefined,
    {},
    {},
    GetBasicFeeConfigPathParams
  >({
    url: '/cost-unit/basic-fee/years/{yearId}/master-configs/{masterConfigId}/cost-unit/{costUnitShortName}',
    method: 'get',
    ...variables,
    signal,
  });

export const getBasicFeeConfigQuery = (
  variables: GetBasicFeeConfigVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<Schemas.CostUnitBasicFeeDTO>;
} => ({
  queryKey: queryKeyFn({
    path: '/cost-unit/basic-fee/years/{yearId}/master-configs/{masterConfigId}/cost-unit/{costUnitShortName}',
    operationId: 'getBasicFeeConfig',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetBasicFeeConfig({ ...variables }, signal),
});

export const useSuspenseGetBasicFeeConfig = <TData = Schemas.CostUnitBasicFeeDTO>(
  variables: GetBasicFeeConfigVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.CostUnitBasicFeeDTO, GetBasicFeeConfigError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<Schemas.CostUnitBasicFeeDTO, GetBasicFeeConfigError, TData>({
    ...getBasicFeeConfigQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetBasicFeeConfig = <TData = Schemas.CostUnitBasicFeeDTO>(
  variables: GetBasicFeeConfigVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.CostUnitBasicFeeDTO, GetBasicFeeConfigError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<Schemas.CostUnitBasicFeeDTO, GetBasicFeeConfigError, TData>({
    ...getBasicFeeConfigQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface GetClientPathParams {
  name: string;
}

export interface GetClientHeaders {
  authorization?: string;
}

export type GetClientError = Fetcher.ErrorWrapper<undefined>;

export type GetClientVariables = {
  headers?: GetClientHeaders;
  pathParams: GetClientPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetClient = (variables: GetClientVariables, signal?: AbortSignal) =>
  diceFetch<
    Schemas.ClientDTO,
    GetClientError,
    undefined,
    GetClientHeaders,
    {},
    GetClientPathParams
  >({ url: '/clients/{name}', method: 'get', ...variables, signal });

export const getClientQuery = (
  variables: GetClientVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<Schemas.ClientDTO>;
} => ({
  queryKey: queryKeyFn({
    path: '/clients/{name}',
    operationId: 'getClient',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) => fetchGetClient({ ...variables }, signal),
});

export const useSuspenseGetClient = <TData = Schemas.ClientDTO>(
  variables: GetClientVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ClientDTO, GetClientError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<Schemas.ClientDTO, GetClientError, TData>({
    ...getClientQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetClient = <TData = Schemas.ClientDTO>(
  variables: GetClientVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ClientDTO, GetClientError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<Schemas.ClientDTO, GetClientError, TData>({
    ...getClientQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface GetPublicFacilityPathParams {
  clientId: string;
  publicFacilityId: string;
}

export interface GetPublicFacilityHeaders {
  authorization?: string;
}

export type GetPublicFacilityError = Fetcher.ErrorWrapper<undefined>;

export type GetPublicFacilityVariables = {
  headers?: GetPublicFacilityHeaders;
  pathParams: GetPublicFacilityPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetPublicFacility = (
  variables: GetPublicFacilityVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.PublicFacilityDTO,
    GetPublicFacilityError,
    undefined,
    GetPublicFacilityHeaders,
    {},
    GetPublicFacilityPathParams
  >({
    url: '/clients/{clientId}/public-facilities/{publicFacilityId}',
    method: 'get',
    ...variables,
    signal,
  });

export const getPublicFacilityQuery = (
  variables: GetPublicFacilityVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<Schemas.PublicFacilityDTO>;
} => ({
  queryKey: queryKeyFn({
    path: '/clients/{clientId}/public-facilities/{publicFacilityId}',
    operationId: 'getPublicFacility',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetPublicFacility({ ...variables }, signal),
});

export const useSuspenseGetPublicFacility = <TData = Schemas.PublicFacilityDTO>(
  variables: GetPublicFacilityVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.PublicFacilityDTO, GetPublicFacilityError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<Schemas.PublicFacilityDTO, GetPublicFacilityError, TData>({
    ...getPublicFacilityQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetPublicFacility = <TData = Schemas.PublicFacilityDTO>(
  variables: GetPublicFacilityVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.PublicFacilityDTO, GetPublicFacilityError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<Schemas.PublicFacilityDTO, GetPublicFacilityError, TData>({
    ...getPublicFacilityQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface DeletePublicFacilityPathParams {
  clientId: string;
  publicFacilityId: string;
}

export interface DeletePublicFacilityHeaders {
  authorization?: string;
}

export type DeletePublicFacilityError = Fetcher.ErrorWrapper<undefined>;

export type DeletePublicFacilityVariables = {
  headers?: DeletePublicFacilityHeaders;
  pathParams: DeletePublicFacilityPathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeletePublicFacility = (
  variables: DeletePublicFacilityVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    DeletePublicFacilityError,
    undefined,
    DeletePublicFacilityHeaders,
    {},
    DeletePublicFacilityPathParams
  >({
    url: '/clients/{clientId}/public-facilities/{publicFacilityId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeletePublicFacility = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeletePublicFacilityError,
      DeletePublicFacilityVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    DeletePublicFacilityError,
    DeletePublicFacilityVariables
  >({
    mutationFn: (variables: DeletePublicFacilityVariables) =>
      fetchDeletePublicFacility({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetAllDistinctAssetCostCentersQueryParams {
  yearId: string;
  masterConfigurationId?: string;
  includeForecastAssets?: boolean;
}

export type GetAllDistinctAssetCostCentersError = Fetcher.ErrorWrapper<undefined>;

export type GetAllDistinctAssetCostCentersResponse = string[];

export type GetAllDistinctAssetCostCentersVariables = {
  queryParams: GetAllDistinctAssetCostCentersQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchGetAllDistinctAssetCostCenters = (
  variables: GetAllDistinctAssetCostCentersVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetAllDistinctAssetCostCentersResponse,
    GetAllDistinctAssetCostCentersError,
    undefined,
    {},
    GetAllDistinctAssetCostCentersQueryParams,
    {}
  >({ url: '/assets_costcenters', method: 'get', ...variables, signal });

export const getAllDistinctAssetCostCentersQuery = (
  variables: GetAllDistinctAssetCostCentersVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({
    signal,
  }: {
    signal?: AbortSignal;
  }) => Promise<GetAllDistinctAssetCostCentersResponse>;
} => ({
  queryKey: queryKeyFn({
    path: '/assets_costcenters',
    operationId: 'getAllDistinctAssetCostCenters',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetAllDistinctAssetCostCenters({ ...variables }, signal),
});

export const useSuspenseGetAllDistinctAssetCostCenters = <
  TData = GetAllDistinctAssetCostCentersResponse,
>(
  variables: GetAllDistinctAssetCostCentersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAllDistinctAssetCostCentersResponse,
      GetAllDistinctAssetCostCentersError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetAllDistinctAssetCostCentersResponse,
    GetAllDistinctAssetCostCentersError,
    TData
  >({
    ...getAllDistinctAssetCostCentersQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetAllDistinctAssetCostCenters = <TData = GetAllDistinctAssetCostCentersResponse>(
  variables: GetAllDistinctAssetCostCentersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAllDistinctAssetCostCentersResponse,
      GetAllDistinctAssetCostCentersError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    GetAllDistinctAssetCostCentersResponse,
    GetAllDistinctAssetCostCentersError,
    TData
  >({
    ...getAllDistinctAssetCostCentersQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface GetAssetCategoryToAssetNbrWithDescriptionQueryParams {
  yearId: string;
  masterConfigurationId?: string;
  includeForecastAssets?: boolean;
}

export type GetAssetCategoryToAssetNbrWithDescriptionError = Fetcher.ErrorWrapper<undefined>;

export type GetAssetCategoryToAssetNbrWithDescriptionResponse = Schemas.AssetCategory[];

export type GetAssetCategoryToAssetNbrWithDescriptionVariables = {
  queryParams: GetAssetCategoryToAssetNbrWithDescriptionQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchGetAssetCategoryToAssetNbrWithDescription = (
  variables: GetAssetCategoryToAssetNbrWithDescriptionVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetAssetCategoryToAssetNbrWithDescriptionResponse,
    GetAssetCategoryToAssetNbrWithDescriptionError,
    undefined,
    {},
    GetAssetCategoryToAssetNbrWithDescriptionQueryParams,
    {}
  >({ url: '/assets_categories/numbers/description', method: 'get', ...variables, signal });

export const getAssetCategoryToAssetNbrWithDescriptionQuery = (
  variables: GetAssetCategoryToAssetNbrWithDescriptionVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({
    signal,
  }: {
    signal?: AbortSignal;
  }) => Promise<GetAssetCategoryToAssetNbrWithDescriptionResponse>;
} => ({
  queryKey: queryKeyFn({
    path: '/assets_categories/numbers/description',
    operationId: 'getAssetCategoryToAssetNbrWithDescription',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetAssetCategoryToAssetNbrWithDescription({ ...variables }, signal),
});

export const useSuspenseGetAssetCategoryToAssetNbrWithDescription = <
  TData = GetAssetCategoryToAssetNbrWithDescriptionResponse,
>(
  variables: GetAssetCategoryToAssetNbrWithDescriptionVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAssetCategoryToAssetNbrWithDescriptionResponse,
      GetAssetCategoryToAssetNbrWithDescriptionError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetAssetCategoryToAssetNbrWithDescriptionResponse,
    GetAssetCategoryToAssetNbrWithDescriptionError,
    TData
  >({
    ...getAssetCategoryToAssetNbrWithDescriptionQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetAssetCategoryToAssetNbrWithDescription = <
  TData = GetAssetCategoryToAssetNbrWithDescriptionResponse,
>(
  variables: GetAssetCategoryToAssetNbrWithDescriptionVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAssetCategoryToAssetNbrWithDescriptionResponse,
      GetAssetCategoryToAssetNbrWithDescriptionError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    GetAssetCategoryToAssetNbrWithDescriptionResponse,
    GetAssetCategoryToAssetNbrWithDescriptionError,
    TData
  >({
    ...getAssetCategoryToAssetNbrWithDescriptionQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface GetAssetCategoryDescriptionQueryParams {
  yearId: string;
  masterConfigurationId?: string;
  includeForecastAssets?: boolean;
}

export type GetAssetCategoryDescriptionError = Fetcher.ErrorWrapper<undefined>;

export type GetAssetCategoryDescriptionResponse = Record<string, string>;

export type GetAssetCategoryDescriptionVariables = {
  queryParams: GetAssetCategoryDescriptionQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchGetAssetCategoryDescription = (
  variables: GetAssetCategoryDescriptionVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetAssetCategoryDescriptionResponse,
    GetAssetCategoryDescriptionError,
    undefined,
    {},
    GetAssetCategoryDescriptionQueryParams,
    {}
  >({ url: '/assets_categories/description', method: 'get', ...variables, signal });

export const getAssetCategoryDescriptionQuery = (
  variables: GetAssetCategoryDescriptionVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<GetAssetCategoryDescriptionResponse>;
} => ({
  queryKey: queryKeyFn({
    path: '/assets_categories/description',
    operationId: 'getAssetCategoryDescription',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetAssetCategoryDescription({ ...variables }, signal),
});

export const useSuspenseGetAssetCategoryDescription = <TData = GetAssetCategoryDescriptionResponse>(
  variables: GetAssetCategoryDescriptionVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAssetCategoryDescriptionResponse,
      GetAssetCategoryDescriptionError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetAssetCategoryDescriptionResponse,
    GetAssetCategoryDescriptionError,
    TData
  >({
    ...getAssetCategoryDescriptionQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetAssetCategoryDescription = <TData = GetAssetCategoryDescriptionResponse>(
  variables: GetAssetCategoryDescriptionVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAssetCategoryDescriptionResponse,
      GetAssetCategoryDescriptionError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    GetAssetCategoryDescriptionResponse,
    GetAssetCategoryDescriptionError,
    TData
  >({
    ...getAssetCategoryDescriptionQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface GetAllAssetsWithPagerQueryParams {
  yearId: string;
  /**
   * @format int32
   */
  page: number;
  /**
   * @format int32
   */
  pageSize: number;
}

export type GetAllAssetsWithPagerError = Fetcher.ErrorWrapper<undefined>;

export type GetAllAssetsWithPagerVariables = {
  queryParams: GetAllAssetsWithPagerQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchGetAllAssetsWithPager = (
  variables: GetAllAssetsWithPagerVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.PageAsset,
    GetAllAssetsWithPagerError,
    undefined,
    {},
    GetAllAssetsWithPagerQueryParams,
    {}
  >({ url: '/assets/page', method: 'get', ...variables, signal });

export const getAllAssetsWithPagerQuery = (
  variables: GetAllAssetsWithPagerVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<Schemas.PageAsset>;
} => ({
  queryKey: queryKeyFn({
    path: '/assets/page',
    operationId: 'getAllAssetsWithPager',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetAllAssetsWithPager({ ...variables }, signal),
});

export const useSuspenseGetAllAssetsWithPager = <TData = Schemas.PageAsset>(
  variables: GetAllAssetsWithPagerVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.PageAsset, GetAllAssetsWithPagerError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<Schemas.PageAsset, GetAllAssetsWithPagerError, TData>({
    ...getAllAssetsWithPagerQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetAllAssetsWithPager = <TData = Schemas.PageAsset>(
  variables: GetAllAssetsWithPagerVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.PageAsset, GetAllAssetsWithPagerError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<Schemas.PageAsset, GetAllAssetsWithPagerError, TData>({
    ...getAllAssetsWithPagerQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface GetAllDistinctAssetCategoriesQueryParams {
  yearId: string;
  masterConfigurationId?: string;
  includeForecastAssets?: boolean;
}

export type GetAllDistinctAssetCategoriesError = Fetcher.ErrorWrapper<undefined>;

export type GetAllDistinctAssetCategoriesResponse = string[];

export type GetAllDistinctAssetCategoriesVariables = {
  queryParams: GetAllDistinctAssetCategoriesQueryParams;
} & DiceContext['fetcherOptions'];

export const fetchGetAllDistinctAssetCategories = (
  variables: GetAllDistinctAssetCategoriesVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    GetAllDistinctAssetCategoriesResponse,
    GetAllDistinctAssetCategoriesError,
    undefined,
    {},
    GetAllDistinctAssetCategoriesQueryParams,
    {}
  >({ url: '/asset_categories', method: 'get', ...variables, signal });

export const getAllDistinctAssetCategoriesQuery = (
  variables: GetAllDistinctAssetCategoriesVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<GetAllDistinctAssetCategoriesResponse>;
} => ({
  queryKey: queryKeyFn({
    path: '/asset_categories',
    operationId: 'getAllDistinctAssetCategories',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetAllDistinctAssetCategories({ ...variables }, signal),
});

export const useSuspenseGetAllDistinctAssetCategories = <
  TData = GetAllDistinctAssetCategoriesResponse,
>(
  variables: GetAllDistinctAssetCategoriesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAllDistinctAssetCategoriesResponse,
      GetAllDistinctAssetCategoriesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    GetAllDistinctAssetCategoriesResponse,
    GetAllDistinctAssetCategoriesError,
    TData
  >({
    ...getAllDistinctAssetCategoriesQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetAllDistinctAssetCategories = <TData = GetAllDistinctAssetCategoriesResponse>(
  variables: GetAllDistinctAssetCategoriesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAllDistinctAssetCategoriesResponse,
      GetAllDistinctAssetCategoriesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    GetAllDistinctAssetCategoriesResponse,
    GetAllDistinctAssetCategoriesError,
    TData
  >({
    ...getAllDistinctAssetCategoriesQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface FindGroupsPathParams {
  masterConfigurationId: string;
}

export type FindGroupsError = Fetcher.ErrorWrapper<undefined>;

export type FindGroupsResponse = Schemas.AssetGroupDTO[];

export type FindGroupsVariables = {
  pathParams: FindGroupsPathParams;
} & DiceContext['fetcherOptions'];

export const fetchFindGroups = (variables: FindGroupsVariables, signal?: AbortSignal) =>
  diceFetch<FindGroupsResponse, FindGroupsError, undefined, {}, {}, FindGroupsPathParams>({
    url: '/asset-group/masterConfiguration/{masterConfigurationId}',
    method: 'get',
    ...variables,
    signal,
  });

export const findGroupsQuery = (
  variables: FindGroupsVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<FindGroupsResponse>;
} => ({
  queryKey: queryKeyFn({
    path: '/asset-group/masterConfiguration/{masterConfigurationId}',
    operationId: 'findGroups',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) => fetchFindGroups({ ...variables }, signal),
});

export const useSuspenseFindGroups = <TData = FindGroupsResponse>(
  variables: FindGroupsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<FindGroupsResponse, FindGroupsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<FindGroupsResponse, FindGroupsError, TData>({
    ...findGroupsQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useFindGroups = <TData = FindGroupsResponse>(
  variables: FindGroupsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<FindGroupsResponse, FindGroupsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<FindGroupsResponse, FindGroupsError, TData>({
    ...findGroupsQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface GetAllocationKeyConfigPreselectedValuesPathParams {
  masterConfigurationId: string;
}

export type GetAllocationKeyConfigPreselectedValuesError = Fetcher.ErrorWrapper<undefined>;

export type GetAllocationKeyConfigPreselectedValuesVariables = {
  pathParams: GetAllocationKeyConfigPreselectedValuesPathParams;
} & DiceContext['fetcherOptions'];

export const fetchGetAllocationKeyConfigPreselectedValues = (
  variables: GetAllocationKeyConfigPreselectedValuesVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    Schemas.AllocationConfigPreselectedValuesDTO,
    GetAllocationKeyConfigPreselectedValuesError,
    undefined,
    {},
    {},
    GetAllocationKeyConfigPreselectedValuesPathParams
  >({
    url: '/allocation-key-config/preselected-values/master-configs/{masterConfigurationId}',
    method: 'get',
    ...variables,
    signal,
  });

export const getAllocationKeyConfigPreselectedValuesQuery = (
  variables: GetAllocationKeyConfigPreselectedValuesVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({
    signal,
  }: {
    signal?: AbortSignal;
  }) => Promise<Schemas.AllocationConfigPreselectedValuesDTO>;
} => ({
  queryKey: queryKeyFn({
    path: '/allocation-key-config/preselected-values/master-configs/{masterConfigurationId}',
    operationId: 'getAllocationKeyConfigPreselectedValues',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) =>
    fetchGetAllocationKeyConfigPreselectedValues({ ...variables }, signal),
});

export const useSuspenseGetAllocationKeyConfigPreselectedValues = <
  TData = Schemas.AllocationConfigPreselectedValuesDTO,
>(
  variables: GetAllocationKeyConfigPreselectedValuesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.AllocationConfigPreselectedValuesDTO,
      GetAllocationKeyConfigPreselectedValuesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useSuspenseQuery<
    Schemas.AllocationConfigPreselectedValuesDTO,
    GetAllocationKeyConfigPreselectedValuesError,
    TData
  >({
    ...getAllocationKeyConfigPreselectedValuesQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useGetAllocationKeyConfigPreselectedValues = <
  TData = Schemas.AllocationConfigPreselectedValuesDTO,
>(
  variables: GetAllocationKeyConfigPreselectedValuesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.AllocationConfigPreselectedValuesDTO,
      GetAllocationKeyConfigPreselectedValuesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useDiceContext(options);
  return reactQuery.useQuery<
    Schemas.AllocationConfigPreselectedValuesDTO,
    GetAllocationKeyConfigPreselectedValuesError,
    TData
  >({
    ...getAllocationKeyConfigPreselectedValuesQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export interface DeleteDataContainerPathParams {
  id: string;
}

export type DeleteDataContainerError = Fetcher.ErrorWrapper<undefined>;

export type DeleteDataContainerVariables = {
  pathParams: DeleteDataContainerPathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeleteDataContainer = (
  variables: DeleteDataContainerVariables,
  signal?: AbortSignal,
) =>
  diceFetch<undefined, DeleteDataContainerError, undefined, {}, {}, DeleteDataContainerPathParams>({
    url: '/years/{id}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteDataContainer = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteDataContainerError,
      DeleteDataContainerVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, DeleteDataContainerError, DeleteDataContainerVariables>({
    mutationFn: (variables: DeleteDataContainerVariables) =>
      fetchDeleteDataContainer({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface DeleteFileMetadata1PathParams {
  yearCalculationId: string;
  fileType: 'ASSETS' | 'LABELS' | 'LEDGER_ACCOUNT' | 'JOURNAL' | 'ACCOUNT_PLAN';
}

export type DeleteFileMetadata1Error = Fetcher.ErrorWrapper<undefined>;

export type DeleteFileMetadata1Variables = {
  pathParams: DeleteFileMetadata1PathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeleteFileMetadata1 = (
  variables: DeleteFileMetadata1Variables,
  signal?: AbortSignal,
) =>
  diceFetch<undefined, DeleteFileMetadata1Error, undefined, {}, {}, DeleteFileMetadata1PathParams>({
    url: '/year-calculations/{yearCalculationId}/files-metadata/type/{fileType}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteFileMetadata1 = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteFileMetadata1Error,
      DeleteFileMetadata1Variables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, DeleteFileMetadata1Error, DeleteFileMetadata1Variables>({
    mutationFn: (variables: DeleteFileMetadata1Variables) =>
      fetchDeleteFileMetadata1({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface DeleteYearPathParams {
  yearId: string;
}

export interface DeleteYearHeaders {
  authorization?: string;
}

export type DeleteYearError = Fetcher.ErrorWrapper<undefined>;

export type DeleteYearVariables = {
  headers?: DeleteYearHeaders;
  pathParams: DeleteYearPathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeleteYear = (variables: DeleteYearVariables, signal?: AbortSignal) =>
  diceFetch<undefined, DeleteYearError, undefined, DeleteYearHeaders, {}, DeleteYearPathParams>({
    url: '/v2/years/{yearId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteYear = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteYearError, DeleteYearVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, DeleteYearError, DeleteYearVariables>({
    mutationFn: (variables: DeleteYearVariables) =>
      fetchDeleteYear({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface DeletePriceGroupPathParams {
  priceGroupId: string;
}

export type DeletePriceGroupError = Fetcher.ErrorWrapper<undefined>;

export type DeletePriceGroupVariables = {
  pathParams: DeletePriceGroupPathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeletePriceGroup = (variables: DeletePriceGroupVariables, signal?: AbortSignal) =>
  diceFetch<undefined, DeletePriceGroupError, undefined, {}, {}, DeletePriceGroupPathParams>({
    url: '/price-groups/{priceGroupId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeletePriceGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeletePriceGroupError, DeletePriceGroupVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, DeletePriceGroupError, DeletePriceGroupVariables>({
    mutationFn: (variables: DeletePriceGroupVariables) =>
      fetchDeletePriceGroup({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface DeletePriceSubGroupPathParams {
  priceSubGroupId: string;
}

export type DeletePriceSubGroupError = Fetcher.ErrorWrapper<undefined>;

export type DeletePriceSubGroupVariables = {
  pathParams: DeletePriceSubGroupPathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeletePriceSubGroup = (
  variables: DeletePriceSubGroupVariables,
  signal?: AbortSignal,
) =>
  diceFetch<undefined, DeletePriceSubGroupError, undefined, {}, {}, DeletePriceSubGroupPathParams>({
    url: '/price-groups/sub-groups/{priceSubGroupId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeletePriceSubGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeletePriceSubGroupError,
      DeletePriceSubGroupVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, DeletePriceSubGroupError, DeletePriceSubGroupVariables>({
    mutationFn: (variables: DeletePriceSubGroupVariables) =>
      fetchDeletePriceSubGroup({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface DeletePriceSubGroupEntriesPathParams {
  priceSubGroupEntryId: string;
}

export type DeletePriceSubGroupEntriesError = Fetcher.ErrorWrapper<undefined>;

export type DeletePriceSubGroupEntriesVariables = {
  pathParams: DeletePriceSubGroupEntriesPathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeletePriceSubGroupEntries = (
  variables: DeletePriceSubGroupEntriesVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    DeletePriceSubGroupEntriesError,
    undefined,
    {},
    {},
    DeletePriceSubGroupEntriesPathParams
  >({
    url: '/price-groups/sub-groups/entries/{priceSubGroupEntryId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeletePriceSubGroupEntries = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeletePriceSubGroupEntriesError,
      DeletePriceSubGroupEntriesVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    DeletePriceSubGroupEntriesError,
    DeletePriceSubGroupEntriesVariables
  >({
    mutationFn: (variables: DeletePriceSubGroupEntriesVariables) =>
      fetchDeletePriceSubGroupEntries({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface DeleteTechnicalDataEntryPathParams {
  masterConfigurationId: string;
  ids: string[];
}

export type DeleteTechnicalDataEntryError = Fetcher.ErrorWrapper<undefined>;

export type DeleteTechnicalDataEntryVariables = {
  pathParams: DeleteTechnicalDataEntryPathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeleteTechnicalDataEntry = (
  variables: DeleteTechnicalDataEntryVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    DeleteTechnicalDataEntryError,
    undefined,
    {},
    {},
    DeleteTechnicalDataEntryPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/technical-data/{ids}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteTechnicalDataEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteTechnicalDataEntryError,
      DeleteTechnicalDataEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    DeleteTechnicalDataEntryError,
    DeleteTechnicalDataEntryVariables
  >({
    mutationFn: (variables: DeleteTechnicalDataEntryVariables) =>
      fetchDeleteTechnicalDataEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface DeleteBasicFeeGroupPathParams {
  groupId: string;
}

export type DeleteBasicFeeGroupError = Fetcher.ErrorWrapper<undefined>;

export type DeleteBasicFeeGroupVariables = {
  pathParams: DeleteBasicFeeGroupPathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeleteBasicFeeGroup = (
  variables: DeleteBasicFeeGroupVariables,
  signal?: AbortSignal,
) =>
  diceFetch<undefined, DeleteBasicFeeGroupError, undefined, {}, {}, DeleteBasicFeeGroupPathParams>({
    url: '/cost-unit/basic-fee/groups/{groupId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteBasicFeeGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteBasicFeeGroupError,
      DeleteBasicFeeGroupVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, DeleteBasicFeeGroupError, DeleteBasicFeeGroupVariables>({
    mutationFn: (variables: DeleteBasicFeeGroupVariables) =>
      fetchDeleteBasicFeeGroup({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface DeleteBasicFeeGroupEntryPathParams {
  groupEntryId: string;
}

export type DeleteBasicFeeGroupEntryError = Fetcher.ErrorWrapper<undefined>;

export type DeleteBasicFeeGroupEntryVariables = {
  pathParams: DeleteBasicFeeGroupEntryPathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeleteBasicFeeGroupEntry = (
  variables: DeleteBasicFeeGroupEntryVariables,
  signal?: AbortSignal,
) =>
  diceFetch<
    undefined,
    DeleteBasicFeeGroupEntryError,
    undefined,
    {},
    {},
    DeleteBasicFeeGroupEntryPathParams
  >({
    url: '/cost-unit/basic-fee/groups/entries/{groupEntryId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteBasicFeeGroupEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteBasicFeeGroupEntryError,
      DeleteBasicFeeGroupEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<
    undefined,
    DeleteBasicFeeGroupEntryError,
    DeleteBasicFeeGroupEntryVariables
  >({
    mutationFn: (variables: DeleteBasicFeeGroupEntryVariables) =>
      fetchDeleteBasicFeeGroupEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface DeleteFile1PathParams {
  assetId: string;
}

export type DeleteFile1Error = Fetcher.ErrorWrapper<undefined>;

export type DeleteFile1Variables = {
  pathParams: DeleteFile1PathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeleteFile1 = (variables: DeleteFile1Variables, signal?: AbortSignal) =>
  diceFetch<undefined, DeleteFile1Error, undefined, {}, {}, DeleteFile1PathParams>({
    url: '/assets/id/{assetId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteFile1 = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteFile1Error, DeleteFile1Variables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, DeleteFile1Error, DeleteFile1Variables>({
    mutationFn: (variables: DeleteFile1Variables) =>
      fetchDeleteFile1({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteAllError = Fetcher.ErrorWrapper<undefined>;

export type DeleteAllRequestBody = string[];

export type DeleteAllVariables = {
  body?: DeleteAllRequestBody;
} & DiceContext['fetcherOptions'];

export const fetchDeleteAll = (variables: DeleteAllVariables, signal?: AbortSignal) =>
  diceFetch<undefined, DeleteAllError, DeleteAllRequestBody, {}, {}, {}>({
    url: '/assets/batch',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteAll = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteAllError, DeleteAllVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, DeleteAllError, DeleteAllVariables>({
    mutationFn: (variables: DeleteAllVariables) =>
      fetchDeleteAll({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface DeleteAssetGroupPathParams {
  groupId: string;
}

export type DeleteAssetGroupError = Fetcher.ErrorWrapper<undefined>;

export type DeleteAssetGroupVariables = {
  pathParams: DeleteAssetGroupPathParams;
} & DiceContext['fetcherOptions'];

export const fetchDeleteAssetGroup = (variables: DeleteAssetGroupVariables, signal?: AbortSignal) =>
  diceFetch<undefined, DeleteAssetGroupError, undefined, {}, {}, DeleteAssetGroupPathParams>({
    url: '/asset-group/{groupId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteAssetGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteAssetGroupError, DeleteAssetGroupVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useDiceContext();
  return reactQuery.useMutation<undefined, DeleteAssetGroupError, DeleteAssetGroupVariables>({
    mutationFn: (variables: DeleteAssetGroupVariables) =>
      fetchDeleteAssetGroup({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type QueryOperation =
  | {
      path: '/';
      operationId: 'base';
      variables: BaseVariables;
    }
  | {
      path: '/years';
      operationId: 'getAllDataContainer';
      variables: GetAllDataContainerVariables;
    }
  | {
      path: '/public-facilities/{publicFacilityId}/years/{yearId}';
      operationId: 'getYear';
      variables: GetYearVariables;
    }
  | {
      path: '/master-configurations/{masterConfigurationId}';
      operationId: 'getMasterConfiguration';
      variables: GetMasterConfigurationVariables;
    }
  | {
      path: '/master-configurations/{masterConfigurationId}/technical-data';
      operationId: 'getTechnicalDataByMasterConfigurationId';
      variables: GetTechnicalDataByMasterConfigurationIdVariables;
    }
  | {
      path: '/master-configurations/{masterConfigurationId}/cost-unit-allocations';
      operationId: 'getCostUnitAllocationConfig';
      variables: GetCostUnitAllocationConfigVariables;
    }
  | {
      path: '/imports/fileErpTypes';
      operationId: 'getFileErpTypes';
      variables: GetFileErpTypesVariables;
    }
  | {
      path: '/clients/{clientId}/public-facilities';
      operationId: 'getPublicFacilitiesByClient';
      variables: GetPublicFacilitiesByClientVariables;
    }
  | {
      path: '/assets';
      operationId: 'getAllAssets';
      variables: GetAllAssetsVariables;
    }
  | {
      path: '/asset-cc-config';
      operationId: 'getAllAssets1';
      variables: GetAllAssets1Variables;
    }
  | {
      path: '/asset-category';
      operationId: 'getAllAssetCategoryConfigs';
      variables: GetAllAssetCategoryConfigsVariables;
    }
  | {
      path: '/public-facilities/{publicFacilityId}/years';
      operationId: 'getYears';
      variables: GetYearsVariables;
    }
  | {
      path: '/master-configurations/{masterConfigurationId}/account-ranges';
      operationId: 'getRangeConfigForMasterConfiguration';
      variables: GetRangeConfigForMasterConfigurationVariables;
    }
  | {
      path: '/index-factors';
      operationId: 'getAllIndexFactors';
      variables: GetAllIndexFactorsVariables;
    }
  | {
      path: '/config';
      operationId: 'getAssetExtendedInfo';
      variables: GetAssetExtendedInfoVariables;
    }
  | {
      path: '/clients';
      operationId: 'getClients';
      variables: GetClientsVariables;
    }
  | {
      path: '/years/{yearId}/master-configs/{masterConfigId}/cost-units/profit-center-trees';
      operationId: 'getProfitCenterTreeForCostUnit';
      variables: GetProfitCenterTreeForCostUnitVariables;
    }
  | {
      path: '/years/{ids}';
      operationId: 'getDataContainerById';
      variables: GetDataContainerByIdVariables;
    }
  | {
      path: '/year-calculations/{yearCalculationId}';
      operationId: 'getYearCalculation';
      variables: GetYearCalculationVariables;
    }
  | {
      path: '/year-calculations/{yearCalculationId}/master-configurations/entities';
      operationId: 'getMasterConfigurationByYearCalculation';
      variables: GetMasterConfigurationByYearCalculationVariables;
    }
  | {
      path: '/year-calculations/{yearCalculationId}/files-metadata';
      operationId: 'getFilesMetadata';
      variables: GetFilesMetadataVariables;
    }
  | {
      path: '/year-calculations/{yearCalculationId}/files-metadata/status';
      operationId: 'getFilesMetadataStatus';
      variables: GetFilesMetadataStatusVariables;
    }
  | {
      path: '/public-facilities/{publicFacilityId}/years/year-calculations/master-configurations';
      operationId: 'getMasterConfigurations';
      variables: GetMasterConfigurationsVariables;
    }
  | {
      path: '/price-groups/years/{yearId}/master-configs/{masterConfigId}';
      operationId: 'getPriceSheetConfig';
      variables: GetPriceSheetConfigVariables;
    }
  | {
      path: '/price-groups/years/{yearId}/master-configs/{masterConfigId}/reports';
      operationId: 'getPriceSheetReport';
      variables: GetPriceSheetReportVariables;
    }
  | {
      path: '/migration/test-endpoint';
      operationId: 'testEndpoint';
      variables: TestEndpointVariables;
    }
  | {
      path: '/master-configurations/{masterConfigurationId}/planned-cost-revenue';
      operationId: 'getPlannedCostRevenueConfig';
      variables: GetPlannedCostRevenueConfigVariables;
    }
  | {
      path: '/master-configurations/{masterConfigurationId}/planned-cost-revenue/preselected-values';
      operationId: 'getPlannedCostRevenuePreselectedValues';
      variables: GetPlannedCostRevenuePreselectedValuesVariables;
    }
  | {
      path: '/master-configurations/{masterConfigurationId}/cost-unit-allocations/preselected-values';
      operationId: 'getCostUnitAllocationPreselectedValues';
      variables: GetCostUnitAllocationPreselectedValuesVariables;
    }
  | {
      path: '/master-configuration/{id}';
      operationId: 'getMasterConfigurationById';
      variables: GetMasterConfigurationByIdVariables;
    }
  | {
      path: '/master-configuration/year';
      operationId: 'getAllMasterConfigurationsByYear';
      variables: GetAllMasterConfigurationsByYearVariables;
    }
  | {
      path: '/master-configuration/year/{yearId}';
      operationId: 'getMasterConfigurationsByYearId';
      variables: GetMasterConfigurationsByYearIdVariables;
    }
  | {
      path: '/master-configs/{masterConfigurationId}/status';
      operationId: 'getConfigsStatus';
      variables: GetConfigsStatusVariables;
    }
  | {
      path: '/master-configs/{masterConfigurationId}/allocation-key-configs';
      operationId: 'getAllocationKeyConfig';
      variables: GetAllocationKeyConfigVariables;
    }
  | {
      path: '/master-configs/{masterConfigurationId}/allocation-key-configs-status';
      operationId: 'getAllocationKeyConfigStatus';
      variables: GetAllocationKeyConfigStatusVariables;
    }
  | {
      path: '/master-configs/{masterConfigurationId}/accounts';
      operationId: 'getAccountsByMasterConfiguration';
      variables: GetAccountsByMasterConfigurationVariables;
    }
  | {
      path: '/master-configs/{masterConfigId}/cost-type-accounts';
      operationId: 'getConfig';
      variables: GetConfigVariables;
    }
  | {
      path: '/index-factors/years';
      operationId: 'getIndexFactorsAllYears';
      variables: GetIndexFactorsAllYearsVariables;
    }
  | {
      path: '/index-factors/years/{configYear}/distinct-field/{distinctByField}';
      operationId: 'getIndexFactorForYearAndField';
      variables: GetIndexFactorForYearAndFieldVariables;
    }
  | {
      path: '/index-factors/only-years/{onlyYears}';
      operationId: 'getIndexFactorYears';
      variables: GetIndexFactorYearsVariables;
    }
  | {
      path: '/index-factors/data-sources';
      operationId: 'getIndexFactorDataSources';
      variables: GetIndexFactorDataSourcesVariables;
    }
  | {
      path: '/imports/ledger-account-filters';
      operationId: 'getImportLedgerAccountFilters';
      variables: GetImportLedgerAccountFiltersVariables;
    }
  | {
      path: '/imports/journal-filters';
      operationId: 'getImportJournalFilters';
      variables: GetImportJournalFiltersVariables;
    }
  | {
      path: '/imports/erpType';
      operationId: 'getErpType';
      variables: GetErpTypeVariables;
    }
  | {
      path: '/imports/asset-filters';
      operationId: 'getImportAssetFilters';
      variables: GetImportAssetFiltersVariables;
    }
  | {
      path: '/healthcheck';
      operationId: 'healthcheck';
      variables: HealthcheckVariables;
    }
  | {
      path: '/files';
      operationId: 'getFile';
      variables: GetFileVariables;
    }
  | {
      path: '/exception';
      operationId: 'getEsraException';
      variables: GetEsraExceptionVariables;
    }
  | {
      path: '/cost-unit/basic-fee/years/{yearId}/master-configs/{masterConfigId}/cost-unit/{costUnitShortName}';
      operationId: 'getBasicFeeConfig';
      variables: GetBasicFeeConfigVariables;
    }
  | {
      path: '/clients/{name}';
      operationId: 'getClient';
      variables: GetClientVariables;
    }
  | {
      path: '/clients/{clientId}/public-facilities/{publicFacilityId}';
      operationId: 'getPublicFacility';
      variables: GetPublicFacilityVariables;
    }
  | {
      path: '/assets_costcenters';
      operationId: 'getAllDistinctAssetCostCenters';
      variables: GetAllDistinctAssetCostCentersVariables;
    }
  | {
      path: '/assets_categories/numbers/description';
      operationId: 'getAssetCategoryToAssetNbrWithDescription';
      variables: GetAssetCategoryToAssetNbrWithDescriptionVariables;
    }
  | {
      path: '/assets_categories/description';
      operationId: 'getAssetCategoryDescription';
      variables: GetAssetCategoryDescriptionVariables;
    }
  | {
      path: '/assets/page';
      operationId: 'getAllAssetsWithPager';
      variables: GetAllAssetsWithPagerVariables;
    }
  | {
      path: '/asset_categories';
      operationId: 'getAllDistinctAssetCategories';
      variables: GetAllDistinctAssetCategoriesVariables;
    }
  | {
      path: '/asset-group/masterConfiguration/{masterConfigurationId}';
      operationId: 'findGroups';
      variables: FindGroupsVariables;
    }
  | {
      path: '/allocation-key-config/preselected-values/master-configs/{masterConfigurationId}';
      operationId: 'getAllocationKeyConfigPreselectedValues';
      variables: GetAllocationKeyConfigPreselectedValuesVariables;
    };
